import { Dropdown } from 'primereact/dropdown';
import React from 'react';

const DropdownComponent = ({ label, placeholder, value, options, optionLabel = 'label', onChange, ...rest }) => {
  return (
    <div>
      <label className='text-base block font-medium mb-2'>{label}</label>
      <Dropdown
        value={value}
        options={options}
        optionLabel={optionLabel}
        placeholder={placeholder}
        className={`input-field-root`}
        onChange={onChange}
        {...rest}
        pt={{
          input: { className: '!w-full !p-1.5' },
          list: { className: '!py-0' },
          dropdownicon: { className: 'h-[13px] w-[13px]' }
        }}
      />
    </div>
  );
};

export default DropdownComponent;
