import React, { useState } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { formatTimestamp } from '../../components/Shared/SharedMethods';
import SharedTooltip from '../../components/Shared/SharedTooltip';
import { useDispatch } from 'react-redux';
import {
  filterInvoiceData,
  getInsuranceInvoiceDetail,
  getInvoiceDetail,
  printInvoice,
  setInitialSalesfilter
} from '../../store/slices/InvoicesSlice';
import { useNavigate } from 'react-router-dom';
import CustomPagination from '../../components/Shared/CustomPagination';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../Transactions/TransactionsTable';
import { HiOutlinePrinter } from 'react-icons/hi2';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import ReceivePaymentInvoice from './ReceivePaymentInvoice';
import TableLoading from '../../components/Shared/TableLoading';
import CustomTable from '../../components/Shared/CustomTable';

export default function InvoiceTable({
  invoicesList,
  loading,
  filters,
  setFilters,
  setFilteredInvoices,
  setFiltersDetails,
  SetFiltersLoading
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [receivePaymentDialogVisible, setReceivePaymentDialogVisible] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);

  const datebody = rowData => {
    return formatTimestamp(rowData.date);
  };
  const handlePrintInvoice = async id => {
    try {
      const resultAction = await dispatch(printInvoice(id));
      if (printInvoice.fulfilled.match(resultAction)) {
        navigate('/manager/show-invoice');
      } else if (printInvoice.rejected.match(resultAction)) {
        console.error('Failed to print invoice:', resultAction.error);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const onPageChange = async (newPage, newRowsPerPage) => {
    let payload = {
      ...filters,
      page: newPage,
      per_page: newRowsPerPage
    };
    console.log(newPage);
    SetFiltersLoading(true);
    const data = await dispatch(filterInvoiceData(payload));
    SetFiltersLoading(false);

    if (data) {
      let filteredInvoicesData = data?.payload?.data ? data?.payload : [];
      setFiltersDetails(data?.payload);
      setFilteredInvoices(filteredInvoicesData);
      dispatch(setInitialSalesfilter(payload));
    }
    setFilters(prev => ({
      ...prev,
      page: newPage,
      per_page: newRowsPerPage
    }));
  };

  const templateBody = rowData => (
    <div className='flex gap-1'>
      <SharedTooltip content='print' id='print' />
      <SharedTooltip content='payment' id='payment' />

      <HiOutlinePrinter
        size={25}
        data-tooltip-id='print'
        className='!p-1 !text-black cursor-pointer'
        onClick={() => {
          handlePrintInvoice(rowData?.id);
        }}
      />
      {rowData.status !== 'Paid' && rowData?.status !== 'cancel' && (
        <LiaFileInvoiceDollarSolid
          size={25}
          data-tooltip-id='payment'
          className='table-icon'
          onClick={() => {
            setSelectedEntry(rowData);
            setReceivePaymentDialogVisible(true);
          }}
        />
      )}
      {rowData?.status != 'cancel' && (
        <MdOutlineCancel
          size={25}
          data-tooltip-id='cancel'
          className='!p-1 !text-black cursor-pointer'
          onClick={() => {
            if (rowData?.invoice_type === 'credit') {
              dispatch(
                getInsuranceInvoiceDetail({
                  invoice_id: rowData?.company_invoice_id
                })
              ).then(() => {
                navigate('/manager/insuranceInvoice/create', {
                  state: { data: rowData, cancel: true }
                });
              });
            } else {
              dispatch(
                getInvoiceDetail({
                  invoice_id: rowData?.id
                })
              ).then(() => {
                navigate('/manager/invoices/create', {
                  state: { data: rowData, cancel: true }
                });
              });
            }
          }}
        />
      )}
    </div>
  );
  const statusBody = data => {
    if (data?.status === 'Paid') {
      return <div className='bg-green-500 !w-[50px] p-1 text-center text-white rounded-md'>{t('Paid')}</div>;
    } else if (data?.status === 'Unpaid') {
      return <div className='bg-red-500 p-1 !w-[50px] text-center text-white rounded-md'>{t('Unpaid')}</div>;
    } else if (data?.status === 'cancel') {
      return <div className='bg-red-500 p-1 !w-[50px] text-center text-white rounded-md'>{t('Cancel')}</div>;
    } else {
      return <div className='bg-red-500 p-1 !w-[50px] text-center text-white rounded-md'>{t('Unpaid')}</div>;
    }
  };
  const sequenceBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1 + (invoicesList?.page - 1) * invoicesList?.per_page;
  };

  const balanceBody = (data, column) => {
    let fieldName = column?.field;
    if (fieldName == 'total_company_amount')
      return (
        <>
          {data?.company_share + data?.company_tax} {t('SAR')}
        </>
      );
    return (
      <>
        {data[fieldName]} {t('SAR')}
      </>
    );
  };
  const VatBody = data => {
    return (
      <>
        {data?.patient_tax} {t('SAR')}
      </>
    );
  };

  const columnsData = [
    {
      body: sequenceBodyTemplate,
      header: t('no'),
      style: { width: '3rem' }
    },
    {
      field: 'date',
      header: t('date'),
      body: rowData => formatDate(rowData.date)
    },
    {
      field: 'invoice_number',
      header: t('invoiceNumber')
    },
    {
      field: 'file_number',
      header: t('fileNumber')
    },
    {
      field: 'customer_name',
      header: `${t('Customer')} ${t('name')}`
    },
    {
      field: 'patient_share',
      header: `${t('Customer')} ${t('amount')}`,
      body: balanceBody
    },
    {
      field: 'discount',
      header: t('discount')
    },
    {
      field: 'patient_tax',
      header: `${t('Customer')} ${t('vat')}`,
      body: balanceBody
    },
    {
      field: 'amount',
      header: t('total_customer_amount'),
      body: balanceBody
    },
    {
      field: 'company_share',
      header: t('company_amount'),
      body: balanceBody
    },
    {
      field: 'company_tax',
      header: t('company_vat'),
      body: balanceBody
    },
    {
      field: 'total_company_amount',
      header: t('total_company_amount'),
      body: balanceBody
    },
    {
      field: 'payment_method',
      header: t('payment_method')
    },
    {
      field: 'nationality',
      header: t('nationality')
    },

    {
      body: statusBody,
      field: 'paid',
      header: t('status')
    },
    {
      field: 'description',
      header: t('description')
    },
    {
      body: templateBody,
      header: t('actions')
    }
  ];

  return (
    <>
      <CustomTable
        data={invoicesList?.data}
        isLoading={loading}
        loadingIcon={<TableLoading />}
        columns={columnsData}
        rows={invoicesList?.per_page}
        rootyStyles='max-h-[50vh] overflow-auto'
      />
      {invoicesList?.data?.length > 0 && (
        <CustomPagination
          currentPage={invoicesList?.page}
          rowsPerPage={invoicesList?.per_page}
          totalRecords={invoicesList?.total_pages}
          totalRecord={invoicesList?.total_record}
          onPageChange={onPageChange}
        />
      )}
      <ReceivePaymentInvoice
        open={receivePaymentDialogVisible}
        setOpen={setReceivePaymentDialogVisible}
        selectedEntries={selectedEntry ? [selectedEntry] : []}
        filtersPayload={filters}
        setFilteredInvoices={setFilteredInvoices}
      />
    </>
  );
}
