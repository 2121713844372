import React from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useTranslation } from 'react-i18next';
import SharedButton from './SharedButton';

export default function SharedConfirmDialog({
  message,
  header,
  icon = 'pi pi-exclamation-triangle',
  onAccept,
  onReject,
  visible,
  setVisible,
  rejectLabel,
  acceptLabel
}) {
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      header={t(header) || t('Confirmation')}
      visible={visible}
      group='declarative'
      onHide={() => setVisible(false)}
      message={t(message)}
      icon={icon}
      accept={onAccept}
      reject={onReject}
      pt={{
        root: { className: 'w-4/5 md:w-2/3 lg:w-[550px] mx-4 overflow-auto' },
        header: { className: '!p-2 !bg-darkBg !text-white' },
        footer: { className: '!p-3' },
        content: { className: '!p-3' },
        closeButton: {
          className: 'hover:!bg-black/20 !outline-none !shadow-none'
        },
        closeButtonIcon: { className: '!text-white' }
      }}
      footer={
        <div className='flex justify-end gap-3 mt-6'>
          <SharedButton
            icon='pi pi-times'
            label={t(rejectLabel)}
            className='cancel-btn'
            type='button'
            onClick={() => {
              setVisible(false);
              if (onReject) onReject();
            }}
          />
          <SharedButton
            icon='pi pi-check'
            label={t(acceptLabel)}
            className='confirm-btn'
            type='button'
            onClick={() => {
              setVisible(false);
              if (onAccept) onAccept();
            }}
          />
        </div>
      }
    />
  );
}
