import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { MdOutlineDelete, MdOutlineEdit } from 'react-icons/md';
import { IoMdEye } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SharedTooltip from '../../components/Shared/SharedTooltip';
import SharedButton from '../../components/Shared/SharedButton';
import { formatDate } from '../Transactions/TransactionsTable';
import { deletePaymentVoucher, getPaymentVoucher } from '../../store/slices/VoucherSlice';
import { getCostCenter } from '../../store/slices/CostCenterSlice';
import SharedConfirmDialog from '../../components/Shared/SharedConfirmDialog';
import CustomPagination from '../../components/Shared/CustomPagination';
import TableLoading from '../../components/Shared/TableLoading';
import CustomTable from '../../components/Shared/CustomTable';

function PaymentVoucher() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteVoucher, setDeleteVoucher] = useState(false);
  const [delId, setDelId] = useState(null);
  const { paymentVouchers, isLoading } = useSelector(state => state.voucher);
  const { costCenters } = useSelector(state => state?.costCenter);
  const lang = i18next.language;

  useEffect(() => {
    dispatch(getCostCenter());
    dispatch(getPaymentVoucher({ page: 1, per_page: 10 }));
  }, []);

  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(getPaymentVoucher({ page: newPage, per_page: newRowsPerPage }));
  };
  const sequenceBodyTemplate = (rowData, options) => {
    return Number(options.rowIndex + 1 + (paymentVouchers?.page - 1) * paymentVouchers?.per_page);
  };
  const costCenterTempelate = rowData => {
    const center = costCenters?.data?.find(center => center?.id == rowData?.cost_center_id);
    return lang === 'ar' ? center?.name_ar : center?.name_en;
  };
  const templateBody = rowData => {
    return (
      <div className='flex  gap-1'>
        <SharedTooltip content='view' id='my-tooltip-1' />
        <SharedTooltip id='my-tooltip-2' content='edit' />
        <SharedTooltip id='my-tooltip-3' content='delete' />
        <IoMdEye size={25} data-tooltip-id='my-tooltip-1' className='table-icon' />
        <MdOutlineEdit
          size={25}
          data-tooltip-id='my-tooltip-2'
          onClick={() => {
            navigate('/manager/create/payment/voucher', {
              state: { data: rowData }
            });
          }}
          className='table-icon'
        />
        <MdOutlineDelete
          size={25}
          onClick={() => {
            setDeleteVoucher(true);
            setDelId(rowData?.id);
          }}
          data-tooltip-id='my-tooltip-3'
          className='table-icon'
        />
      </div>
    );
  };

  const columnsData = [
    {
      body: sequenceBodyTemplate,
      header: t('no'),
      style: { width: '3rem' }
    },
    {
      field: 'date',
      header: t('date'),
      body: rowData => formatDate(rowData?.date)
    },
    {
      body: costCenterTempelate,
      header: t('costCenter')
    },
    {
      field: 'total_amount',
      header: t('total_amount')
    },
    {
      body: templateBody,
      header: t('actions')
    }
  ];

  return (
    <div>
      <div className='flex justify-between items-center flex-col gap-3 sm:gap-0 sm:flex-row mb-3'>
        <SharedTooltip content={t('payment_vouchers')} position='left' id='Payment' />
        <span data-tooltip-id='Payment' className='font-semibold text-black/70 text-xl mt-2'>
          {t('payment_vouchers')}
          <span className='italic text-lg ml-1'>
            ({paymentVouchers?.data?.length || 0}
            <span className='text-xs font-bold italic'> {t('Entries')}</span>)
          </span>
        </span>
        <div className='flex items-center gap-4'>
          <SharedButton
            label={t('create_payment_voucher')}
            icon='pi pi-plus'
            onClick={() => navigate('/manager/create/payment/voucher')}
            className='bg-gradient primary-btn'
          />
        </div>
      </div>
      <div>
        <CustomTable
          data={paymentVouchers?.data}
          isLoading={isLoading}
          loadingIcon={<TableLoading />}
          columns={columnsData}
          rows={paymentVouchers?.per_page}
        />
        {paymentVouchers?.data?.length > 0 && (
          <CustomPagination
            currentPage={paymentVouchers?.page}
            rowsPerPage={paymentVouchers?.per_page}
            totalRecords={paymentVouchers?.total_pages}
            totalRecord={paymentVouchers?.total_record}
            onPageChange={onPageChange}
          />
        )}
      </div>
      <SharedConfirmDialog
        visible={deleteVoucher}
        setVisible={setDeleteVoucher}
        message='DoyouwanttoDelete?'
        rejectLabel='No'
        acceptLabel='Yes'
        onAccept={() => {
          dispatch(deletePaymentVoucher(delId))?.then(() => {
            setDeleteVoucher(false);
            dispatch(getPaymentVoucher({ page: 1, per_page: 10 }));
          });
        }}
        onReject={() => setDeleteVoucher(false)}
      />
    </div>
  );
}

export default PaymentVoucher;
