import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CashInflow from '../CashInflow/CashInflow';
import Income from '../Income/Income';
import Ledger from '../Ledger/Ledger';
import Trial from '../TrialBalance/TrialBalance';
import SharedTooltip from '../../components/Shared/SharedTooltip';
import VatReport from '../Vat Report/VatReport';
import BalanceSheetPage from '../BalanceSheet/BalanceSheetPage';
import VatReturnReport from '../VatReturnReport/VatReturnReport';
import AccountStatementMain from '../AccountStatement/AccountStatementMain';

export default function ReportMain() {
  const [activeTab, setActiveTab] = useState('balance');
  const { t } = useTranslation();
  const tabList = [
    {
      label: t('BalanceSheet'),
      comapre: 'balance',
      command: () => setActiveTab('balance')
    },
    {
      label: t('CashFlow'),
      comapre: 'cash',
      command: () => setActiveTab('cash')
    },
    {
      label: t('IncomeStatement'),
      comapre: 'income',
      command: () => setActiveTab('income')
    },

    {
      label: t('accountStatement'),
      comapre: 'accountStatement',
      command: () => setActiveTab('accountStatement')
    },

    {
      label: t('Ledger'),
      comapre: 'ledger',
      command: () => setActiveTab('ledger')
    },
    {
      label: t('TrialBalance'),
      comapre: 'trial',
      command: () => setActiveTab('trial')
    },
    {
      label: t('VAT Report'),
      comapre: 'vat',
      command: () => setActiveTab('vat')
    },
    {
      label: t('VAT Return Report'),
      comapre: 'vatReturn',
      command: () => setActiveTab('vatReturn')
    }
  ];

  return (
    <div>
      <div>
        <div className='flex justify-between flex-col gap-3 sm:gap-0 sm:flex-row mb-3'>
          <SharedTooltip content={t('Keep_track_of_your_reports')} position='left' id='report' />
          <span data-tooltip-id='report' className='font-semibold text-black/70 text-xl mt-2 '>
            {t('reports')}
          </span>
        </div>
        <div className='yee'>
          <ul className='grid grid-flow-col mb-2 text-center text-gray-500 bg-gray-100 border rounded p-1 shadow-inner'>
            {tabList.map((tab, index) => (
              <li key={index}>
                <a
                  className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                    activeTab === tab?.comapre ? ' bg-gradient rounded shadow text-white hover:text-white ' : ''
                  }`}
                  onClick={() => tab?.command()}
                >
                  {tab.label}
                </a>
              </li>
            ))}
          </ul>

          <div className='rounded-md h-[70vh] overflow-auto'>
            {activeTab === 'balance' ? (
              <BalanceSheetPage />
            ) : activeTab === 'cash' ? (
              <CashInflow />
            ) : activeTab === 'accountStatement' ? (
              <AccountStatementMain />
            ) : activeTab === 'income' ? (
              <Income />
            ) : activeTab === 'ledger' ? (
              <Ledger />
            ) : activeTab === 'trial' ? (
              <Trial />
            ) : activeTab === 'vat' ? (
              <VatReport />
            ) : activeTab === 'vatReturn' ? (
              <VatReturnReport />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
