import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';

const ReusableTable = props => {
  const { i18n, t } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const {
    data,
    isLoading,
    columns,
    tableStyle = { minWidth: '50rem' },
    paginator = false,
    loadingIcon = null,
    emptyMessage = t('no_results_found'),
    rootyStyles = 'max-h-[60vh] overflow-auto',
    rest
  } = props;

  return (
    <DataTable
      value={data}
      loading={isLoading}
      loadingIcon={loadingIcon}
      tableStyle={tableStyle}
      paginator={paginator}
      emptyMessage={emptyMessage}
      pt={{ root: { className: rootyStyles } }}
      {...rest}
    >
      {columns.map((col, index) => (
        <Column
          key={index}
          field={col?.field}
          header={col?.header}
          body={col?.body}
          style={{ ...col.style }}
          pt={{
            headerCell: {
              className: `!p-[0.3rem] !bg-secondary text-${isRtl ? 'right' : 'left'} ${col.headerCellClass || ''}`,
              ...col.pt?.headerCell
            },
            bodyCell: {
              className: `!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary text-${
                isRtl ? 'right' : 'left'
              } ${col.bodyCellClass || ''} `,
              ...col.pt?.bodyCell
            },
            ...col.pt
          }}
          {...col.extraProps}
        />
      ))}
    </DataTable>
  );
};

export default ReusableTable;
