import React, { useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MdOutlineDelete, MdOutlineEdit } from 'react-icons/md';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { formatTimestamp } from '../../components/Shared/SharedMethods';
import SharedTooltip from '../../components/Shared/SharedTooltip';
export default function UsersTable({ data }) {
  // console.log("DaTAA: ", data);
  const datebody = rowData => {
    return formatTimestamp(rowData.created_at);
  };
  const templateBody = rowData => (
    <div className='flex gap-1'>
      <SharedTooltip content='view' id='my-tooltip-1' />
      <SharedTooltip id='my-tooltip-2' content='edit' />
      <SharedTooltip id='my-tooltip-3' content='delete' />
      <MdOutlineRemoveRedEye size={25} data-tooltip-id='my-tooltip-1' className='table-icon' />
      <MdOutlineEdit size={25} data-tooltip-id='my-tooltip-2' className='table-icon' />
      <MdOutlineDelete size={25} data-tooltip-id='my-tooltip-3' className='table-icon' />
    </div>
  );

  return (
    <DataTable value={data} tableStyle={{ minWidth: '50rem' }}>
      <Column
        field='full_name'
        header='Full Name'
        pt={{
          headerCell: {
            className: '!p-[0.3rem] !bg-secondary'
          },
          bodyCell: {
            className: '!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary'
          }
        }}
      />

      <Column
        field='email'
        header='Email'
        pt={{
          headerCell: {
            className: '!p-[0.3rem] !bg-secondary'
          },
          bodyCell: {
            className: '!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary'
          }
        }}
      />

      <Column
        field='mobile_number'
        header='Mobile Number'
        pt={{
          headerCell: {
            className: '!p-[0.3rem] !bg-secondary !rounded-tl-md'
          },
          bodyCell: {
            className: '!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary'
          }
        }}
      />

      <Column
        field='created_at'
        header='Created At'
        body={datebody}
        pt={{
          headerCell: {
            className: '!p-[0.3rem] !bg-secondary'
          },
          bodyCell: {
            className: '!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary'
          }
        }}
      />

      <Column
        body={templateBody}
        header='Actions'
        pt={{
          headerCell: {
            className: '!p-[0.3rem] !bg-secondary !rounded-tr-md'
          },
          bodyCell: {
            className: '!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary'
          }
        }}
      />
    </DataTable>
  );
}
