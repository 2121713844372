import React, { useRef } from 'react';
import * as XLSX from 'xlsx';
import i18next from 'i18next';
import { Menu } from 'primereact/menu';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { fetchData } from '../../helpers/fetchData';
import SharedButton from '../../components/Shared/SharedButton';

const ExportInvoices = ({ filters, columnsData, apiUrl, exportOptionsFormat, name }) => {
  const { t } = useTranslation();
  const iframeRef = useRef(null);
  const menuRef = useRef(null);
  const lang = i18next.language;

  const handlePrint = async () => {
    let payload = { ...filters };
    if (!payload?.customer_id) payload.customer_id = 0;
    const response = await fetchData('POST', apiUrl, payload);
    if (response?.data) {
      const tableContent = generateTable(response.data);
      printTable(tableContent);
    }
  };

  const generateTable = data => {
    let html = '<table border="1" style="width:100%;">';
    html += '<thead><tr>';
    columnsData.forEach(col => {
      html += `<th>${col.header}</th>`;
    });
    html += '</tr></thead><tbody>';
    data.forEach((row, index) => {
      html += '<tr>';
      columnsData.forEach(col => {
        let defaultValue = col?.type == Number ? 0 : '';
        let value = col.key == 'no' ? index + 1 : row[col.key] || defaultValue;
        if (col.key == 'total_company_amount') {
          value = row?.company_share + row?.company_tax;
        }
        if (col.key == 'grass_amount') {
          value = row?.discount_amount + row?.taxable_amount;
        }
        html += `<td>${value} ${col?.type == Number ? t('SAR') : ''}</td>`;
      });
      html += '</tr>';
    });

    html += '</tbody></table>';
    return html;
  };

  const printTable = tableContent => {
    const direction = lang == 'ar' ? 'rtl' : 'ltr';
    const iframe = iframeRef.current;
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write('<html><head><title>Invoices Details</title>');
    doc.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css">');

    doc.write('<style>');
    doc.write(`
      <html>
        <head>
          <title>Invoices Details</title>
          <style>
           @page {
             margin: 20px 0 20px 0;
           }
           body {
             margin: 1cm;
             direction: ${direction};
           }
          table {
             direction: ${direction};
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border-bottom: 1px solid lightgray;
              text-align:start;
            }
          </style>
        </head>
        <body>
          ${tableContent}
        </body>
      </html>
    `);
    doc.close();
    iframe.onload = () => {
      iframe.contentWindow.print();
    };
  };

  const handleExcelExport = async () => {
    let payload = { ...filters };
    if (!payload?.customer_id) payload.customer_id = 0;
    const response = await fetchData('POST', apiUrl, payload);

    if (!response?.data) {
      toast.error('No data available for export');
      return;
    }
    const exportData = exportOptionsFormat(response?.data);
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const columnWidths = [{ wch: 15 }, { wch: 30 }, { wch: 15 }];
    worksheet['!cols'] = columnWidths;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, name);
    XLSX.writeFile(workbook, `${name}.xlsx`);
  };

  const items = [
    {
      label: t('EXCEL'),
      icon: 'pi pi-file-excel',
      command: handleExcelExport
    },
    {
      label: t('PDF'),
      icon: 'pi pi-print',
      command: handlePrint
    }
  ];
  return (
    <div>
      <SharedButton
        label={`${t('Export')}`}
        icon='pi pi-download'
        iconPos='right'
        onClick={e => menuRef.current.toggle(e)}
        className='bg-gradient primary-btn'
      />
      <Menu
        pt={{
          root: { className: '!py-0 bg-white' },
          content: { className: '!p-0 bg-white !text-gray-500' },
          action: { className: '!py-1.5 !px-2 !bg-none !text-gray-500' },
          menuitem: { className: '!bg-none' },
          menu: { className: '!bg-gray-100' },
          icon: { className: '!text-gray-500' }
        }}
        model={items}
        popup
        ref={menuRef}
      />
      <iframe ref={iframeRef} style={{ display: 'none' }}></iframe>
    </div>
  );
};

export default ExportInvoices;
