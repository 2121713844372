import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import validSupergitHIS from '../../api/validSupergitHIS';
import toast from 'react-hot-toast';

export const createInvoice = createAsyncThunk('createInvoice', async (data, { rejectWithValue }) => {
  try {
    const response = await validSupergitHIS.post('createInvoice', data);
    toast.success(response?.data?.message);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.error || 'something went wrong');
    return rejectWithValue(error?.response?.data?.error || 'something went wrong');
  }
});

export const printInvoice = createAsyncThunk('printInvoice', async (id, { rejectWithValue }) => {
  try {
    const response = await validSupergitHIS.post(`printInvoice/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.error || 'something went wrong');
    return rejectWithValue(error?.response?.data?.error || 'something went wrong');
  }
});
export const printCreditInvoice = createAsyncThunk('printCreditInvoice', async (id, { rejectWithValue }) => {
  try {
    const response = await validSupergitHIS.post(`printCreditInvoice/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.error || 'something went wrong');
    return rejectWithValue(error?.response?.data?.error || 'something went wrong');
  }
});

export const printCompanyInvoice = createAsyncThunk('printCompanyInvoice', async (id, { rejectWithValue }) => {
  try {
    const response = await validSupergitHIS.post(`printCompanyInvoice/${id}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.error || 'something went wrong');
    return rejectWithValue(error?.response?.data?.error || 'something went wrong');
  }
});
export const printCreditCompanyInvoice = createAsyncThunk(
  'printCreditCompanyInvoice',
  async (id, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(`printCreditCompanyInvoice/${id}`);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || 'something went wrong');
      return rejectWithValue(error?.response?.data?.error || 'something went wrong');
    }
  }
);

export const createCompanyInvoice = createAsyncThunk('createCompanyInvoice', async (data, { rejectWithValue }) => {
  try {
    const response = await validSupergitHIS.post('createInvoice', data);
    toast.success(response?.data?.message);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error || 'something went wrong');
    return rejectWithValue(error?.response?.data?.error || 'something went wrong');
  }
});

export const createPatientInvoice = createAsyncThunk('createPatientInvoice', async (data, { rejectWithValue }) => {
  try {
    const response = await validSupergitHIS.post('patientInvoice', data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error || 'something went wrong');
    return rejectWithValue(error?.response?.data?.error || 'something went wrong');
  }
});
export const updatePatientInvoice = createAsyncThunk('updatePatientInvoice', async (data, { rejectWithValue }) => {
  try {
    const response = await validSupergitHIS.post('updateInvoice', data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error || 'something went wrong');
    return rejectWithValue(error?.response?.data?.error || 'something went wrong');
  }
});

export const getInvoicesList = createAsyncThunk('getInvoicesList', async (payload, { rejectWithValue }) => {
  try {
    const response = await validSupergitHIS.post('listInvoices', payload);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.error || 'something went wrong');
    return rejectWithValue(error?.response?.data?.error || 'something went wrong');
  }
});
export const getCreditInvoicesList = createAsyncThunk('getCreditInvoicesList', async (payload, { rejectWithValue }) => {
  try {
    const response = await validSupergitHIS.post('listCreditInvoice', payload);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.error || 'something went wrong');
    return rejectWithValue(error?.response?.data?.error || 'something went wrong');
  }
});
export const getCreditCompanyInvoicesListApi = createAsyncThunk(
  'getCreditCompanyInvoicesList',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post('listCreditCompanyInvoice', payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || 'something went wrong');
      return rejectWithValue(error?.response?.data?.error || 'something went wrong');
    }
  }
);

export const getCompanyInvoicesList = createAsyncThunk(
  'getCompanyInvoicesList',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post('listInsuranceInvoice', payload);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || 'something went wrong');
      return rejectWithValue(error?.response?.data?.error || 'something went wrong');
    }
  }
);

export const getSearchedInvoice = createAsyncThunk('getSearchedInvoice', async (payload, { rejectWithValue }) => {
  try {
    const response = await validSupergitHIS.post('searchInvoices', payload);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error || 'something went wrong');
    return rejectWithValue(error?.response?.data?.error || 'something went wrong');
  }
});

export const filterInvoiceData = createAsyncThunk('filterInvoiceData', async (payload, { rejectWithValue }) => {
  try {
    if (!payload?.customer_id) payload.customer_id = 0;
    const response = await validSupergitHIS.post('filterInvoices', payload);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error || 'something went wrong');
    return rejectWithValue(error?.response?.data?.error || 'something went wrong');
  }
});

export const getSearchedInsuranceInvoice = createAsyncThunk(
  'getSearchedInsuranceInvoice',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post('searchCompanyInvoices', payload);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || 'something went wrong');
      return rejectWithValue(error?.response?.data?.error || 'something went wrong');
    }
  }
);

export const CreateNewInvoicePayment = createAsyncThunk(
  'CreateNewInvoicePayment',
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post('recordInvoicePayment', data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || 'something went wrong');
      return rejectWithValue(error?.response?.data?.error || 'something went wrong');
    }
  }
);
export const CreateNewInsuranceInvoicePayment = createAsyncThunk(
  'recordInsuranceInvoicePayment',
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post('recordInsuranceInvoicePayment', data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || 'something went wrong');
      return rejectWithValue(error?.response?.data?.error || 'something went wrong');
    }
  }
);
export const CreateNewCreditInvoicePayment = createAsyncThunk(
  'recordCreditInvoicePayment',
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post('recordCreditInvoicePayment', data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || 'something went wrong');
      return rejectWithValue(error?.response?.data?.error || 'something went wrong');
    }
  }
);
export const CreateNewCreditInsuranceInvoicePayment = createAsyncThunk(
  'recordInsuranceCreditInvoicePayment',
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post('recordInsuranceCreditInvoicePayment', data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || 'something went wrong');
      return rejectWithValue(error?.response?.data?.error || 'something went wrong');
    }
  }
);
export const getInsuranceInvoiceDetail = createAsyncThunk(
  'getInsuranceInvoiceDetail',
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post('insuranceInvoiceDetail', data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || 'something went wrong');
      return rejectWithValue(error?.response?.data?.error || 'something went wrong');
    }
  }
);
export const getInvoiceDetail = createAsyncThunk('getInvoiceDetail', async (data, { rejectWithValue }) => {
  try {
    const response = await validSupergitHIS.post('invoiceDetail', data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error || 'something went wrong');
    return rejectWithValue(error?.response?.data?.error || 'something went wrong');
  }
});

export const filterCompanyInvoiceData = createAsyncThunk(
  'filterCompanyInvoiceData',
  async (payload, { rejectWithValue }) => {
    try {
      if (!payload?.customer_id) payload.customer_id = 0;
      const response = await validSupergitHIS.post('filterCompanyInvoices', payload);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || 'something went wrong');
      return rejectWithValue(error?.response?.data?.error || 'something went wrong');
    }
  }
);

const initialState = {
  isLoading: false,
  invoiceCode: null,
  companyInvoiceCode: null,
  invoicesList: [],
  companyInvoicesList: [],
  searchedInvoice: null,
  creditInvoiceList: null,
  creditCompanyInvoiceList: null,
  companyDetail: [],
  invocieDetail: [],
  initialSalesFilters: null,
  initialCompnayFilters: null
};

const InvoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInitialSalesfilter(state, action) {
      state.initialSalesFilters = action.payload;
    },
    setInitialCompaniesfilter(state, action) {
      state.initialCompnayFilters = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(createInvoice.pending, state => {
        state.isLoading = true;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(createInvoice.rejected, state => {
        state.isLoading = false;
      })
      .addCase(printInvoice.pending, state => {
        state.isLoading = true;
      })
      .addCase(printInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoiceCode = action.payload;
      })
      .addCase(printInvoice.rejected, state => {
        state.isLoading = false;
      })
      .addCase(printCreditInvoice.pending, state => {
        state.isLoading = true;
      })
      .addCase(printCreditInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoiceCode = action.payload;
      })
      .addCase(printCreditInvoice.rejected, state => {
        state.isLoading = false;
      })
      .addCase(printCompanyInvoice.pending, state => {
        state.isLoading = true;
      })
      .addCase(printCompanyInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companyInvoiceCode = action.payload;
      })
      .addCase(printCompanyInvoice.rejected, state => {
        state.isLoading = false;
      })
      .addCase(printCreditCompanyInvoice.pending, state => {
        state.isLoading = true;
      })
      .addCase(printCreditCompanyInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companyInvoiceCode = action.payload;
      })
      .addCase(printCreditCompanyInvoice.rejected, state => {
        state.isLoading = false;
      })
      .addCase(createCompanyInvoice.pending, state => {
        state.isLoading = true;
      })
      .addCase(createCompanyInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(createCompanyInvoice.rejected, state => {
        state.isLoading = false;
      })
      .addCase(filterInvoiceData.pending, state => {
        state.isLoading = true;
      })
      .addCase(filterInvoiceData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoicesList = action.payload;
      })
      .addCase(filterInvoiceData.rejected, state => {
        state.isLoading = false;
      })
      .addCase(filterCompanyInvoiceData.pending, state => {
        state.isLoading = true;
      })
      .addCase(filterCompanyInvoiceData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companyInvoicesList = action.payload;
      })
      .addCase(filterCompanyInvoiceData.rejected, state => {
        state.isLoading = false;
      })

      .addCase(getSearchedInvoice.pending, state => {
        state.isLoading = true;
      })
      .addCase(getSearchedInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoicesList = action.payload;
      })
      .addCase(getSearchedInvoice.rejected, state => {
        state.isLoading = false;
      })
      .addCase(getSearchedInsuranceInvoice.pending, state => {
        state.isLoading = true;
      })
      .addCase(getSearchedInsuranceInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companyInvoicesList = action.payload;
      })
      .addCase(getSearchedInsuranceInvoice.rejected, state => {
        state.isLoading = false;
      })

      .addCase(CreateNewInvoicePayment.pending, state => {
        state.isLoading = true;
      })
      .addCase(CreateNewInvoicePayment.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action?.payload?.message);
      })
      .addCase(CreateNewInvoicePayment.rejected, state => {
        state.isLoading = false;
      })
      .addCase(CreateNewInsuranceInvoicePayment.pending, state => {
        state.isLoading = true;
      })
      .addCase(CreateNewInsuranceInvoicePayment.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action?.payload?.message);
      })
      .addCase(CreateNewInsuranceInvoicePayment.rejected, state => {
        state.isLoading = false;
      })
      .addCase(CreateNewCreditInvoicePayment.pending, state => {
        state.isLoading = true;
      })
      .addCase(CreateNewCreditInvoicePayment.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action?.payload?.message);
      })
      .addCase(CreateNewCreditInvoicePayment.rejected, state => {
        state.isLoading = false;
      })
      .addCase(CreateNewCreditInsuranceInvoicePayment.pending, state => {
        state.isLoading = true;
      })
      .addCase(CreateNewCreditInsuranceInvoicePayment.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action?.payload?.message);
      })
      .addCase(CreateNewCreditInsuranceInvoicePayment.rejected, state => {
        state.isLoading = false;
      })
      .addCase(updatePatientInvoice.pending, state => {
        state.isLoading = true;
      })
      .addCase(updatePatientInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action?.payload?.message);
      })
      .addCase(updatePatientInvoice.rejected, state => {
        state.isLoading = false;
      })
      .addCase(getCreditInvoicesList.pending, state => {
        state.isLoading = true;
      })
      .addCase(getCreditInvoicesList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.creditInvoiceList = action.payload;
      })
      .addCase(getCreditInvoicesList.rejected, state => {
        state.isLoading = false;
      })
      .addCase(getCreditCompanyInvoicesListApi.pending, state => {
        state.isLoading = true;
      })
      .addCase(getCreditCompanyInvoicesListApi.fulfilled, (state, action) => {
        state.creditCompanyInvoiceList = action?.payload;
        // toast.success(action?.payload?.message);
        state.isLoading = false;
      })
      .addCase(getCreditCompanyInvoicesListApi.rejected, state => {
        state.isLoading = false;
      })
      .addCase(getInsuranceInvoiceDetail.pending, state => {
        state.isLoading = true;
      })
      .addCase(getInsuranceInvoiceDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companyDetail = action.payload.data;
      })
      .addCase(getInsuranceInvoiceDetail.rejected, state => {
        state.isLoading = false;
      })
      .addCase(getInvoiceDetail.pending, state => {
        state.isLoading = true;
      })
      .addCase(getInvoiceDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invocieDetail = action.payload.data;
      })
      .addCase(getInvoiceDetail.rejected, state => {
        state.isLoading = false;
      });
  }
});
export const { setInitialCompaniesfilter, setInitialSalesfilter } = InvoicesSlice.actions;
export default InvoicesSlice.reducer;

// toast.success(action.payload.data || "Succesfull");
