import React, { useEffect, useState } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';

const ReusableTreeTable = props => {
  const {
    data,
    isLoading,
    columns,
    tableStyle = { minWidth: '50rem' },
    paginator = false,
    loadingIcon = null,
    globalFilter = null,
    filterMode = 'lenient',
    rest
  } = props;

  const { i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const [expandedKeys, setExpandedKeys] = useState({});

  // // Function to find and expand searched node
  const findAndExpandNode = (nodes, term) => {
    let matchedKey = null;
    let keysToExpand = new Set();
    const searchNode = (node, parentKeys = []) => {
      const nodeData = node.data || {};
      const fieldsToSearch = [nodeData.name_code_ar, nodeData.name_code_en, nodeData.name_ar, nodeData.name_en];
      if (fieldsToSearch.some(field => field && field.toLowerCase().includes(term.toLowerCase()))) {
        matchedKey = node.key;
        parentKeys.forEach(key => keysToExpand.add(key));
        return;
      }
      if (node.children && node.children.length > 0) {
        for (let child of node.children) {
          searchNode(child, [...parentKeys, node.key]);
          if (matchedKey) return;
        }
      }
    };

    nodes.forEach(node => searchNode(node));
    return { matchedKey, keysToExpand };
  };

  useEffect(() => {
    if (globalFilter) {
      const { matchedKey, keysToExpand } = findAndExpandNode(data, globalFilter);
      if (matchedKey !== null) {
        setExpandedKeys(prev => {
          const updatedKeys = { ...prev };
          keysToExpand.forEach(key => {
            updatedKeys[key] = true;
          });
          return updatedKeys;
        });
      }
    }
  }, [globalFilter, data]);

  return (
    <TreeTable
      value={data}
      loading={isLoading}
      loadingIcon={loadingIcon}
      tableStyle={tableStyle}
      paginator={paginator}
      globalFilter={globalFilter}
      filterMode={filterMode}
      expandedKeys={expandedKeys}
      onToggle={e => setExpandedKeys(e.value)}
      {...rest}
    >
      {columns.map((col, index) => (
        <Column
          key={index}
          field={col?.field}
          header={col?.header}
          body={col?.body}
          style={{ ...col.style }}
          expander={col?.expander}
          pt={{
            headerCell: {
              className: `!p-[0.3rem] !bg-secondary text-${isRtl ? 'right' : 'left'} ${col.headerCellClass || ''}`,
              ...col.pt?.headerCell
            },
            bodyCell: {
              className: `!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary text-${
                isRtl ? 'right' : 'left'
              } ${col.bodyCellClass || ''}`,
              ...col.pt?.bodyCell
            },
            ...col.pt
          }}
          {...col.extraProps}
        />
      ))}
    </TreeTable>
  );
};

export default ReusableTreeTable;
