import React, { useEffect, useRef, useState } from 'react';
import SharedButton from '../../components/Shared/SharedButton';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'primereact/menu';
import { AutoComplete } from 'primereact/autocomplete';
import * as XLSX from 'xlsx';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { getOneMonthAgo } from '../CashInflow/CashInflow';
import AccountStatementTable from './AccountStatementTable';
import { getAccountStatementList } from '../../store/slices/ReportSlice';
import { fetchData } from '../../helpers/fetchData';

export default function AccountStatementMain() {
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(getOneMonthAgo(endDate));
  const [accountId, setAccountId] = useState(null);
  const [accountOptions, setAccountOptions] = useState([]);
  const { accountStatementList } = useSelector(state => state.reports);
  const dispatch = useDispatch();
  const balanceSheetRef = useRef(null);
  const iframeRef = useRef(null);
  const lang = i18next.language;
  const menuRef = useRef(null);

  const handleSubmit = () => {
    if (startDate && endDate && accountId) {
      const payload = {
        start_date: startDate,
        end_date: endDate,
        account_id: accountId?.value,
        page: 1,
        per_page: 20
      };
      dispatch(getAccountStatementList(payload));
    }
  };

  useEffect(() => {
    const payload = {
      start_date: startDate,
      end_date: endDate,
      account_id: accountId,
      page: 1,
      per_page: 20
    };
    dispatch(getAccountStatementList(payload));
  }, []);

  const handlePrint = () => {
    const direction = lang == 'ar' ? 'rtl' : 'ltr';
    const printContents = balanceSheetRef.current.innerHTML;
    const iframe = iframeRef.current;
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write('<html><head><title>Balance Sheet</title>');
    doc.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css">');

    doc.write('<style>');
    doc.write(`
      @media print {
        @page {
          margin: 20px 0 20px 0; /* Removes the default margins */
        }
        body {
          margin: 1cm; /* Adjust the margins to your needs */
          direction: ${direction};
        }
        /* Hide browser print headers and footers */
        header, footer {
          display: none;
        }
        table {
          direction: ${direction};
        }
      }
    `);
    doc.write('</style>');

    doc.write('</head><body>');
    doc.write(printContents);
    doc.write('</body></html>');
    doc.close();

    iframe.onload = () => {
      iframe.contentWindow.print();
    };
  };

  const selectedOptionTemplate = option => {
    if (option) {
      return <div>{option.label}</div>;
    }
    return <span>{`${t('select')} ${t('account')}`}</span>;
  };

  const optionTemplate = option => {
    return <div>{option.label}</div>;
  };
  const extractData = element => {
    const data = [];
    const title = element.querySelector('.text-lg').textContent;
    data.push([title]);
    data.push(['Item', 'Amount']);

    const items = element.querySelectorAll('.flex.justify-between');
    items.forEach(item => {
      const name = item.querySelector('.font-medium').textContent.replace(':', '');
      const amount = item.querySelector('span:last-child').textContent;
      data.push([name, amount]);
    });

    return data;
  };

  const handleExcelExport = () => {
    if (!accountStatementList?.data || accountStatementList.data.length === 0) {
      console.error('No data available for export');
      return;
    }

    // Extracting data from accountStatementList
    const exportData = accountStatementList.data.map(item => ({
      TransactionID: item.transaction_id,
      BusinessID: item.transaction_business_id,
      BranchID: item.transaction_branch_id,
      Date: new Date(item.transaction_date).toLocaleString(), // Format the date as needed
      Description: item.transaction_description,
      PaymentMethod: item.transaction_payment_method,
      TotalAmount: item.transaction_total_amount,
      TotalCredit: item.transaction_total_credit,
      TotalDebit: item.transaction_total_debit,
      CreatedAt: new Date(item.transaction_created_at).toLocaleString(),
      UpdatedAt: new Date(item.transaction_updated_at).toLocaleString(),
      EntryID: item.entry_id,
      AccountID: item.entry_account_id,
      EntryAmount: item.entry_amount,
      EntryType: item.entry_type,
      EntryDescription: item.entry_description,
      AccountID: item.account_id,
      AccountNameEn: item.account_name_en,
      AccountNameAr: item.account_name_ar,
      AccountCode: item.account_code,
      AccountTypeEn: item.account_type_en,
      AccountTypeAr: item.account_type_ar,
      AccountCategory: item.account_category,
      AccountParentCode: item.account_parent_code,
      AccountSubTypeEn: item.account_sub_type_en,
      AccountSubTypeAr: item.account_sub_type_ar,
      AccountBalance: item.account_balance,
      AccountDescription: item.account_description
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Account Statement');

    // Setting column widths
    worksheet['!cols'] = [
      { wch: 15 }, // TransactionID
      { wch: 15 }, // BusinessID
      { wch: 15 }, // BranchID
      { wch: 20 }, // Date
      { wch: 30 }, // Description
      { wch: 20 }, // PaymentMethod
      { wch: 15 }, // TotalAmount
      { wch: 15 }, // TotalCredit
      { wch: 15 }, // TotalDebit
      { wch: 20 }, // CreatedAt
      { wch: 20 }, // UpdatedAt
      { wch: 15 }, // EntryID
      { wch: 15 }, // AccountID
      { wch: 15 }, // EntryAmount
      { wch: 15 }, // EntryType
      { wch: 30 }, // EntryDescription
      { wch: 15 }, // AccountID
      { wch: 30 }, // AccountNameEn
      { wch: 30 }, // AccountNameAr
      { wch: 15 }, // AccountCode
      { wch: 15 }, // AccountTypeEn
      { wch: 15 }, // AccountTypeAr
      { wch: 15 }, // AccountCategory
      { wch: 15 }, // AccountParentCode
      { wch: 20 }, // AccountSubTypeEn
      { wch: 20 }, // AccountSubTypeAr
      { wch: 20 }, // AccountBalance
      { wch: 30 } // AccountDescription
    ];

    // Writing the file
    XLSX.writeFile(workbook, 'Account_Statement.xlsx');
  };

  const items = [
    {
      label: t('EXCEL'),
      icon: 'pi pi-file-excel',
      command: handleExcelExport
    },
    {
      label: t('PDF'),
      icon: 'pi pi-print',
      command: handlePrint
    }
  ];

  const handleComplete = event => {
    fetchAccountOptions(event.query);
  };

  const fetchAccountOptions = async query => {
    let payload = { query };
    const data = await fetchData('POST', 'searchAllAccounts', payload);
    if (data) {
      const listAccountsValues = data?.data
        ? Object.entries(data.data).map(([id, account]) => ({
            value: Number(account?.id),
            label: lang === 'ar' ? account.name_ar + ' ' + account?.code : account.name_en + ' ' + account?.code
          }))
        : [];
      setAccountOptions(listAccountsValues);
    }
  };
  return (
    <div>
      <div className='flex justify-between'>
        <div className='flex gap-5 flex-wrap'>
          <div className='flex items-center gap-2'>
            <label className='text-sm'>{`${t('select')} ${t('account')}`}</label>
            <AutoComplete
              id='account_id'
              field='label'
              placeholder={`${t('select')} ${t('account')}`}
              value={accountId}
              onChange={e => setAccountId(e.value)}
              suggestions={accountOptions}
              completeMethod={handleComplete}
              forceSelection
              pt={{
                input: {
                  root: {
                    className:
                      'w-full p-0 border-gray-300 rounded-md hover:border-gray-500 !outline-none !shadow-none !p-1.5'
                  }
                },
                panel: { className: '!shadow-lg rounded-[8px] mt-2 pb-2 overflow-hidden' },
                loadingIcon: { className: 'absolute top-[13px] right-[12px]' }
              }}
            />
          </div>
          <div className='flex items-center gap-2'>
            <label className='text-sm'>{t('StartDate')}</label>
            <input
              required
              type='date'
              name='start_date'
              value={startDate}
              onChange={e => {
                setStartDate(e.target.value);
              }}
              id='dobDate'
              autoComplete='off'
              className='p-[0.33rem] border rounded-md'
            />
          </div>
          <div className='flex items-center gap-2'>
            <label className='text-sm'>{t('EndDate')}</label>
            <input
              required
              type='date'
              name='end_date'
              value={endDate}
              onChange={e => {
                setEndDate(e.target.value);
              }}
              id='dobDate'
              autoComplete='off'
              className='p-[0.33rem] border rounded-md'
            />
          </div>
          <div className=''>
            <SharedButton
              label={t('Submit')}
              className='bg-gradient primary-btn'
              onClick={handleSubmit}
              // disabled={!startDate || !endDate}
              disabled={!startDate || !endDate || !accountId}
            />
          </div>
        </div>

        <div className=''>
          <SharedButton
            label={`${t('Export')}`}
            icon='pi pi-download'
            iconPos='right'
            onClick={e => menuRef.current.toggle(e)}
            className='bg-gradient primary-btn'
          />
          <Menu
            pt={{
              root: { className: '!py-0 !bg-none' },
              content: { className: '!p-0 !bg-none !text-gray-500' },
              action: { className: '!py-1.5 !px-2 !bg-none !text-gray-500' },
              menuitem: { className: '!bg-none' },
              menu: { className: '!bg-gray-100' },
              icon: { className: '!text-gray-500' }
            }}
            model={items}
            popup
            ref={menuRef}
          />
        </div>
      </div>

      <div className='this_div' ref={balanceSheetRef}>
        <div className='pb-8'>
          <div className='title !my-8'>
            <div className='flex justify-center'>
              <div className='mx-10 !mt-1'>
                <div className='font-semibold text-black/70 text-xl pb-4'>{`${t('accountStatement')}`}</div>{' '}
              </div>
            </div>
          </div>

          <div className=''>
            <div
              className=''
              pt={{
                content: { className: '!py-0' }
              }}
            >
              <div className=''>
                <AccountStatementTable data={accountStatementList?.data} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <iframe ref={iframeRef} style={{ display: 'none' }}></iframe>
    </div>
  );
}
