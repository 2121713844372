import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import { Chart } from 'primereact/chart';
import { FaFilter } from 'react-icons/fa6';
import FilterSidebar from './home/FilterSidebar';
import { fetchData } from '../helpers/fetchData';

export default function Home() {
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [dashboardStats, setDashboardStats] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [darkMode, setDarkMode] = useState(false);
  const [filterDate, setFilterDate] = useState({
    start_date: '',
    end_date: ''
  });

  // Set Default Filter Date and trigger fetch data when component mounts
  useEffect(() => {
    const currentDate = new Date();
    const endDate = currentDate.toISOString().split('T')[0];

    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1); // Set to 1 month ago
    const formattedStartDate = startDate.toISOString().split('T')[0];

    setFilterDate({
      start_date: formattedStartDate,
      end_date: endDate
    });

    let payload = {
      start_date: formattedStartDate,
      end_date: endDate
    };
    fetchAllData(payload);
  }, []);

  // Sales Chart Data
  const salesChartData = {
    labels: graphData?.map(item => item.month),
    datasets: [
      {
        label: t('sales'),
        data: graphData?.map(item => item.total_sales),
        backgroundColor: '#50C878',
        borderColor: '#50C878',
        borderWidth: 1,
        barThickness: 70
      },
      {
        label: t('purchases'),
        data: graphData?.map(item => item.total_purchases),
        backgroundColor: '#F4BB44',
        borderColor: '#F4BB44',
        borderWidth: 1,
        barThickness: 70
      }
    ]
  };

  // Cash Flow Chart Data Options
  const cashflowChartData = {
    labels: graphData?.map(item => item.month),
    datasets: [
      {
        label: t('BankTransferFlow'),
        data: graphData?.map(item => item.bank_flow),
        backgroundColor: '#0096FF',
        borderColor: '#0096FF',
        borderWidth: 1,
        barThickness: 70
      },
      {
        label: t('CashInflow'),
        data: graphData?.map(item => item.cash_flow),
        backgroundColor: '#DE3163',
        borderColor: '#DE3163',
        borderWidth: 1,
        barThickness: 70
      }
    ]
  };

  // Function to handle filter updates and trigger data fetching
  const handleUpdateFilters = async () => {
    let payload = {
      start_date: filterDate?.start_date,
      end_date: filterDate?.end_date
    };
    await fetchAllData(payload);
    setSidebarVisible(false);
  };

  // Function to fetch dashboard stats and update the state
  const getDashboardStats = async payload => {
    const data = await fetchData('POST', 'getStats', payload);
    if (data) setDashboardStats(data?.data);
  };

  // Function to fetch graph stats and update the state
  const getGraphStats = async payload => {
    const data = await fetchData('POST', 'getStatByMonths', payload);
    if (data) setGraphData(data?.data);
  };

  // Function to fetch all necessary data (dashboard stats, graph stats, and transactions)
  const fetchAllData = async payload => {
    setIsLoading(true);
    const apiCalls = [getDashboardStats(payload), getGraphStats(payload)];
    await Promise.all(apiCalls);
    setIsLoading(false);
  };

  return (
    <div className={`min-h-screen xl:min-h-[calc(100vh-100px)] ${darkMode ? 'bg-gray-900 text-white' : 'transparent'}`}>
      <div className='container mx-auto px-4 py-8'>
        <div className='flex items-center mb-2'>
          <h3 className='text-2xl font-[600] me-2 text-gray-800'>{t('apply_filter')}</h3>{' '}
          <FaFilter className='text-primary cursor-pointer' size={20} onClick={() => setSidebarVisible(true)} />
        </div>

        <FilterSidebar
          visible={isSidebarVisible}
          setVisible={setSidebarVisible}
          filterDate={filterDate}
          setFilterDate={setFilterDate}
          handleUpdateFilters={handleUpdateFilters}
          loading={isLoading}
        />

        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6'>
          <Card title={t('total_sales')} className={darkMode ? 'bg-gray-800' : ''}>
            <p className='text-2xl font-bold'>
              {dashboardStats?.total_sales} {t('SAR')}
            </p>
          </Card>
          <Card title={t('total_purchase')} className={darkMode ? 'bg-gray-800' : ''}>
            <p className='text-2xl font-bold'>
              {dashboardStats?.total_purchases} {t('SAR')}
            </p>
          </Card>
          <Card title={t('BankTransferFlow')} className={darkMode ? 'bg-gray-800' : ''}>
            <p className='text-2xl font-bold'>
              {dashboardStats?.bank_flow} {t('SAR')}
            </p>
          </Card>
          <Card title={t('CashInflow')} className={darkMode ? 'bg-gray-800' : ''}>
            <p className='text-2xl font-bold'>
              {dashboardStats?.cash_flow} {t('SAR')}
            </p>
          </Card>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 gap-3 mb-6'>
          <Card
            title={t('sales_vs_purchase')}
            className={darkMode ? 'bg-gray-800' : ''}
            pt={{ root: { className: 'xl:h-[600px]' }, content: { className: 'h-[500px]' } }}
          >
            <Chart
              type='bar'
              data={salesChartData}
              pt={{ root: { className: '!h-[500px]' }, canvas: { className: '!h-[500px]' } }}
            />
          </Card>
          <Card
            title={t('bankflow_vs_cashflow')}
            className={darkMode ? 'bg-gray-800' : ''}
            pt={{ root: { className: 'xl:h-[600px]' }, content: { className: 'h-[500px]' } }}
          >
            <Chart
              type='bar'
              data={cashflowChartData}
              pt={{ root: { className: '!h-[500px]' }, canvas: { className: '!h-[500px]' } }}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}
