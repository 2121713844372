import React, { useEffect, useRef, useState } from 'react';
import { Menu } from 'primereact/menu';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import SharedButton from '../../components/Shared/SharedButton';
import TrialBalanceTable from './TrialBalanceTable';
import { getTrialBalanceList } from '../../store/slices/ReportSlice';
import { getOneMonthAgo } from '../CashInflow/CashInflow';

export default function Ledger() {
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [startDate, setStartDate] = useState(getOneMonthAgo(endDate));
  const { trialBalanceList } = useSelector(state => state.reports);
  const dispatch = useDispatch();
  const menuRef = useRef(null);
  const balanceSheetRef = useRef(null);
  const iframeRef = useRef(null);
  const { t } = useTranslation();
  const lang = i18next.language;

  const handleSubmit = () => {
    if (startDate && endDate) {
      const payload = {
        start_date: startDate,
        end_date: endDate,
        page: 1,
        per_page: 20
      };
      dispatch(getTrialBalanceList(payload));
    }
  };

  useEffect(() => {
    const payload = {
      start_date: startDate,
      end_date: endDate,
      page: 1,
      per_page: 20
    };
    dispatch(getTrialBalanceList(payload));
  }, []);

  const handlePrint = () => {
    const direction = lang == 'ar' ? 'rtl' : 'ltr';
    const printContents = balanceSheetRef.current.innerHTML;
    const iframe = iframeRef.current;
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write('<html><head><title>Balance Sheet</title>');
    doc.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css">');

    doc.write('<style>');
    doc.write(
      `@media print { @page { margin: 20px 0 20px 0; } body { margin: 1cm; direction: ${direction}; } header, footer { display: none; } }`
    );
    doc.write('</style>');
    doc.write('</head><body>');
    doc.write(printContents);
    doc.write('</body></html>');
    doc.close();

    iframe.onload = () => {
      iframe.contentWindow.print();
    };
  };

  const handleExcelExport = () => {
    // Check if trialBalanceList is an array
    console.log(trialBalanceList); // Check the structure of the trialBalanceList

    const data = trialBalanceList?.data;

    if (!Array.isArray(data) || data.length === 0) {
      console.error('No data available for export');
      return;
    }

    // Initialize totals
    let totalDebit = 0;
    let totalCredit = 0;
    let totalOpeningBalance = 0;
    let totalClosingBalance = 0;

    // Prepare export data excluding movement
    const exportData = data.map(item => {
      // Add opening balance
      totalOpeningBalance += item.opening_balance || 0;

      // Initialize debit and credit
      let debit = 0;
      let credit = 0;

      // Check the movement to determine debit or credit
      if (item.movement < 0) {
        credit = Math.abs(item.movement); // If movement is negative, it's a credit
        totalCredit += credit; // Aggregate total credit
      } else {
        debit = item.movement; // Otherwise, it's a debit
        totalDebit += debit; // Aggregate total debit
        credit = 0; // Set credit to zero for debit entries
      }

      // Add closing balance
      totalClosingBalance += item.closing_balance || 0;

      // Return the object for export without the movement field
      return {
        account_code: item.account_code,
        account_name: item.account_name,
        account_type: item.account_type,
        closing_balance: item.closing_balance,
        opening_balance: item.opening_balance,
        debit: debit,
        credit: credit
      };
    });

    // Append totals to export data
    exportData.push({
      account_code: 'Totals',
      account_name: '',
      account_type: '',
      closing_balance: totalClosingBalance,
      opening_balance: totalOpeningBalance,
      debit: totalDebit,
      credit: totalCredit
    });

    // Convert JSON data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Trial Balance');

    // Export the workbook to an Excel file
    XLSX.writeFile(workbook, 'Trial_Balance.xlsx');
  };

  const items = [
    {
      label: t('EXCEL'),
      icon: 'pi pi-file-excel',
      command: handleExcelExport
    },
    {
      label: t('PDF'),
      icon: 'pi pi-print',
      command: handlePrint
    }
  ];

  return (
    <div>
      <div className='flex justify-between'>
        <div className='flex gap-5 flex-wrap'>
          <div className='flex items-center gap-2'>
            <label className='text-sm'>{t('StartDate')}</label>
            <input
              required
              type='date'
              name='start_date'
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
              id='dobDate'
              autoComplete='off'
              className='p-[0.33rem] border rounded-md'
            />
          </div>
          <div className='flex items-center gap-2'>
            <label className='text-sm'>{t('EndDate')}</label>
            <input
              required
              type='date'
              name='end_date'
              value={endDate}
              onChange={e => setEndDate(e.target.value)}
              id='dobDate'
              autoComplete='off'
              className='p-[0.33rem] border rounded-md'
            />
          </div>
          <div className=''>
            <SharedButton
              label={t('Submit')}
              className='bg-gradient primary-btn'
              onClick={handleSubmit}
              disabled={!startDate || !endDate}
            />
          </div>
        </div>

        <div className=''>
          <SharedButton
            label={`${t('Export')}`}
            icon='pi pi-download'
            iconPos='right'
            onClick={e => menuRef.current.toggle(e)}
            className='bg-gradient primary-btn'
          />
          <Menu
            pt={{
              root: { className: '!py-0 !bg-none' },
              content: { className: '!p-0 !bg-none !text-gray-500' },
              action: { className: '!py-1.5 !px-2 !bg-none !text-gray-500' },
              menuitem: { className: '!bg-none' },
              menu: { className: '!bg-gray-100' },
              icon: { className: '!text-gray-500' }
            }}
            model={items}
            popup
            ref={menuRef}
          />
        </div>
      </div>

      <div className='this_div' ref={balanceSheetRef}>
        <div className='pb-8'>
          <div className='title !my-8'>
            <div className='flex justify-center'>
              <div className='mx-10 !mt-1'>
                <div className='font-semibold text-black/70 text-xl pb-4'>{`${t('TrialBalance')}`}</div>
              </div>
            </div>
          </div>

          <div className=''>
            <div
              className=''
              pt={{
                content: { className: '!py-0' }
              }}
            >
              <div className=''>
                <TrialBalanceTable data={trialBalanceList} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <iframe ref={iframeRef} style={{ display: 'none' }}></iframe>
    </div>
  );
}
