import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PatientsVat from './PatientsVat';
import CompaniesVat from './CompaniesVat';

export default function VatReport() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('patientsVat');
  const tabList = [
    {
      label: t('Patients VAT'),
      comapre: 'patientsVat',
      command: () => setActiveTab('patientsVat')
    },
    {
      label: t('Companies VAT'),
      comapre: 'companiesVat',
      command: () => setActiveTab('companiesVat')
    }
  ];

  return (
    <div>
      <div>
        {' '}
        {/* <div className=" col-span-5 p-2 rounded h-[75vh] overflow-auto"> */}
        <div className='ye'>
          <ul className='grid grid-flow-col mb-2 text-center text-gray-500 bg-gray-100 border rounded p-1 shadow-inner'>
            {tabList.map(tab => (
              <li>
                <a
                  className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                    activeTab === tab?.comapre ? 'bg-gradient rounded shadow text-white' : ''
                  }`}
                  onClick={() => tab?.command()}
                >
                  {tab.label}
                </a>
              </li>
            ))}
          </ul>
          <div className='rounded-md h-[61vh] overflow-auto'>
            {activeTab === 'patientsVat' ? <PatientsVat /> : activeTab === 'companiesVat' ? <CompaniesVat /> : null}
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
