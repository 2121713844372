import React, { useEffect, useState } from 'react';
import SharedTooltip from '../../components/Shared/SharedTooltip';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { MdOutlineDelete, MdOutlineEdit } from 'react-icons/md';
import { IoMdEye } from 'react-icons/io';
import SharedButton from '../../components/Shared/SharedButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../Transactions/TransactionsTable';
import { getCostCenter } from '../../store/slices/CostCenterSlice';
import { deleteReceiptVoucher, getReceiptVoucher } from '../../store/slices/VoucherSlice';
import SharedConfirmDialog from '../../components/Shared/SharedConfirmDialog';
import CustomPagination from '../../components/Shared/CustomPagination';
import TableLoading from '../../components/Shared/TableLoading';
import CustomTable from '../../components/Shared/CustomTable';

function ReceiptVoucher() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { receiptVouchers, isLoading } = useSelector(state => state.voucher);
  const [deleteVoucher, setDeleteVoucher] = useState(false);
  const [delId, setDelId] = useState(null);
  const { costCenters } = useSelector(state => state.costCenter);
  const lang = i18next.language;

  const sequenceBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1 + (receiptVouchers?.page - 1) * receiptVouchers?.per_page;
  };
  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(getReceiptVoucher({ page: newPage, per_page: newRowsPerPage }));
  };
  const costCenterTempelate = rowData => {
    const center = costCenters?.data?.find(center => center?.id == rowData?.cost_center_id);
    return lang === 'ar' ? center?.name_ar : center?.name_en;
  };
  const templateBody = rowData => {
    return (
      <div className='flex  gap-1'>
        <SharedTooltip content='view' id='my-tooltip-1' />
        <SharedTooltip id='my-tooltip-2' content='edit' />
        <SharedTooltip id='my-tooltip-3' content='delete' />
        <IoMdEye size={25} data-tooltip-id='my-tooltip-1' className='table-icon' />
        <MdOutlineEdit
          size={25}
          data-tooltip-id='my-tooltip-2'
          onClick={() => {
            navigate('/manager/create/receipt/voucher', {
              state: { data: rowData }
            });
          }}
          className='table-icon'
        />
        <MdOutlineDelete
          size={25}
          onClick={() => {
            setDeleteVoucher(true);
            setDelId(rowData?.id);
          }}
          data-tooltip-id='my-tooltip-3'
          className='table-icon'
        />
      </div>
    );
  };

  useEffect(() => {
    dispatch(getCostCenter());
    dispatch(getReceiptVoucher({ page: 1, per_page: 10 }));
  }, []);

  const columnsData = [
    {
      body: sequenceBodyTemplate,
      header: t('no'),
      style: { width: '3rem' }
    },
    {
      field: 'date',
      header: t('date'),
      body: rowData => formatDate(rowData.date)
    },
    {
      body: costCenterTempelate,
      header: t('costCenter')
    },
    {
      field: 'total_amount',
      header: t('total_amount')
    },
    {
      body: templateBody,
      header: t('actions')
    }
  ];

  return (
    <div>
      <div className='flex justify-between items-center flex-col gap-3 sm:gap-0 sm:flex-row mb-3'>
        <SharedTooltip content={t('receipt_vouchers')} position='left' id='Payment' />
        <span data-tooltip-id='Payment' className='font-semibold text-black/70 text-xl mt-2'>
          {t('receipt_vouchers')}
          <span className='italic text-lg ml-1'>
            ({receiptVouchers?.data?.length || 0}
            <span className='text-xs font-bold italic'> {t('Entries')}</span>)
          </span>
        </span>
        <div className='flex items-center gap-4'>
          <SharedButton
            label={t('CreateReceiptVoucher')}
            icon='pi pi-plus'
            onClick={() => navigate('/manager/create/receipt/voucher')}
            className='bg-gradient  primary-btn'
          />
        </div>
      </div>
      <div>
        <CustomTable
          data={receiptVouchers?.data}
          isLoading={isLoading}
          loadingIcon={<TableLoading />}
          columns={columnsData}
          rows={receiptVouchers?.per_page}
        />
        {receiptVouchers?.data?.length > 0 && (
          <CustomPagination
            currentPage={receiptVouchers?.page}
            rowsPerPage={receiptVouchers?.per_page}
            totalRecords={receiptVouchers?.total_pages}
            totalRecord={receiptVouchers?.total_record}
            onPageChange={onPageChange}
          />
        )}
      </div>
      <SharedConfirmDialog
        visible={deleteVoucher}
        setVisible={setDeleteVoucher}
        message='DoyouwanttoDelete?'
        rejectLabel='No'
        acceptLabel='Yes'
        onAccept={() => {
          dispatch(deleteReceiptVoucher(delId)).then(() => {
            setDeleteVoucher(false);
            dispatch(getReceiptVoucher({ page: 1, per_page: 10 }));
          });
        }}
        onReject={() => setDeleteVoucher(false)}
      />
    </div>
  );
}

export default ReceiptVoucher;
