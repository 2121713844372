import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import SharedTooltip from '../../components/Shared/SharedTooltip';
import { getInvoiceSettings, updateInvoiceSettings } from '../../store/slices/InvoiceSettingsSlice';
import SettingsList from '../../components/settings/SettingsList';
import DropdownComponent from '../../components/Shared/DropdownComponent';
import SwitchInput from '../../components/Shared/SwitchInput';

const Settings = () => {
  const { t } = useTranslation();
  const lang = i18next.language;
  const dispatach = useDispatch();
  const [invoiceSettingsOptions, setInvoiceSettingsOptions] = useState({
    coaCheck: '',
    companyInvoiceSize: '',
    saleInvoiceSize: '',
    companyCoaCheck: '',
    customerCoaCheck: ''
  });
  const { invoiceSettings, isLoading } = useSelector(state => state?.invoiceSettings);
  const isAllowEdit = invoiceSettings?.coaCheck?.value == 'true';

  const coaTypesOptions = [
    { value: true, label: lang == 'ar' ? 'نشيط' : 'Active' },
    { value: false, label: lang == 'ar' ? 'في نشط' : 'In Active' }
  ];

  const invocieSizeoptions = [
    { value: 'a4', label: 'A4' },
    { value: 'a2', label: 'A2' }
  ];

  // useEffect to fetch initial settings data on component mount
  useEffect(() => {
    dispatach(getInvoiceSettings());
  }, []);

  useEffect(() => {
    setInvoiceSettingsOptions(prev => ({
      ...prev,
      coaCheck: invoiceSettings?.coaCheck?.value == 'false' ? false : true,
      companyInvoiceSize: invoiceSettings?.companyInvoiceSize?.value,
      saleInvoiceSize: invoiceSettings?.saleInvoiceSize?.value,
      companyCoaCheck: invoiceSettings?.companyCoaCheck?.value == 'false' ? false : true,
      customerCoaCheck: invoiceSettings?.customerCoaCheck?.value == 'false' ? false : true
    }));
  }, [invoiceSettings]);

  const handleUpdateSettings = (event, fieldName) => {
    let selectedObj = { ...invoiceSettings[fieldName] };
    selectedObj.value = String(event?.value);
    if (selectedObj) {
      let payload = {
        id: selectedObj?.id,
        data: selectedObj
      };
      setInvoiceSettingsOptions(prev => ({
        ...prev,
        [fieldName]: event.value
      }));
      dispatach(updateInvoiceSettings(payload)).then(res => {
        // Get invoices gettings only when COA check field is changed
        if (fieldName == 'coaCheck' && res?.meta?.requestStatus === 'fulfilled') {
          dispatach(getInvoiceSettings());
        }
      });
    }
  };

  return (
    <div>
      <div className='flex items-center justify-between flex-col gap-3 sm:gap-0 sm:flex-row mb-3'>
        <SharedTooltip content={t('Settings')} position='left' id='Settings' />
        <span data-tooltip-id='Settings' className='font-semibold text-black/70 text-xl mt-2 '>
          {t('Settings')}
        </span>
        <div className='grid grid-cols-2 md:grid-cols-3 lg:flex lg:items-center gap-4'>
          <SwitchInput
            value={invoiceSettingsOptions?.coaCheck}
            label={t('select_coa_status')}
            onChange={e => handleUpdateSettings(e, 'coaCheck')}
            disabled={isLoading}
          />
          <SwitchInput
            label={t('select_company_coa_status')}
            value={invoiceSettingsOptions?.companyCoaCheck}
            onChange={e => handleUpdateSettings(e, 'companyCoaCheck')}
            disabled={isLoading || !isAllowEdit}
          />
          <SwitchInput
            label={t('select_customer_coa_status')}
            value={invoiceSettingsOptions?.customerCoaCheck}
            onChange={e => handleUpdateSettings(e, 'customerCoaCheck')}
            disabled={isLoading || !isAllowEdit}
          />
          <DropdownComponent
            label={t('select_company_invoice_size')}
            value={invoiceSettingsOptions?.companyInvoiceSize}
            options={invocieSizeoptions}
            optionLabel='label'
            placeholder={t('select_company_invoice_size')}
            onChange={e => handleUpdateSettings(e, 'companyInvoiceSize')}
            disabled={isLoading}
          />
          <DropdownComponent
            label={t('select_sales_invoice_size')}
            value={invoiceSettingsOptions?.saleInvoiceSize}
            options={invocieSizeoptions}
            optionLabel='label'
            placeholder={t('select_sales_invoice_size')}
            onChange={e => handleUpdateSettings(e, 'saleInvoiceSize')}
            disabled={isLoading}
          />
        </div>
      </div>
      <SettingsList />
    </div>
  );
};

export default Settings;
