import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

const TreeNode = ({ node, level }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();
  const language = i18next.language;

  if (!node || !node.account) {
    return null;
  }

  const hasChildren = node.account.sub_ledgers && node.account.sub_ledgers.length > 0;
  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <>
      <div
        className={`grid grid-cols-12 items-center py-2 border-b px-4 ${
          level === 0 ? "font-medium" : ""
        }`}
      >
        <div
          className="col-span-9 flex items-center"
          style={{ paddingLeft: `${level * 20}px` }}
        >
          {hasChildren && (
            <button onClick={toggleOpen} className="mr-2">
              {isOpen ? (
                <IoIosArrowDown size={16} />
              ) : (
                <IoIosArrowForward size={16} />
              )}
            </button>
          )}
          <span>
            {language === "en" ? node.account.name_en : node.account.name_ar}
          </span>
        </div>
        <div className="col-span-3">
          {node.account.balance !== undefined ? node.account.balance.toFixed(2) : "0"}{" "}
          <span>{t("SAR")}</span>
        </div>
      </div>
      {isOpen &&
        hasChildren &&
        node.account.sub_ledgers.map((child, index) => (
          <TreeNode
            key={child.account ? child.account.id : index}
            node={child}
            level={level + 1}
            language={language}
          />
        ))}
    </>
  );
};

const LedgerExpenses = ({ data, language = "en" }) => {
  const { t } = useTranslation();

  if (!data || !data?.expenses || !data?.expenses?.accounts) {
    return <div>{t("No data available")}</div>;
  }

  return (
    <>
      <div className="py-2 text-xl font-semibold">3 - {t("Expense")}</div>
      <div className="bg-white rounded-lg shadow overflow-hidden my-2">
        <div className="grid grid-cols-12 bg-gray-200 font-bold py-2 px-4">
          <div className="col-span-9">{t("name")}</div>
          <div className="col-span-3">{t("balance")}</div>
        </div>
        {data?.expenses?.accounts.map((account, index) => (
          <TreeNode
            key={account.account ? account.account.id : index}
            node={account}
            level={0}
            language={language}
          />
        ))}
      </div>
    </>
  );
};

export default LedgerExpenses;

