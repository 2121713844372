import React, { useState } from 'react';
import { formatTimestamp } from '../../components/Shared/SharedMethods';
import SharedTooltip from '../../components/Shared/SharedTooltip';
import { MdOutlineDelete, MdOutlineEdit } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomersList } from '../../store/slices/CustomerSlice';
import CustomPagination from '../../components/Shared/CustomPagination';
import { useTranslation } from 'react-i18next';
import CreateCustomer from './CreateCustomer';
import { deleteCustomer } from '../../store/slices/CustomerSlice';
import ConfirmationDialog from '../../components/Shared/ConfirmationDialog';
import TableLoading from '../../components/Shared/TableLoading';
import CustomTable from '../../components/Shared/CustomTable';

export default function CustomerTable({ data }) {
  const datebody = rowData => {
    return formatTimestamp(rowData.CreatedAt);
  };
  const [selectedData, setSelectedData] = useState(null);
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
  const { isLoading } = useSelector(state => state.customers);
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(
      getCustomersList({
        page: newPage,
        per_page: newRowsPerPage
      })
    );
  };
  const templateBody = rowData => (
    <div className='flex gap-1'>
      <SharedTooltip content='view' id='my-tooltip-1' />
      <SharedTooltip id='my-tooltip-2' content='edit' />
      <SharedTooltip id='my-tooltip-3' content='delete' />
      <MdOutlineEdit
        size={25}
        data-tooltip-id='my-tooltip-2'
        className='table-icon'
        onClick={() => {
          setEditData(rowData);
          setCreateDialogVisible(true);
        }}
      />
      <MdOutlineDelete
        size={25}
        data-tooltip-id='my-tooltip-3'
        onClick={() => {
          setConfirmationDialogVisible(true);
          setSelectedData(rowData);
        }}
        className='table-icon'
      />
    </div>
  );

  const sequenceBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1 + (data?.page - 1) * data?.per_page;
  };

  const confirmDeleteHandler = () => {
    if (selectedData) {
      dispatch(deleteCustomer({ id: selectedData.id })).then(res => {
        dispatch(getCustomersList({ page: 1, per_page: 10 }));
      });
      setConfirmationDialogVisible(false);
      setSelectedData(null);
    }
  };

  const columnsData = [
    {
      body: sequenceBodyTemplate,
      header: t('no'),
      style: { width: '3rem' }
    },
    {
      field: 'name',
      header: t('name')
    },
    {
      field: 'document_id',
      header: t('document_id')
    },
    {
      field: 'primary_contact_number',
      header: t('primaryContactNumber')
    },
    {
      field: 'primary_email_id',
      header: t('primaryEmailId')
    },
    {
      field: 'status',
      header: t('status')
    },
    {
      body: templateBody,
      header: t('actions')
    }
  ];

  return (
    <>
      <CustomTable
        data={data?.data}
        isLoading={isLoading}
        loadingIcon={<TableLoading />}
        columns={columnsData}
        rows={data?.per_page}
      />
      <ConfirmationDialog
        title={t('delete_confirmation_popup_text')}
        isDialogVisible={confirmationDialogVisible}
        setIsDialogVisible={setConfirmationDialogVisible}
        confirmHandler={confirmDeleteHandler}
      />
      <CreateCustomer
        setCreateDialogVisible={setCreateDialogVisible}
        createDialogVisible={createDialogVisible}
        editData={editData}
      />
      {data?.data?.length > 0 && (
        <CustomPagination
          currentPage={data?.page}
          rowsPerPage={data?.per_page}
          totalRecords={data?.total_pages}
          totalRecord={data?.total_record}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
}
