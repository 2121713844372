import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { Formik, FieldArray, Field } from 'formik';
import * as yup from 'yup';
import SharedInputText from '../../components/Shared/SharedInputText';
import SharedButton from '../../components/Shared/SharedButton';
import { useDispatch, useSelector } from 'react-redux';
import SharedDropdown from '../../components/Shared/SharedDropdown';
import { createTransaction, getTransactions, updateTransaction } from '../../store/slices/TransactionsSlice';
import { getListAccounts } from '../../store/slices/AcountsSlice';
import { RiDeleteBin5Line } from 'react-icons/ri';
import Loader from '../../Loader';
import CustomSearchDropdown from '../../components/Shared/CustomSearchDropdown';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { getBranches } from '../../store/slices/BusinessSlice';
import { getCostCenter } from '../../store/slices/CostCenterSlice';
import CustomSearchInput from '../../components/Shared/CustomSearchInput';
import { getCurrentDateTime } from './CreateTransaction';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
export function convertISOToLocalDate(isoString) {
  const date = new Date(isoString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}
export default function CreateTransactionPage() {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { listAccounts } = useSelector(state => state.acount);
  const { isLoading } = useSelector(state => state.transaction);
  const { costCenters } = useSelector(state => state.costCenter);
  const { branches } = useSelector(state => state.business);
  const lang = i18next.language;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   dispatch(getListAccounts());
  //   dispatch(getBranches());
  //   dispatch(getCostCenter());
  // }, []);

  const listAccountsvalues = listAccounts?.data
    ? Object.entries(listAccounts.data).map(([id, account]) => ({
        value: Number(account?.id),
        label: lang === 'ar' ? account.name_ar : account.name_en
      }))
    : [];

  const listBranchvalues = branches?.data
    ? Object.entries(branches?.data).map(([id, branch]) => ({
        value: Number(branch?.id),
        label: lang === 'ar' ? branch.name_ar : branch.name_en
      }))
    : [];

  const listCostCentervalues = costCenters?.data
    ? Object.entries(costCenters?.data).map(([id, costCenter]) => ({
        value: Number(costCenter?.id),
        label: lang === 'ar' ? costCenter.name_ar : costCenter.name_en
      }))
    : [];

  // const initialValues = {
  //   date: getCurrentDateTime(),
  //   description: "",
  //   entry_details: [
  //     {
  //       branch_id: "",
  //       costcenter_id: "",
  //       account_id: "",
  //       debit_amount: "",
  //       credit_amount: "",
  //       comment: "",
  //     },
  //   ],
  // };

  const [isEditing, setIsEditing] = useState(false);
  const [initialValues, setInitialValues] = useState({
    date: getCurrentDateTime(),
    description: '',
    entry_details: [
      {
        branch_id: '',
        cost_center_id: '',
        account_id: '',
        debit_amount: '',
        credit_amount: '',
        comment: ''
      }
    ]
  });
  console.log(location);

  useEffect(() => {
    dispatch(getListAccounts());
    dispatch(getBranches());
    dispatch(getCostCenter());

    if (location.state && location.state.transactionData) {
      setIsEditing(true);
      const { transactionData } = location.state;
      setInitialValues({
        date: convertISOToLocalDate(transactionData.created_at),
        description: transactionData.description,
        entry_details: transactionData?.transaction_entries?.map(entry => ({
          branch_id: entry.branch_id,
          cost_center_id: Number(entry.cost_center_id),
          account_id: entry.account_id,
          debit_amount: entry.type === 'Debit' ? entry.amount : 0,
          credit_amount: entry.type === 'Credit' ? entry.amount : 0,
          comment: entry.description,
          branch_id: entry?.branch_id
        }))
      });
    } else {
      setInitialValues({
        date: getCurrentDateTime(),
        description: '',
        entry_details: [
          {
            branch_id: '',
            cost_center_id: '',
            account_id: '',
            debit_amount: '',
            credit_amount: '',
            comment: ''
          }
        ]
      });
    }
  }, [dispatch, location.state]);

  const validationSchema = yup.object().shape({
    date: yup.string().required(t('required')),
    entry_details: yup.array().of(
      yup.object().shape({
        cost_center_id: yup.number().required(t('required')),
        branch_id: yup.number().required(t('required')),
        account_id: yup.number().required(t('required')),
        debit_amount: yup
          .number()
          .min(0, `${t('debitAmount')}${t('mustBePositive')}`)
          .required(t('required')),
        credit_amount: yup
          .number()
          .min(0, `${t('creditAmount')}${t('mustBePositive')}`)
          .required(t('required')),
        comment: yup.string()
      })
    )
  });

  const validateTotals = values => {
    const totalDebit = values.entry_details.reduce((sum, entry) => sum + Number(entry.debit_amount), 0);
    const totalCredit = values.entry_details.reduce((sum, entry) => sum + Number(entry.credit_amount), 0);
    return totalDebit === totalCredit;
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const payload = {
      ...values,
      entry_details: values.entry_details.map(entry => ({
        ...entry,
        amount: parseFloat(entry.debit_amount) || parseFloat(entry.credit_amount),
        type: parseFloat(entry.debit_amount) > 0 ? 'Debit' : 'Credit'
      }))
    };

    if (isEditing) {
      dispatch(
        updateTransaction({
          id: location.state.transactionData?.id,
          data: payload
        })
      ).then(() => {
        navigate('/manager/transactions');
      });
    } else {
      dispatch(createTransaction(payload))
        .then(() => {
          // toast.success(t("transactionCreated"));
          navigate('/manager/transactions');
        })
        .catch(error => {
          // toast.error(t("errorCreatingTransaction"));
          console.error('Error creating transaction:', error);
        });
    }
    setSubmitting(false);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        // onSubmit={async (data, { resetForm }) => {
        //   console.log("Submitting form with data:", data);
        //   const resultAction = await dispatch(createTransaction(data));
        //   if (createTransaction.fulfilled.match(resultAction)) {
        //     resetForm();
        //     navigate("/manager/transactions");
        //   }
        // }}

        // onSubmit={async (data, { resetForm }) => {
        //   console.log("Submitting form with data:", data);
        //   if (validateTotals(data)) {
        //     const resultAction = await dispatch(createTransaction(data));
        //     if (createTransaction.fulfilled.match(resultAction)) {
        //       navigate("/manager/transactions");
        //       resetForm();
        //     }
        //   } else {
        //     toast.error(t("Total debit and credit amounts must be equal"));
        //   }
        // }}
      >
        {({ values, handleSubmit, resetForm }) => (
          <FieldArray name='entry_details'>
            {({ push, remove }) => (
              <form onSubmit={handleSubmit}>
                {console.log(values)}
                <div className='rounded-md bg-gray-100 p-6 w-full gap-4'>
                  <h2 className='text-3xl font-bold mb-6 text-gray-800'>
                    {/* {`${t("CreateJournalEntries")}`} */}
                    {isEditing ? t('EditJournalEntry') : t('CreateJournalEntries')}
                  </h2>
                  <div className='grid grid-cols-1 md:grid-cols-7 gap-2 w-full'>
                    <div className='col-span-3'>
                      <SharedInputText
                        type={'datetime-local'}
                        label={t('date')}
                        name={'date'}
                        placeholder='Enter Date'
                        className='!p-1.5'
                      />
                    </div>
                    <div className='col-span-3'>
                      <SharedInputText
                        label={t('description')}
                        name={'description'}
                        placeholder={`${t('enter')} ${t('description')}`}
                        className='!p-1.5'
                      />
                    </div>
                  </div>

                  {values?.entry_details?.map((_, index) => (
                    <div key={index} className='grid grid-cols-2 md:grid-cols-7 gap-2 w-full mt-4 border-t py-2'>
                      <CustomSearchInput
                        label={index === 0 ? t('account') : null}
                        className='!w-full'
                        name={`entry_details.${index}.account_id`}
                        options={listAccountsvalues}
                        placeholder={`${t('select')} ${t('account')}`}
                      />
                      <SharedInputText
                        type='number'
                        label={index === 0 ? t('debitAmount') : null}
                        name={`entry_details.${index}.debit_amount`}
                        placeholder={`${t('enter')} ${t('debitAmount')}`}
                        className='!p-1.5'
                        min={0}
                      />
                      <SharedInputText
                        type='number'
                        label={index === 0 ? t('creditAmount') : null}
                        name={`entry_details.${index}.credit_amount`}
                        placeholder={`${t('enter')} ${t('creditAmount')}`}
                        className='!p-1.5'
                        min={0}
                      />

                      <SharedInputText
                        label={index === 0 ? t('comment') : null}
                        name={`entry_details.${index}.comment`}
                        placeholder={`${t('enter')} ${t('comment')}`}
                        className='!p-1.5'
                      />

                      <CustomSearchInput
                        label={index === 0 ? t('branch') : null}
                        className='!w-full'
                        name={`entry_details.${index}.branch_id`}
                        options={listBranchvalues}
                        placeholder={`${t('select')} ${t('branch')}`}
                      />

                      <CustomSearchInput
                        label={index === 0 ? t('costCenter') : null}
                        className='!w-full'
                        name={`entry_details.${index}.cost_center_id`}
                        options={listCostCentervalues}
                        placeholder={`${t('select')} ${t('costCenter')}`}
                        onKeyDown={e => {
                          if (e.key === 'Tab' && !e.shiftKey && index === values.entry_details.length - 1) {
                            e.preventDefault();
                            push({
                              branch_id: '',
                              cost_center_id: '',
                              account_id: '',
                              debit_amount: '',
                              credit_amount: '',
                              comment: ''
                            });
                          }
                        }}
                      />

                      {index > 0 && (
                        <div className='flex justify-end items-end'>
                          <span className='h-[30px] w-full flex justify-end pr-5'>
                            <RiDeleteBin5Line
                              size={20}
                              className='cursor-pointer text-red-500'
                              onClick={() => remove(index)}
                            />
                          </span>
                        </div>
                      )}
                    </div>
                  ))}

                  <SharedButton
                    icon='pi pi-plus'
                    label={t('ADDENTRY')}
                    className='submit-btn mt-4'
                    onClick={() =>
                      push({
                        branch_id: '',
                        costcenter_id: '',
                        account_id: '',
                        debit_amount: '',
                        credit_amount: '',
                        comment: ''
                      })
                    }
                    type='button'
                  />

                  <div className='flex justify-end gap-6 mt-6'>
                    <SharedButton
                      icon='pi pi-times'
                      label={t('Cancel')}
                      className='cancel-btn'
                      onClick={() => {
                        navigate('/manager/transactions');
                        resetForm();
                      }}
                      type='button'
                    />
                    <SharedButton
                      icon='pi pi-check'
                      label={isEditing ? t('Update') : t('Submit')}
                      // label={t("Submit")}
                      className='submit-btn'
                      type='submit'
                    />
                  </div>
                </div>
              </form>
            )}
          </FieldArray>
        )}
      </Formik>
      <Loader showLoader={isLoading} />
    </>
  );
}

//without Edit
// import { Dialog } from "primereact/dialog";
// import React, { useEffect, useState } from "react";
// import { Formik, FieldArray, Field } from "formik";
// import * as yup from "yup";
// import SharedInputText from "../../components/Shared/SharedInputText";
// import SharedButton from "../../components/Shared/SharedButton";
// import { useDispatch, useSelector } from "react-redux";
// import SharedDropdown from "../../components/Shared/SharedDropdown";
// import {
//   createTransaction,
//   getTransactions,
// } from "../../store/slices/TransactionsSlice";
// import { getListAccounts } from "../../store/slices/AcountsSlice";
// import { RiDeleteBin5Line } from "react-icons/ri";
// import Loader from "../../Loader";
// import CustomSearchDropdown from "../../components/Shared/CustomSearchDropdown";
// import { useTranslation } from "react-i18next";
// import i18next from "i18next";
// import { getBranches } from "../../store/slices/BusinessSlice";
// import { getCostCenter } from "../../store/slices/CostCenterSlice";
// import CustomSearchInput from "../../components/Shared/CustomSearchInput";
// import { getCurrentDateTime } from "./CreateTransaction";
// import { useNavigate } from "react-router-dom";
// import toast from "react-hot-toast";

// export default function CreateTransactionPage() {
//   const dispatch = useDispatch();
//   const { user } = useSelector((state) => state.auth);
//   const { listAccounts } = useSelector((state) => state.acount);
//   const { isLoading } = useSelector((state) => state.transaction);
//   const { costCenters } = useSelector((state) => state.costCenter);
//   const { branches } = useSelector((state) => state.business);
//   const lang = i18next.language;
//   const { t } = useTranslation();
//   const navigate = useNavigate();

//   useEffect(() => {
//     dispatch(getListAccounts());
//     dispatch(getBranches());
//     dispatch(getCostCenter());
//   }, []);

//   const listAccountsvalues = listAccounts?.data
//     ? Object.entries(listAccounts.data).map(([id, account]) => ({
//         value: Number(account?.id),
//         label: lang === "ar" ? account.name_ar : account.name_en,
//       }))
//     : [];

//   const listBranchvalues = branches?.data
//     ? Object.entries(branches?.data).map(([id, branch]) => ({
//         value: Number(branch?.id),
//         label: lang === "ar" ? branch.name_ar : branch.name_en,
//       }))
//     : [];

//   const listCostCentervalues = costCenters?.data
//     ? Object.entries(costCenters?.data).map(([id, costCenter]) => ({
//         value: Number(costCenter?.id),
//         label: lang === "ar" ? costCenter.name_ar : costCenter.name_en,
//       }))
//     : [];

//   const initialValues = {
//     date: getCurrentDateTime(),
//     description: "",
//     entry_details: [
//       {
//         branch_id: "",
//         costcenter_id: "",
//         account_id: "",
//         debit_amount: "",
//         credit_amount: "",
//         comment: "",
//       },
//     ],
//   };

//   const validationSchema = yup.object().shape({
//     date: yup.string().required(t("required")),
//     entry_details: yup.array().of(
//       yup.object().shape({
//         costcenter_id: yup.number().required(t("required")),
//         branch_id: yup.number().required(t("required")),
//         account_id: yup.number().required(t("required")),
//         debit_amount: yup
//           .number()
//           .min(0, `${t("debitAmount")}${t("mustBePositive")}`)
//           .required(t("required")),
//         credit_amount: yup
//           .number()
//           .min(0, `${t("creditAmount")}${t("mustBePositive")}`)
//           .required(t("required")),
//         comment: yup.string(),
//       })
//     ),
//   });

//   const validateTotals = (values) => {
//     const totalDebit = values.entry_details.reduce(
//       (sum, entry) => sum + Number(entry.debit_amount),
//       0
//     );
//     const totalCredit = values.entry_details.reduce(
//       (sum, entry) => sum + Number(entry.credit_amount),
//       0
//     );
//     return totalDebit === totalCredit;
//   };

//   return (
//     <>
//       <Formik
//         initialValues={initialValues}
//         validationSchema={validationSchema}
//         // onSubmit={async (data, { resetForm }) => {
//         //   console.log("Submitting form with data:", data);
//         //   const resultAction = await dispatch(createTransaction(data));
//         //   if (createTransaction.fulfilled.match(resultAction)) {
//         //     resetForm();
//         //     navigate("/manager/transactions");
//         //   }
//         // }}
//         onSubmit={async (data, { resetForm }) => {
//           console.log("Submitting form with data:", data);
//           if (validateTotals(data)) {
//             const resultAction = await dispatch(createTransaction(data));
//             if (createTransaction.fulfilled.match(resultAction)) {
//               navigate("/manager/transactions");
//               resetForm();
//             }
//           } else {
//             toast.error(t("Total debit and credit amounts must be equal"));
//           }
//         }}
//       >
//         {({ values, handleSubmit, resetForm }) => (
//           <FieldArray name="entry_details">
//             {({ push, remove }) => (
//               <form onSubmit={handleSubmit}>
//                 <div className="rounded-md bg-gray-100 p-6 w-full gap-4">
//                   <h2 className="text-3xl font-bold mb-6 text-gray-800">
//                     {`${t("CreateJournalEntries")}`}
//                   </h2>

//                   <div className="grid grid-cols-1 md:grid-cols-7 gap-2 w-full">
//                     <div className="col-span-3">
//                       <SharedInputText
//                         type={"datetime-local"}
//                         label={t("date")}
//                         name={"date"}
//                         placeholder="Enter Date"
//                         className="!p-1.5"
//                       />
//                     </div>
//                     <div className="col-span-3">
//                       <SharedInputText
//                         label={t("description")}
//                         name={"description"}
//                         placeholder={`${t("enter")} ${t("description")}`}
//                         className="!p-1.5"
//                       />
//                     </div>
//                   </div>

//                   {values.entry_details.map((_, index) => (
//                     <div
//                       key={index}
//                       className="grid grid-cols-2 md:grid-cols-7 gap-2 w-full mt-4 border-t py-2"
//                     >
//                       <CustomSearchInput
//                         label={t("branch")}
//                         className="!w-full"
//                         name={`entry_details.${index}.branch_id`}
//                         options={listBranchvalues}
//                         placeholder={`${t("select")} ${t("branch")}`}
//                       />
//                       <CustomSearchInput
//                         label={`${t("costCenter")}`}
//                         className="!w-full"
//                         name={`entry_details.${index}.costcenter_id`}
//                         options={listCostCentervalues}
//                         placeholder={`${t("select")} ${t("costCenter")}`}
//                       />
//                       <CustomSearchInput
//                         label={t("account")}
//                         className="!w-full"
//                         name={`entry_details.${index}.account_id`}
//                         options={listAccountsvalues}
//                         placeholder={`${t("select")} ${t("account")}`}
//                       />
//                       <SharedInputText
//                         type={"number"}
//                         label={t("debitAmount")}
//                         name={`entry_details.${index}.debit_amount`}
//                         placeholder={`${t("enter")} ${t("debitAmount")}`}
//                         className="!p-1.5"
//                         min={0}
//                       />
//                       <SharedInputText
//                         type={"number"}
//                         label={t("creditAmount")}
//                         name={`entry_details.${index}.credit_amount`}
//                         placeholder={`${t("enter")} ${t("creditAmount")}`}
//                         className="!p-1.5"
//                         min={0}
//                       />
//                       <Field name={`entry_details.${index}.comment`}>
//                         {({ field }) => (
//                           <SharedInputText
//                             label={t("comment")}
//                             {...field}
//                             placeholder={`${t("enter")} ${t("comment")}`}
//                             className="!p-1.5"
//                             onKeyDown={(e) => {
//                               if (
//                                 e.key === "Tab" &&
//                                 !e.shiftKey &&
//                                 index === values.entry_details.length - 1
//                               ) {
//                                 e.preventDefault();
//                                 push({
//                                   branch_id: "",
//                                   costcenter_id: "",
//                                   account_id: "",
//                                   debit_amount: "",
//                                   credit_amount: "",
//                                   comment: "",
//                                 });
//                               }
//                             }}
//                           />
//                         )}
//                       </Field>

//                       {index > 0 && (
//                         <div className="flex justify-end items-end">
//                           <span className="h-[30px] w-full flex justify-end pr-5">
//                             <RiDeleteBin5Line
//                               size={20}
//                               className="cursor-pointer text-red-500"
//                               onClick={() => remove(index)}
//                             />
//                           </span>
//                         </div>
//                       )}
//                     </div>
//                   ))}
//                   <SharedButton
//                     icon="pi pi-plus"
//                     label={t("ADDENTRY")}
//                     className="submit-btn mt-4"
//                     onClick={() =>
//                       push({
//                         branch_id: "",
//                         costcenter_id: "",
//                         account_id: "",
//                         debit_amount: "",
//                         credit_amount: "",
//                         comment: "",
//                       })
//                     }
//                     type="button"
//                   />

//                   <div className="flex justify-end gap-6 mt-6">
//                     <SharedButton
//                       icon="pi pi-times"
//                       label={t("Cancel")}
//                       className="!cancel-btn"
//                       onClick={() => {
//                         navigate("/manager/transactions");
//                         resetForm();
//                       }}
//                       type="button"
//                     />
//                     <SharedButton
//                       icon="pi pi-check"
//                       label={t("Submit")}
//                       className="submit-btn"
//                       type="submit"
//                     />
//                   </div>
//                 </div>
//               </form>
//             )}
//           </FieldArray>
//         )}
//       </Formik>
//       <Loader showLoader={isLoading} />
//     </>
//   );
// }

// import { Dialog } from "primereact/dialog";
// import React, { useEffect, useState } from "react";
// import { Formik, FieldArray } from "formik";
// import * as yup from "yup";
// import SharedInputText from "../../components/Shared/SharedInputText";
// import SharedButton from "../../components/Shared/SharedButton";
// import { useDispatch, useSelector } from "react-redux";
// import SharedDropdown from "../../components/Shared/SharedDropdown";
// import {
//   createTransaction,
//   getTransactions,
// } from "../../store/slices/TransactionsSlice";
// import { getListAccounts } from "../../store/slices/AcountsSlice";
// import { RiDeleteBin5Line } from "react-icons/ri";
// import Loader from "../../Loader";
// import CustomSearchDropdown from "../../components/Shared/CustomSearchDropdown";
// import { useTranslation } from "react-i18next";
// import i18next from "i18next";
// import { getBranches } from "../../store/slices/BusinessSlice";
// import { getCostCenter } from "../../store/slices/CostCenterSlice";
// import CustomSearchInput from "../../components/Shared/CustomSearchInput";
// import { getCurrentDateTime } from "./CreateTransaction";
// import { useNavigate } from "react-router-dom";

// export default function CreateTransactionPage() {
//   const dispatch = useDispatch();
//   const { user } = useSelector((state) => state.auth);
//   const { listAccounts } = useSelector((state) => state.acount);
//   const { isLoading } = useSelector((state) => state.transaction);
//   const { costCenters } = useSelector((state) => state.costCenter);
//   const { branches } = useSelector((state) => state.business);
//   const lang = i18next.language;
//   const { t } = useTranslation();
//   const navigate = useNavigate();

//   useEffect(() => {
//     dispatch(getListAccounts());
//     dispatch(getBranches());
//     dispatch(getCostCenter());
//   }, []);

//   const listAccountsvalues = listAccounts?.data
//     ? Object.entries(listAccounts.data).map(([id, account]) => ({
//         value: Number(account?.id),
//         label: lang === "ar" ? account.name_ar : account.name_en,
//       }))
//     : [];

//   const listBranchvalues = branches?.data
//     ? Object.entries(branches?.data).map(([id, branch]) => ({
//         value: Number(branch?.id),
//         label: lang === "ar" ? branch.name_ar : branch.name_en,
//       }))
//     : [];

//   const listCostCentervalues = costCenters?.data
//     ? Object.entries(costCenters?.data).map(([id, costCenter]) => ({
//         value: Number(costCenter?.id),
//         label: lang === "ar" ? costCenter.name_ar : costCenter.name_en,
//       }))
//     : [];

//   const initialValues = {
//     date: getCurrentDateTime(),
//     description: "",
//     entry_details: [
//       {
//         branch_id: "",
//         costcenter_id: "",
//         account_id: "",
//         debit_amount: "",
//         credit_amount: "",
//         comment: "",
//       },
//     ],
//   };

//   const validationSchema = yup.object().shape({
//     date: yup.string().required(t("required")),
//     // description: yup.string().required(t("required")),
//     entry_details: yup.array().of(
//       yup.object().shape({
//         costcenter_id: yup.number().required(t("required")),
//         branch_id: yup.number().required(t("required")),
//         account_id: yup.number().required(t("required")),
//         debit_amount: yup
//           .number()
//           .min(0, `${t("debitAmount")}${t("mustBePositive")}`)
//           .required(t("required")),
//         credit_amount: yup
//           .number()
//           .min(0, `${t("creditAmount")}${t("mustBePositive")}`)
//           .required(t("required")),
//         comment: yup.string(),
//       })
//     ),
//   });

//   return (
//     <>
//       <Formik
//         initialValues={initialValues}
//         validationSchema={validationSchema}
//         onSubmit={async (data, { resetForm }) => {
//           console.log("Submitting form with data:", data);
//           const resultAction = await dispatch(createTransaction(data));
//           if (createTransaction.fulfilled.match(resultAction)) {
//             // setCreateDialogVisible(false);
//             resetForm();
//             navigate("/manager/transactions");
//             // dispatch(getTransactions({ page: 1, per_page: 10 }));
//           }
//         }}
//       >
//         {({ values, handleSubmit, resetForm }) => (
//           <>
//             <div className="rounded-md bg-gray-100 p-6 w-full gap-4">
//               <h2 className="text-3xl font-bold mb-6 text-gray-800">
//                 {`${t("CreateJournalEntries")}`}
//                 {/* {console.log(props?.values)} */}
//               </h2>

//               {/* <div className="grid grid-cols-7 w-full">
//                 <div className="flex">
//                   <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
//                     <SharedInputText
//                       type={"datetime-local"}
//                       label={t("date")}
//                       name={"date"}
//                       placeholder="Enter Date"
//                       className="!p-1.5"
//                     />
//                     <SharedInputText
//                       label={t("description")}
//                       name={"description"}
//                       placeholder={`${t("enter")} ${t("description")}`}
//                       className="!p-1.5"
//                     />
//                   </div>
//                 </div>
//               </div> */}

//               <div className="grid grid-cols-1 md:grid-cols-7 gap-2 w-full">
//                 <div className="col-span-3">
//                   <SharedInputText
//                     type={"datetime-local"}
//                     label={t("date")}
//                     name={"date"}
//                     placeholder="Enter Date"
//                     className="!p-1.5"
//                   />
//                 </div>
//                 <div className="col-span-3">
//                   <SharedInputText
//                     label={t("description")}
//                     name={"description"}
//                     placeholder={`${t("enter")} ${t("description")}`}
//                     className="!p-1.5"
//                   />
//                 </div>
//               </div>

//               <FieldArray name="entry_details">
//                 {({ push, remove }) => (
//                   <>
//                     {values.entry_details.map((_, index) => (
//                       <div
//                         key={index}
//                         className="grid grid-cols-2 md:grid-cols-7 gap-2 w-full mt-4 border-t py-2"
//                       >
//                         {/* <div>
//                           <CustomSearchDropdown
//                             label={`${t("branch")}`}
//                             className="!w-full"
//                             name={`entry_details.${index}.branch_id`}
//                             options={listBranchvalues}
//                             placeholder={`${t("select")} ${t("branch")}`}
//                           />
//                           <div>

//                           </div>
//                         </div> */}
//                         <CustomSearchInput
//                           label={t("branch")}
//                           className="!w-full"
//                           name={`entry_details.${index}.branch_id`}
//                           options={listBranchvalues}
//                           placeholder={`${t("select")} ${t("branch")}`}
//                         />

//                         <CustomSearchInput
//                           label={`${t("costCenter")}`}
//                           className="!w-full"
//                           name={`entry_details.${index}.costcenter_id`}
//                           options={listCostCentervalues}
//                           placeholder={`${t("select")} ${t("costCenter")}`}
//                         />
//                         <CustomSearchInput
//                           label={t("account")}
//                           className="!w-full"
//                           name={`entry_details.${index}.account_id`}
//                           options={listAccountsvalues}
//                           placeholder={`${t("select")} ${t("account")}`}
//                         />
//                         <SharedInputText
//                           type={"number"}
//                           label={t("debitAmount")}
//                           name={`entry_details.${index}.debit_amount`}
//                           placeholder={`${t("enter")} ${t("debitAmount")}`}
//                           className="!p-1.5"
//                           min={0}
//                         />

//                         <SharedInputText
//                           type={"number"}
//                           label={t("creditAmount")}
//                           name={`entry_details.${index}.credit_amount`}
//                           placeholder={`${t("enter")} ${t("creditAmount")}`}
//                           className="!p-1.5"
//                           min={0}
//                         />
//                         <SharedInputText
//                           label={t("comment")}
//                           name={`entry_details.${index}.comment`}
//                           placeholder={`${t("enter")} ${t("comment")}`}
//                           className="!p-1.5"
//                         />

//                         {index > 0 && (
//                           <>
//                             <div className="flex justify-end items-end">
//                               <span className="h-[30px] w-full flex justify-end pr-5">
//                                 <RiDeleteBin5Line
//                                   size={20}
//                                   className="cursor-pointer text-red-500"
//                                   onClick={() => remove(index)}
//                                 />
//                               </span>
//                             </div>
//                           </>
//                         )}
//                       </div>
//                     ))}
//                     <SharedButton
//                       icon="pi pi-plus"
//                       label={t("ADDENTRY")}
//                       className="submit-btn mt-4"
//                       onClick={() =>
//                         push({
//                           branch_id: "",
//                           costcenter_id: "",
//                           account_id: "",
//                           debit_amount: "",
//                           credit_amount: "",
//                           comment: "",
//                         })
//                       }
//                     />
//                   </>
//                 )}
//               </FieldArray>

//               <div className="flex justify-end gap-6 mt-6">
//                 <SharedButton
//                   icon="pi pi-times"
//                   label={t("Cancel")}
//                   className="!cancel-btn"
//                   onClick={() => {
//                     // setCreateDialogVisible(false);
//                     navigate("/manager/transactions");
//                     resetForm();
//                   }}
//                 />
//                 <SharedButton
//                   icon="pi pi-check"
//                   label={t("Submit")}
//                   className="submit-btn"
//                   onClick={handleSubmit}
//                 />
//               </div>
//             </div>

//             {/* {console.log("Form errors:", errors)}
//                 {console.log("Form touched:", touched)} */}
//           </>
//         )}
//       </Formik>
//       <Loader showLoader={isLoading} />
//     </>
//   );
// }
