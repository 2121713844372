import React from 'react';
import { useTranslation } from 'react-i18next';

const FilterDetails = ({ filtersDetails, loading }) => {
  const { t } = useTranslation();
  return filtersDetails && filtersDetails?.data?.length && !loading ? (
    <div className='w-full md:w-[400px]'>
      <div className=' bg-white rounded px-4 py-2 mt-4 border'>
        <div className='text-lg flex justify-between py-2 border-b'>
          <span className='font-semibold'>{t('total_amount_with_tax')}:</span>{' '}
          <span>
            {filtersDetails?.total_amount?.toFixed(2) || '0'} {`${t('SAR')}`}
          </span>
        </div>
        <div className='text-lg flex justify-between py-2 border-b'>
          <span className='font-semibold'>{t('total_amount_without_tax')}:</span>{' '}
          <span>
            {filtersDetails?.total_amount_no_tax?.toFixed(2) || '0'} {`${t('SAR')}`}
          </span>
        </div>
        <div className='text-lg flex justify-between py-2 border-b'>
          <span className='font-semibold'>{t('total_tax')}:</span>{' '}
          <span>
            {filtersDetails?.total_tax_amount?.toFixed(2) || '0'} {`${t('SAR')}`}
          </span>
        </div>
        <div className='text-lg flex justify-between py-2 border-b'>
          <span className='font-semibold'>{t('total_pages')}:</span> <span>{filtersDetails?.total_pages}</span>
        </div>
        <div className='text-lg flex justify-between py-2'>
          <span className='font-semibold'>{t('total_records')}:</span> <span>{filtersDetails?.total_record}</span>
        </div>
      </div>
    </div>
  ) : (
    ''
  );
};

export default FilterDetails;
