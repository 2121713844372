import React from 'react';
import SharedButton from '../../components/Shared/SharedButton';
import { Dialog } from 'primereact/dialog';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import SharedDropdown from '../../components/Shared/SharedDropdown';
import SharedInputText from '../../components/Shared/SharedInputText';
import { CreateCustomers, getCustomersList, editCustomer } from '../../store/slices/CustomerSlice';
import nationalitiesData from '../../uitls/NationalitiesData.json';

export default function CreateCustomer({ createDialogVisible, setCreateDialogVisible, editData = null }) {
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.customers);
  const { t } = useTranslation();

  const initialValues = {
    name: editData?.name || '',
    primary_contact_number: editData?.primary_contact_number || '',
    primary_email_id: editData?.primary_email_id || '',
    status: editData?.status || '',
    address: editData?.address || '',
    document_id: editData?.document_id || '',
    nationality: editData?.nationality || '',
    branch_id: editData?.branch_id || user?.branch_id,
    business_id: editData?.business_id || user?.business_id
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t('customer_name_is_required')),
    primary_contact_number: Yup.string().required(t('primary_contact_number_req')),
    //  primary_email_id: Yup.string().email(t('invalid_email_format')).required(t('primary_email_id_req')),
    status: Yup.string().required(t('status_is_required')),
    address: Yup.string()
      .required(t('address_is_required'))
      .matches(/^(?!\d+$).+$/, t('invalid_address_format')),
    document_id: Yup.string().required(t('document_id_is_required')),
    nationality: Yup.string().required(t('nationality_is_required'))
  });

  const status_option = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'In-Active' }
  ];
  const nationality_option = nationalitiesData?.countries;

  return (
    <>
      <Dialog
        header={t('CreateCustomer')}
        visible={createDialogVisible}
        onHide={() => {
          if (!createDialogVisible) return;
          setCreateDialogVisible(false);
        }}
        pt={{
          root: { className: 'w-full lg:w-10/12 mx-4 overflow-auto' },
          header: { className: '!p-2 !bg-darkBg !text-white' },
          content: { className: '!p-3' },
          closeButton: {
            className: 'hover:!bg-black/20 !outline-none !shadow-none'
          },
          closeButtonIcon: { className: '!text-white' }
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (data, { resetForm }) => {
            try {
              if (editData) {
                const resultAction = await dispatch(editCustomer({ data: data, id: editData?.id }));
                if (editCustomer.fulfilled.match(resultAction)) {
                  resetForm();
                  setCreateDialogVisible(false);
                  dispatch(getCustomersList({ page: 1, per_page: 10 }));
                }
              } else {
                const resultAction = await dispatch(CreateCustomers(data));
                if (CreateCustomers.fulfilled.match(resultAction)) {
                  resetForm();
                  setCreateDialogVisible(false);
                  dispatch(getCustomersList({ page: 1, per_page: 10 }));
                }
              }
            } catch (error) {
              console.error('Error creating customer:', error);
            }
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className='grid grid-cols-1 md:grid-cols-4 gap-4 w-full'>
                <SharedInputText
                  label={t('name')}
                  name={'name'}
                  placeholder={t('name_placeholder')}
                  className='!p-1.5'
                  error={touched.name && errors.name}
                />

                <SharedInputText
                  type={'tel'}
                  label={t('primary_contact_number')}
                  name={'primary_contact_number'}
                  placeholder={t('primary_contact_number_placeholder')}
                  className='!p-1.5'
                  error={touched.primary_contact_number && errors.primary_contact_number}
                />

                <SharedInputText
                  type={'email'}
                  label={t('primary_email_id')}
                  name={'primary_email_id'}
                  placeholder={t('primary_email_id_placeholder')}
                  className='!p-1.5'
                  error={touched.primary_email_id && errors.primary_email_id}
                />

                <SharedInputText
                  label={t('address')}
                  placeholder={`${t('enter')} ${t('address')}`}
                  name={'address'}
                  className='!p-1.5'
                  error={touched.address && errors.address}
                />

                <SharedInputText
                  label={t('document_id')}
                  name={'document_id'}
                  placeholder={`${t('enter')} ${t('document_id')}`}
                  className='!p-1.5'
                  error={touched.document_id && errors.document_id}
                />

                <SharedDropdown
                  label={t('status')}
                  name={'status'}
                  options={status_option}
                  error={touched.status && errors.status}
                />

                <SharedDropdown
                  label={t('nationality')}
                  name={'nationality'}
                  options={nationality_option}
                  filter
                  error={touched.nationality && errors.nationality}
                />
              </div>
              <div className='flex justify-end gap-6 mt-6'>
                <SharedButton
                  type='button'
                  icon='pi pi-times'
                  label={t('Cancel')}
                  className='cancel-btn'
                  onClick={() => {
                    setCreateDialogVisible(false);
                  }}
                />
                <SharedButton
                  type='submit'
                  icon='pi pi-check'
                  disabled={isLoading}
                  label={editData ? t('Update') : t('Submit')}
                  className='submit-btn'
                />
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
