import React, { useState } from 'react';
import { MdOutlineDelete, MdOutlineEdit } from 'react-icons/md';
import { formatTimestamp } from '../../components/Shared/SharedMethods';
import SharedTooltip from '../../components/Shared/SharedTooltip';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { deleteItem, getItemsList } from '../../store/slices/ItemSlice';
import CustomPagination from '../../components/Shared/CustomPagination';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import CreateItem from './CreateItem';
import ConfirmationDialog from '../../components/Shared/ConfirmationDialog';
import TableLoading from '../../components/Shared/TableLoading';
import CustomTable from '../../components/Shared/CustomTable';

export default function ItemsTable({ data }) {
  const datebody = rowData => {
    return formatTimestamp(rowData.created_at);
  };

  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState(null);
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  const { isLoading } = useSelector(state => state.item);
  const { t } = useTranslation();
  const lang = i18next.language;

  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(getItemsList({ page: newPage, per_page: newRowsPerPage }));
  };

  const templateBody = rowData => (
    <div className='flex gap-1'>
      <SharedTooltip id='my-tooltip-2' content='edit' />
      <SharedTooltip id='my-tooltip-3' content='delete' />
      <MdOutlineEdit
        size={25}
        data-tooltip-id='my-tooltip-2'
        className='table-icon'
        onClick={() => {
          setCreateDialogVisible(true);
          setEditData(rowData);
        }}
      />
      <MdOutlineDelete
        size={25}
        data-tooltip-id='my-tooltip-3'
        className='table-icon'
        onClick={() => {
          setConfirmationDialogVisible(true);
          setSelectedData(rowData);
        }}
      />
    </div>
  );

  const sequenceBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1 + (data?.page - 1) * data?.per_page;
  };

  const balanceBody = data => {
    return (
      <>
        {data?.price} {t('SAR')}
      </>
    );
  };

  // Handle Confirm delete items
  const confirmDeleteHandler = () => {
    if (selectedData) {
      dispatch(
        deleteItem({
          ID: selectedData?.ID
        })
      ).then(() => {
        dispatch(getItemsList({ page: 1, per_page: 10 }));
      });
      setConfirmationDialogVisible(false);
      setSelectedData(null);
    }
  };

  const columnsData = [
    {
      body: sequenceBodyTemplate,
      header: t('no'),
      style: { width: '3rem' }
    },
    {
      field: 'effective_date',
      header: t('effective_date')
    },
    {
      field: lang === 'ar' ? 'name_ar' : 'name_en',
      header: t('name')
    },
    {
      field: 'item_code',
      header: t('itemCode')
    },
    {
      field: 'price',
      header: t('unitPrice'),
      body: balanceBody
    },
    {
      field: 'item_type',
      header: t('item_type')
    },
    {
      body: templateBody,
      header: t('actions')
    }
  ];

  return (
    <>
      <CustomTable
        data={data?.data}
        isLoading={isLoading}
        loadingIcon={<TableLoading />}
        columns={columnsData}
        rows={data?.per_page}
      />
      <CreateItem
        createDialogVisible={createDialogVisible}
        setCreateDialogVisible={setCreateDialogVisible}
        isLoading={isLoading}
        editData={editData}
      />
      <ConfirmationDialog
        title={t('delete_confirmation_popup_text')}
        isDialogVisible={confirmationDialogVisible}
        setIsDialogVisible={setConfirmationDialogVisible}
        confirmHandler={confirmDeleteHandler}
      />
      {data?.data?.length > 0 && (
        <CustomPagination
          currentPage={data?.page}
          rowsPerPage={data?.per_page}
          totalRecords={data?.total_pages}
          totalRecord={data?.total_record}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
}
