import React, { useEffect } from 'react';
import SharedButton from '../../components/Shared/SharedButton';
import { Dialog } from 'primereact/dialog';
import { Formik, Form } from 'formik';
import SharedDropdown from '../../components/Shared/SharedDropdown';
import SharedInputText from '../../components/Shared/SharedInputText';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import CustomSearchDropdown from '../../components/Shared/CustomSearchDropdown';
import { getAllEmployees } from '../../store/slices/EmployeeSlice';
import { CreateNewAllowance, getAllowanceList } from '../../store/slices/HrSlice';

export default function CreateAllowance({ createDialogVisible, setCreateDialogVisible }) {
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.employee);
  const { allEmployees } = useSelector(state => state.employee);
  const { t } = useTranslation();

  const listEmployees = allEmployees?.data
    ? allEmployees.data.map(employee => ({
        id: employee.id,
        name: `${employee.first_name} ${employee.last_name}`
      }))
    : [];

  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  const initialValues = {
    employee_id: '',
    allowance_type: '',
    amount: 0,
    effective_date: '',

    branch_id: user?.branch_id,
    business_id: user?.business_id
  };

  const validationSchema = Yup.object({
    employee_id: Yup.number().required(t('required')),
    allowance_type: Yup.string().required(t('required')),
    amount: Yup.number().min(0, `${t('amount')}${t('mustBePositive')}`),
    effective_date: Yup.string().required(t('required'))
  });

  const allowance_type_option = [
    { value: 'Housing', label: 'Housing' },
    { value: 'Travel', label: 'Travel' },
    { value: 'Bonus', label: 'Bonus' },
    { value: 'Food', label: 'Food' }
  ];

  return (
    <>
      <Dialog
        header={t('CreateAllowance')}
        visible={createDialogVisible}
        onHide={() => {
          if (!createDialogVisible) return;
          setCreateDialogVisible(false);
        }}
        pt={{
          root: { className: 'w-full lg:w-10/12 mx-4 overflow-auto' },
          header: { className: '!p-2 !bg-darkBg !text-white' },
          content: { className: '!p-3' },
          closeButton: {
            className: 'hover:!bg-black/20 !outline-none !shadow-none'
          },
          closeButtonIcon: { className: '!text-white' }
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (data, { resetForm }) => {
            try {
              const resultAction = await dispatch(CreateNewAllowance(data));
              if (CreateNewAllowance.fulfilled.match(resultAction)) {
                resetForm();
                setCreateDialogVisible(false);
                dispatch(getAllowanceList({ page: 1, per_page: 10 }));
              }
            } catch (error) {
              console.error('Error creating allowance:', error);
            }
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className='grid grid-cols-1 md:grid-cols-4 gap-4 w-full'>
                <CustomSearchDropdown
                  name='employee_id'
                  placeholder={`${t('select')} ${t('employee')}`}
                  options={listEmployees}
                  label={`${t('employee')} ${t('name')}`}
                  className={'!w-full'}
                />

                <SharedDropdown
                  label={t('allowance_type')}
                  placeholder={`${t('select')} ${t('allowance_type')}`}
                  name={'allowance_type'}
                  options={allowance_type_option}
                  error={touched.allowance_type && errors.allowance_type}
                />

                <SharedInputText
                  type={'date'}
                  label={t('effective_date')}
                  name={'effective_date'}
                  placeholder='Enter effective_date'
                  className='!p-1.5'
                />

                <SharedInputText
                  type={'number'}
                  label={t('amount')}
                  name={'amount'}
                  placeholder={`${t('enter')} ${t('amount')}`}
                  className='!p-1.5'
                />
              </div>
              <div className='flex justify-end gap-6 mt-6'>
                <SharedButton
                  type='button'
                  icon='pi pi-times'
                  label={t('Cancel')}
                  className='cancel-btn'
                  onClick={() => {
                    setCreateDialogVisible(false);
                  }}
                />
                <SharedButton type='submit' icon='pi pi-check' label={t('Submit')} className='submit-btn' />
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
