import React, { useEffect } from 'react';
import SharedButton from '../../components/Shared/SharedButton';
import { Dialog } from 'primereact/dialog';
import { Formik, Form } from 'formik';
import SharedDropdown from '../../components/Shared/SharedDropdown';
import SharedInputText from '../../components/Shared/SharedInputText';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import CustomSearchDropdown from '../../components/Shared/CustomSearchDropdown';
import { getAllEmployees } from '../../store/slices/EmployeeSlice';
import { CreateNewLeave, getLeavesList } from '../../store/slices/HrSlice';

export default function CreateLeave({ createDialogVisible, setCreateDialogVisible }) {
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.employee);
  const { allEmployees } = useSelector(state => state.employee);
  const { t } = useTranslation();

  const listEmployees = allEmployees?.data
    ? allEmployees.data.map(employee => ({
        id: employee.id,
        name: `${employee.first_name} ${employee.last_name}`
      }))
    : [];

  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  const initialValues = {
    employee_id: '',
    leave_type: '',
    start_date: '',
    end_date: '',
    status: '',

    branch_id: user?.branch_id,
    business_id: user?.business_id
  };

  const validationSchema = Yup.object({
    employee_id: Yup.number().required(t('required')),
    leave_type: Yup.string().required(t('required')),
    start_date: Yup.string().required(t('required')),
    end_date: Yup.string().required(t('required')),
    status: Yup.string().required(t('required'))
  });

  const leave_type_option = [
    { value: 'Day', label: 'Day' },
    { value: 'Week', label: 'Week' },
    { value: 'Month', label: 'Month' },
    { value: 'Annual', label: 'Annual' }
  ];

  const status_option = [
    { value: 'Pending', label: 'Pending' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Rejected', label: 'Rejected' }
  ];

  return (
    <>
      <Dialog
        header={t('CreateLeave')}
        visible={createDialogVisible}
        onHide={() => {
          if (!createDialogVisible) return;
          setCreateDialogVisible(false);
        }}
        pt={{
          root: { className: 'w-full lg:w-10/12 mx-4 overflow-auto' },
          header: { className: '!p-2 !bg-darkBg !text-white' },
          content: { className: '!p-3' },
          closeButton: {
            className: 'hover:!bg-black/20 !outline-none !shadow-none'
          },
          closeButtonIcon: { className: '!text-white' }
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (data, { resetForm }) => {
            try {
              const resultAction = await dispatch(CreateNewLeave(data));
              if (CreateNewLeave.fulfilled.match(resultAction)) {
                resetForm();
                setCreateDialogVisible(false);
                dispatch(getLeavesList({ page: 1, per_page: 10 }));
              }
            } catch (error) {
              console.error('Error creating leave:', error);
            }
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className='grid grid-cols-1 md:grid-cols-4 gap-4 w-full'>
                <CustomSearchDropdown
                  name='employee_id'
                  placeholder={`${t('select')} ${t('employee')}`}
                  options={listEmployees}
                  label={`${t('employee')} ${t('name')}`}
                  className={'!w-full'}
                />

                <SharedDropdown
                  label={t('leave_type')}
                  name={'leave_type'}
                  options={leave_type_option}
                  error={touched.leave_type && errors.leave_type}
                />

                <SharedInputText
                  type={'date'}
                  label={t('start_date')}
                  name={'start_date'}
                  placeholder='Enter start_date'
                  className='!p-1.5'
                />

                <SharedInputText
                  type={'date'}
                  label={t('end_date')}
                  name={'end_date'}
                  placeholder='Enter end_date'
                  className='!p-1.5'
                />

                <SharedDropdown
                  label={t('status')}
                  name={'status'}
                  options={status_option}
                  error={touched.status && errors.status}
                />
              </div>
              <div className='flex justify-end gap-6 mt-6'>
                <SharedButton
                  type='button'
                  icon='pi pi-times'
                  label={t('Cancel')}
                  className='cancel-btn'
                  onClick={() => {
                    setCreateDialogVisible(false);
                  }}
                />
                <SharedButton type='submit' icon='pi pi-check' label={t('Submit')} className='submit-btn' />
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
