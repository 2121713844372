import React from 'react';
import { FieldArray } from 'formik';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';

import SharedButton from '../Shared/SharedButton';
import SharedDropdown from '../Shared/SharedDropdown';

const SettingsFormFieldsArray = props => {
  const { name, label, title, icon: Icon, lang, values, fieldsNames, setFieldValue, addNewItem } = props;
  const { t } = useTranslation();

  const renderIsVatTab = (fieldName, value, setFieldValue) => {
    return (
      <div className='flex flex-col gap-1 w-full md:ml-3'>
        <label className='text-base font-medium block'>{t('is_vat')}</label>
        <div className='flex'>
          <p
            className={`py-1.5 px-4 cursor-pointer transition-all
         ${lang == 'ar' ? 'rounded-tr-md rounded-br-md' : 'rounded-tl-md rounded-bl-md'}
          ${value ? 'is_vat_true' : 'is_vat_false'}`}
            onClick={() => setFieldValue(fieldName, true)}
          >
            {t('true')}
          </p>
          <p
            className={`py-1.5 px-4 cursor-pointer transition-all ${
              lang == 'ar' ? 'rounded-tl-md rounded-bl-md' : 'rounded-tr-md rounded-br-md'
            } ${!value ? 'is_vat_true' : 'is_vat_false'}`}
            onClick={() => setFieldValue(fieldName, false)}
          >
            {t('false')}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className='mb-3 rounded-lg border bg-white border-gray-300 px-6 pt-5 pb-1'>
      <div className='flex items-center mb-4'>
        <div className='flex-shrink-0 p-1.5 bg-gray-100 rounded-lg text-white mr-3'>
          <Icon size={20} color='#f5793b' />
        </div>
        <h3 className='text-xl font-semibold text-gray-700'>{title}</h3>
      </div>
      <FieldArray name={name}>
        {helpers => (
          <div className='max-h-[300px] overflow-auto scrollbar'>
            {values[name].map((_, index) => (
              <div key={index} className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 w-full mt-5 relative'>
                <SharedDropdown
                  label={label.selectAccount}
                  name={`${name}[${index}].${fieldsNames[0]}`}
                  placeholder={label.selectAccount}
                  options={[]}
                />
                <SharedDropdown
                  label={label.selectAccountType}
                  name={`${name}[${index}].${fieldsNames[1]}`}
                  placeholder={label.selectAccountType}
                  options={[]}
                />
                {renderIsVatTab(
                  `${name}[${index}].${fieldsNames[2]}`,
                  values[name][index][fieldsNames[2]],
                  setFieldValue
                )}
                {index > 0 && (
                  <div className={`flex items-center lg:absolute top-[30px] ${lang === 'ar' ? 'left-0' : 'right-0'}`}>
                    <RiDeleteBin5Line
                      size={20}
                      className='cursor-pointer text-red-500'
                      onClick={() => {
                        if (index > 0) {
                          helpers.remove(index);
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            ))}
            <div className='mt-4 flex items-center justify-end pb-2'>
              <SharedButton
                icon='pi pi-plus'
                label={label.addNew}
                className='submit-btn !capitalize'
                onClick={() => helpers.push(addNewItem())}
              />
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default SettingsFormFieldsArray;
