import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function VatReturnReportTable({ data }) {
  const { t } = useTranslation();
  const lang = i18next.language;

  return (
    <>
      <div className=''>
        <div className='w-full md:w-9/12 m-auto'>
          <div className=' bg-white rounded px-4 py-2 mb-4 border'>
            <div className='text-lg flex justify-between py-2 border-b'>
              <span className='font-semibold'>{t('Taxpayer Name')}:</span>{' '}
              <span className=''>{lang === 'ar' ? data?.taxpayer_name_ar : data?.taxpayer_name_en}</span>
            </div>
            <div className='text-lg flex justify-between py-2 border-b'>
              <span className='font-semibold'>{t('VAT Registration Number')}:</span>{' '}
              <span className=''>{data?.vat_registration}</span>
            </div>
            <div className='text-lg flex justify-between py-2 border-b'>
              <span className='font-semibold'>{t('Effective Registration Date')}:</span>{' '}
              <span className=''>{data?.registration_date}</span>
            </div>
            <div className='text-lg flex justify-between py-2'>
              <span className='font-semibold'>{t('Taxpayer Address')}:</span> <span className=''>{data?.address}</span>
            </div>
          </div>
        </div>

        <div className='w-full md:w-9/12 m-auto'>
          <div className='bg-white !rounded mt-4'>
            <table className='min-w-full'>
              <thead>
                <tr className='bg-gray-100'>
                  <th className='border p-3'>{t('tax')}</th>
                  <th className='border p-3'>{t('amount')}</th>
                  <th className='border p-3'>{t('adjustment')}</th>
                  <th className='border p-3'>{t('Tax Amount')}</th>
                </tr>
              </thead>
              <tbody>
                {/* Sales */}
                {/* Sales Row */}
                <tr>
                  <td className='border p-3'>
                    <b>{t('Sales')}</b>
                  </td>
                  <td className='border p-3'>
                    {data?.all_sales?.toFixed(2) || '0'} {`${t('SAR')}`}
                  </td>
                  <td className='border p-3'>{t('Credit notes issued this period: -0.00')}</td>
                  <td className='border p-3'>
                    {data?.all_sales_vat?.toFixed(2) || '0'} {`${t('SAR')}`}
                  </td>
                </tr>
                {/* Zero Tax Row */}
                <tr>
                  <td className='border p-3'>{t('Zero Tax')}</td>
                  <td className='border p-3'>0.00</td>
                  <td className='border p-3'>-0.00</td>
                  <td className='border p-3'>0.00</td>
                </tr>
                {/* Tax Exempt Row */}
                <tr>
                  <td className='border p-3'>{t('Tax Exempt')}</td>
                  <td className='border p-3'>
                    -{data?.tax_exempt_sale?.toFixed(2) || '0'} {`${t('SAR')}`}
                  </td>
                  <td className='border p-3'>-0.00</td>
                  <td className='border p-3'>
                    -{data?.tax_exempt_vat?.toFixed(2) || '0'} {`${t('SAR')}`}
                  </td>
                </tr>
                {/* Sales Total */}
                <tr className='bg-gray-100'>
                  <td className='border p-3'>{t('Total Sale')}</td>
                  <td className='border p-3'>
                    {data?.total_sale?.toFixed(2) || '0'} {`${t('SAR')}`}
                  </td>
                  <td className='border p-3'>0.00</td>
                  <td className='border p-3'>
                    {data?.total_sale_vat?.toFixed(2) || '0'} {`${t('SAR')}`}
                  </td>
                </tr>

                {/* Purchase */}
                {/* Purchase Row */}
                <tr>
                  <td className='border p-3'>
                    <b>{t('purchase')}</b>
                  </td>
                  <td className='border p-3'>
                    {data?.all_purchases?.toFixed(2) || '0'} {`${t('SAR')}`}
                  </td>
                  <td className='border p-3'>{t('Credit notes issued this period: -0.00')}</td>
                  <td className='border p-3'>
                    {data?.all_purchases_vat?.toFixed(2) || '0'} {`${t('SAR')}`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className='w-full md:w-9/12 m-auto'>
          <div className=' bg-white rounded px-4 py-2 mt-4 border'>
            <div className='text-lg flex justify-between py-2 border-b'>
              <span className='font-semibold'>{t('Net Purchase')}:</span>{' '}
              <span className=''>
                {data?.all_purchases?.toFixed(2) || '0'} {`${t('SAR')}`}
              </span>
            </div>
            <div className='text-lg flex justify-between py-2 border-b'>
              <span className='font-semibold'>{t('Net Sales')}:</span>{' '}
              <span className=''>
                {data?.total_sale?.toFixed(2) || '0'} {`${t('SAR')}`}
              </span>
            </div>

            <div className='text-lg flex justify-between py-2'>
              <span className='font-semibold'>{t('Net VAT')}:</span>{' '}
              <span className=''>
                {(data?.total_sale_vat + data?.all_purchases_vat)?.toFixed(2) || '0'} {`${t('SAR')}`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
