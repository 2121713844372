import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import validSupergitHIS from '../../api/validSupergitHIS';

export const getInvoiceSettings = createAsyncThunk('getInvoiceSettings', async (data, { rejectWithValue }) => {
  try {
    const response = await validSupergitHIS.post('invoiceSetting', data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error || 'something went wrong');
    return rejectWithValue(error?.response?.data?.error || 'something went wrong');
  }
});

export const updateInvoiceSettings = createAsyncThunk('updateInvoiceSettings', async (payload, { rejectWithValue }) => {
  try {
    const response = await validSupergitHIS.put(`account_settings/${payload?.id}`, payload?.data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error || 'something went wrong');
    return rejectWithValue(error?.response?.data?.error || 'something went wrong');
  }
});

const initialState = {
  isLoading: false,
  invoiceSettings: []
};

const invoiceSettingSlice = createSlice({
  name: 'invoiceSettings',
  initialState,
  extraReducers: builder => {
    builder

      .addCase(getInvoiceSettings.pending, state => {
        state.isLoading = true;
      })
      .addCase(getInvoiceSettings.fulfilled, (state, action) => {
        state.invoiceSettings = action.payload;
        state.isLoading = false;
      })
      .addCase(getInvoiceSettings.rejected, state => {
        state.isLoading = false;
        state.invoiceSettings = [];
      })
      .addCase(updateInvoiceSettings.pending, state => {
        state.isLoading = true;
      })
      .addCase(updateInvoiceSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action.payload.message || 'Succesfully Edit');
      })
      .addCase(updateInvoiceSettings.rejected, state => {
        state.isLoading = false;
      });
  }
});

export default invoiceSettingSlice.reducer;
