import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AutoComplete } from 'primereact/autocomplete';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import i18next from 'i18next';

import { getBranches } from '../../store/slices/BusinessSlice';
import { getCostCenter } from '../../store/slices/CostCenterSlice';
import { debounce } from '../../helpers/debounce';
import validSupergitHIS from '../../api/validSupergitHIS';
import SharedButton from '../../components/Shared/SharedButton';
import { convertToArabicNumerals } from '../../uitls/ConvertToArNumber';
import { getSearchedAccounts } from '../../store/slices/AcountsSlice';
import { createTransaction, getTransactionEntries, updateTransaction } from '../../store/slices/TransactionsSlice';
import { InputTextarea } from 'primereact/inputtextarea';

//Moves the header focus up or down in the grid based on the current header position.
const moveHeaderFocusUpDown = (previousHeader, headerRowCount, isUp) => {
  const previousColumn = previousHeader.column;
  const isSpanHeaderHeight = !!previousColumn.isSpanHeaderHeight && previousColumn.isSpanHeaderHeight();
  const lastRowIndex = previousHeader.headerRowIndex;
  let nextRowIndex = isUp ? lastRowIndex - 1 : lastRowIndex + 1;
  let nextColumn;
  if (nextRowIndex === -1) {
    return previousHeader;
  }
  if (nextRowIndex === headerRowCount) {
    nextRowIndex = -1;
  }
  let parentColumn = previousColumn.getParent();
  if (isUp) {
    if (isSpanHeaderHeight) {
      while (parentColumn && parentColumn.isPadding()) {
        parentColumn = parentColumn.getParent();
      }
    }
    if (!parentColumn) {
      return previousHeader;
    }
    nextColumn = parentColumn;
  } else {
    const children = (previousColumn.getChildren && previousColumn.getChildren()) || [];
    nextColumn = children.length > 0 ? children[0] : previousColumn;
  }
  return {
    headerRowIndex: nextRowIndex,
    column: nextColumn
  };
};

// Account cell editor component using PrimeReact AutoComplete
const AccountCellEditor = props => {
  const [value, setValue] = useState(props.value || '');
  const lang = i18next.language;
  const highlightedIndexRef = useRef(null);
  const [accountSuggestions, setAccountSuggestions] = useState([]);

  // AutoComplete filtering logic
  const handleAccountSearch = event => {
    if (event?.query?.length) {
      debouncedSearchAccoutns(event?.query);
    }
  };

  const debouncedSearchAccoutns = useCallback(
    debounce(async search => {
      try {
        const response = await validSupergitHIS.post('searchAccounts', { query: search });
        const listAccountsValues = response?.data
          ? Object.entries(response?.data?.data).map(([id, account]) => ({
              value: Number(account?.id),
              label: lang === 'ar' ? account?.name_ar + ' ' + account?.code : account?.name_en + ' ' + account?.code
            }))
          : [];
        setAccountSuggestions(listAccountsValues);
      } catch (error) {
        console.log(error);
        setAccountSuggestions([]);
      }
    }, 500),
    []
  );

  const handleChange = e => {
    setValue(e.value);
    if (e?.value?.label) {
      let value = e?.value?.label;
      props.node.setDataValue('account', value);
      props.api.stopEditing({ newValue: value });
      highlightedIndexRef.current = null;
    }
  };

  // Handle keyDown event to navigate and select with arrow keys and Enter
  const handleKeyDown = e => {
    if (e.key === 'ArrowDown') {
      highlightedIndexRef.current =
        highlightedIndexRef.current === null || highlightedIndexRef.current === accountSuggestions.length - 1
          ? 0
          : highlightedIndexRef.current + 1;
    } else if (e.key === 'ArrowUp') {
      highlightedIndexRef.current =
        highlightedIndexRef.current === null || highlightedIndexRef.current === 0
          ? accountSuggestions.length - 1
          : highlightedIndexRef.current - 1;
    }
  };

  const handleBlur = e => {
    const selectedItem = accountSuggestions[highlightedIndexRef.current];
    if (selectedItem) {
      setValue(selectedItem);
      props.node.setDataValue('account', selectedItem.label);
      props.api.stopEditing({ newValue: selectedItem.label });
      highlightedIndexRef.current = null;
    }
  };
  return (
    <AutoComplete
      value={value}
      suggestions={accountSuggestions}
      completeMethod={handleAccountSearch}
      field='label'
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      className='w-full'
      forceSelection
      autoFocus
      pt={{
        input: {
          root: { className: 'w-full border-0 !outline-none !shadow-none' }
        },
        panel: { className: '!bg-gray-100 !shadow-lg rounded-[8px] mt-2 pb-2 overflow-hidden' },
        loadingIcon: { className: `absolute top-[18px] ${lang == 'ar' ? '!left-[12px]' : 'right-[12px]'}` }
      }}
    />
  );
};

// Journal Entries Table Component
const JournalEntriesTable = ({ date, transactionId, transactionDescription }) => {
  const { t } = useTranslation();
  const lang = i18next.language;
  const gridRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const descriptionRef = useRef(null);
  const { branches } = useSelector(state => state.business);
  const { costCenters } = useSelector(state => state.costCenter);
  const { searchedAccountsList } = useSelector(state => state.acount);
  const { entries } = useSelector(state => state.transaction);
  const { isLoading } = useSelector(state => state.transaction);
  const [isRtl, setIsRtl] = useState(lang === 'ar' || lang === 'he');
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [debitTotal, setDebitTotal] = useState(0);
  const [creditTotal, setCreditTotal] = useState(0);

  // Generate 50 rows with empty values
  const initalRowsData = Array.from({ length: 50 }, () => ({
    account: '',
    debitAmount: 0,
    creditAmount: 0,
    comment: '',
    branch: '',
    costCenter: ''
  }));

  const listBranchValues = branches?.data
    ? Object.entries(branches?.data).map(([id, branch]) => ({
        value: Number(branch?.id),
        label: lang === 'ar' ? branch.name_ar : branch.name_en
      }))
    : [];

  const listCostCenterValues = costCenters?.data
    ? Object.entries(costCenters?.data).map(([id, costCenter]) => ({
        value: Number(costCenter?.id),
        label: lang === 'ar' ? costCenter.name_ar : costCenter.name_en
      }))
    : [];

  const listAccountsValues = searchedAccountsList?.data
    ? Object.entries(searchedAccountsList?.data).map(([id, account]) => ({
        value: Number(account?.id),
        label: lang === 'ar' ? account?.name_ar + ' ' + account?.code : account?.name_en + ' ' + account?.code
      }))
    : [];

  // Convert values to a list of values for agSelectCellEditor
  const branchValues = ['', ...listBranchValues.map(item => String(item.label))];
  const costCenterValues = ['', ...listCostCenterValues.map(item => String(item.label))];

  useEffect(() => {
    if (transactionId) {
      dispatch(getTransactionEntries(transactionId));
    }
  }, [transactionId]);

  useEffect(() => {
    const formattedRowsEntries = entries?.map(data => {
      let branch = listBranchValues?.find(item => item.value == data?.location_id)?.label || '';
      let costCenter = listCostCenterValues?.find(item => item.value == data?.cost_center_id)?.label || '';
      return {
        account:
          lang === 'ar'
            ? data?.account?.name_ar + ' ' + data?.account?.code
            : data?.account?.name_en + ' ' + data?.account?.code,
        branch,
        costCenter,
        debitAmount: data.type === 'Debit' ? data?.amount : 0,
        creditAmount: data.type === 'Credit' ? data?.amount : 0,
        comment: data?.description ?? ''
      };
    });
    const paddedRowsEntries = Array.from(
      { length: 50 },
      (_, index) =>
        formattedRowsEntries[index] || {
          account: '',
          branch: '',
          costCenter: '',
          debitAmount: 0,
          creditAmount: 0,
          comment: ''
        }
    );
    if (formattedRowsEntries) {
      setRowData(paddedRowsEntries);
    }
    descriptionRef.current.value = transactionDescription ?? '';
  }, [entries]);

  useEffect(() => {
    const fetchData = async () => {
      let payload = { qery: '' };
      await Promise.all([dispatch(getBranches()), dispatch(getCostCenter(), dispatch(getSearchedAccounts(payload)))]);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    setIsRtl(lang === 'ar');
    setColumnDefs(columsData);
  }, [lang]);

  useEffect(() => {
    setRowData(initalRowsData);
    setColumnDefs(columsData);
  }, []);

  // Function to handle cell value change
  const handleCellValueChange = params => {
    const creditArray = [];
    const debitArray = [];
    const { colDef, newValue, node } = params;

    // Check if the changed field is either 'creditAmount' or 'debitAmount'
    if (colDef.field === 'creditAmount' || colDef.field === 'debitAmount') {
      gridRef.current?.api.forEachNode(rowNode => {
        const creditAmount = rowNode.data.creditAmount;
        const debitAmount = rowNode.data.debitAmount;
        if (colDef.field === 'creditAmount') {
          if (newValue && node?.rowIndex == rowNode?.rowIndex) {
            rowNode.setDataValue('debitAmount', 0);
          }
        } else if (colDef.field === 'debitAmount') {
          if (newValue && node?.rowIndex == rowNode?.rowIndex) {
            rowNode.setDataValue('creditAmount', 0);
          }
        }
        if (creditAmount) {
          creditArray.push(Number(creditAmount));
        }
        if (debitAmount) {
          debitArray.push(Number(debitAmount));
        }
      });
      if (creditArray?.length || debitArray?.length) {
        calculateTotals(creditArray, debitArray);
      }
    }
  };

  const columsData = [
    {
      headerName: '',
      valueGetter: 'node.rowIndex + 1',
      width: 50,
      suppressMenu: true,
      cellRenderer: params => {
        const rowIndex = params.node.rowIndex + 1;
        const arabicIndex = convertToArabicNumerals(rowIndex);
        return lang == 'ar' ? arabicIndex : rowIndex;
      }
    },
    {
      headerName: t('account'),
      field: 'account',
      editable: true,
      cellEditor: AccountCellEditor,
      cellEditorParams: params => ({
        value: params?.value || ''
      }),
      width: 250,
      flex: 1
    },
    {
      headerName: t('debitAmount'),
      editable: true,
      field: 'debitAmount',
      cellEditor: 'agNumberCellEditor',
      stopEditingWhenCellsLoseFocus: true,
      width: 250,
      flex: 1
    },
    {
      headerName: t('creditAmount'),
      field: 'creditAmount',
      editable: true,
      cellEditor: 'agNumberCellEditor',
      stopEditingWhenCellsLoseFocus: true,
      width: 250,
      flex: 1
    },
    { headerName: t('comment'), field: 'comment', editable: true, width: 250, flex: 1 },
    {
      headerName: t('branch'),
      field: 'branch',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: branchValues
      },
      cellRenderer: params => {
        const match = listBranchValues.find(item => item.label === params.value);
        const isLangAr = lang == 'ar';
        return (
          <div>
            <span>
              {match ? match.label : params.value}
              <i
                className={`pi pi-angle-down absolute top-[12px] text-[12px] ${
                  isLangAr ? 'left-[15px]' : 'right-[15px]'
                }`}
              ></i>
            </span>
          </div>
        );
      },
      width: 250,
      flex: 1
    },
    {
      headerName: t('costCenter'),
      field: 'costCenter',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: costCenterValues
      },
      cellRenderer: params => {
        const match = listCostCenterValues.find(item => item.label === params.value);
        const isLangAr = lang == 'ar';
        return (
          <span>
            {match ? match.label : params.value}
            <i
              className={`pi pi-angle-down absolute top-[12px] text-[12px] ${
                isLangAr ? 'left-[15px]' : 'right-[15px]'
              }`}
            ></i>
          </span>
        );
      },
      width: 250,
      flex: 1
    }
  ];

  const gridOptions = {
    defaultColDef: {
      cellClass: 'border !border-gray-300 !border-t-0 !border-b-0 !border-r-0 py-2'
    },
    rowHeight: 40,
    onCellValueChanged: handleCellValueChange
  };

  //Navigates between header rows when the Tab key is pressed, supporting both forward and backward navigation.
  const tabToNextHeader = useCallback(params => {
    return moveHeaderFocusUpDown(params.previousHeaderPosition, params.headerRowCount, params.backwards);
  }, []);

  // Handles Arrow key navigation between cells (ArrowUp, ArrowDown, ArrowLeft, ArrowRight),
  const navigateToNextCell = useCallback(params => {
    const previousCell = params.previousCellPosition,
      suggestedNextCell = params.nextCellPosition;
    let nextRowIndex, renderedRowCount;
    switch (params.key) {
      case 'ArrowDown':
        // return the cell above
        nextRowIndex = previousCell.rowIndex + 1;
        if (nextRowIndex < -1) {
          return null;
        } // returning null means don't navigate
        return {
          rowIndex: nextRowIndex,
          column: previousCell.column,
          rowPinned: previousCell.rowPinned
        };
      case 'ArrowUp':
        // return the cell below
        nextRowIndex = previousCell.rowIndex - 1;
        renderedRowCount = params.api.getDisplayedRowCount();
        if (nextRowIndex >= renderedRowCount) {
          return null;
        } // returning null means don't navigate
        return {
          rowIndex: nextRowIndex,
          column: previousCell.column,
          rowPinned: previousCell.rowPinned
        };
      case 'ArrowLeft':
      case 'ArrowRight':
        return suggestedNextCell;
      default:
        throw Error('this will never happen, navigation is always one of the 4 keys above');
    }
  }, []);

  // Calculates the sum of credits and debits cells
  const calculateTotals = useCallback((creditData, debitData) => {
    const totalCredit = creditData.reduce((sum, value) => sum + value, 0);
    const totalDebit = debitData.reduce((sum, value) => sum + value, 0);
    setDebitTotal(totalDebit);
    setCreditTotal(totalCredit);
  }, []);

  // Handle Create Tranctions
  const handleSubmitData = async () => {
    let dateValue = date?.current?.value;
    let descriptionValue = descriptionRef?.current?.value;

    if (!dateValue) {
      toast.error('Please fill in the date');
      return;
    }
    let isRowDataExists = false;
    gridRef.current?.api.forEachNode(rowNode => {
      const { account, debitAmount, creditAmount } = rowNode.data || {};
      // Check if any of the required fields have data
      if (account && (debitAmount || creditAmount)) {
        isRowDataExists = true;
        return;
      }
    });

    if (!isRowDataExists) {
      toast.error('Please add at least one entry');
      return;
    }

    const formattedData = [];
    gridRef.current?.api.forEachNode(rowNode => {
      let data = {
        account: listAccountsValues?.find(data => data?.label == rowNode?.data?.account)?.value || '',
        debitAmount: parseFloat(rowNode?.data?.debitAmount) || 0,
        creditAmount: parseFloat(rowNode?.data?.creditAmount) || 0,
        comment: rowNode?.data?.comment || '',
        branch: listBranchValues?.find(item => item.label === rowNode?.data?.branch)?.value || '',
        costCenter: listCostCenterValues?.find(item => item.label === rowNode?.data?.costCenter)?.value || ''
      };

      if (data?.account && (data?.creditAmount || data?.debitAmount)) {
        formattedData.push(data);
      }
    });
    const totalDebit = formattedData.reduce((sum, entry) => sum + entry.debitAmount, 0);
    const totalCredit = formattedData.reduce((sum, entry) => sum + entry.creditAmount, 0);

    if (Math.abs(totalDebit - totalCredit) > 0.01) {
      toast.error('Total debit and credit amounts must be equal');
      return;
    }

    const payloadData = {
      date: dateValue,
      description: descriptionValue,
      entry_details: formattedData.map(data => {
        let entry = {
          account_id: data?.account,
          debit_amount: data?.debitAmount,
          credit_amount: data?.creditAmount,
          comment: data?.comment
        };
        if (data?.branch) entry.branch_id = data?.branch;
        if (data?.costCenter) entry.cost_center_id = data?.costCenter;
        return entry;
      })
    };

    //    console.log(payloadData);

    if (transactionId) {
      await dispatch(updateTransaction({ id: transactionId, data: payloadData })).then(res => {
        navigate('/manager/transactions');
      });
    } else {
      await dispatch(createTransaction(payloadData)).then(res => {
        if (res?.payload?.message == 'Journal Entry created successfully') {
          navigate('/manager/transactions');
        }
      });
    }
  };

  return (
    <React.Fragment>
      <div className='ag-theme-quartz h-[62vh] overflow-y-auto mt-8'>
        <AgGridReact
          ref={gridRef}
          key={isRtl ? 'rtl' : 'ltr'}
          rowData={rowData}
          animateRows={true}
          columnDefs={columnDefs}
          enableRtl={isRtl}
          gridOptions={gridOptions}
          tabToNextHeader={tabToNextHeader}
          navigateToNextCell={navigateToNextCell}
          singleClickEdit={true}
        />
      </div>
      <div className='mt-2'>
        <div className='flex justify-between px-4 py-2 bg-gray-200 rounded-md'>
          <div className='flex gap-4'>
            <div>
              <span className='font-bold'>{t('Total Debit')}: </span>
              <span>{debitTotal.toFixed(2)}</span>
            </div>
            <div>
              <span className='font-bold'>{t('Total Credit')}: </span>
              <span>{creditTotal.toFixed(2)}</span>
            </div>
          </div>
          <SharedButton
            disabled={isLoading}
            onClick={handleSubmitData}
            icon='pi pi-check'
            label={t('Submit')}
            className='submit-btn'
          />
        </div>
      </div>
      <div className='py-2'>
        <div className='flex flex-col gap-1'>
          <label htmlFor='description' className='text-base font-medium'>
            {t('description')}
          </label>
          <InputTextarea
            id='description'
            placeholder={`${t('enter')} ${t('description')}`}
            type='text'
            ref={descriptionRef}
            className='w-full h-[80px] !p-1 rounded-md hover:border-primary !outline-none !shadow-none'
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default JournalEntriesTable;
