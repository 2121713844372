import React, { useState } from 'react';
import SharedTooltip from '../../components/Shared/SharedTooltip';
import { useTranslation } from 'react-i18next';
import EmployeeMain from '../Employee/EmployeeMain';
import LeaveMain from '../Leave/LeaveMain';
import AttendanceMain from '../Attendance/AttendanceMain';
import DeductionMain from '../Deduction/DeductionMain';
import AllowanceMain from '../Allowance/AllowanceMain';
import PayslipMain from '../Payslip/PayslipMain';
import PayrunMain from '../Payrun/PayrunMain';

export default function PayrollMain() {
  const [activeTab, setActiveTab] = useState('employees');
  const { t } = useTranslation();
  const tabList = [
    {
      label: t('employees'),
      comapre: 'employees',
      command: () => setActiveTab('employees')
    },
    {
      label: t('attendances'),
      comapre: 'attendance',
      command: () => setActiveTab('attendance')
    },
    {
      label: t('leaves'),
      comapre: 'leave',
      command: () => setActiveTab('leave')
    },
    {
      label: t('deductions'),
      comapre: 'deduction',
      command: () => setActiveTab('deduction')
    },
    {
      label: t('allowances'),
      comapre: 'allowance',
      command: () => setActiveTab('allowance')
    },
    {
      label: t('payruns'),
      comapre: 'payrun',
      command: () => setActiveTab('payrun')
    },
    {
      label: t('payslips'),
      comapre: 'payslip',
      command: () => setActiveTab('payslip')
    }
  ];

  return (
    <div>
      <div>
        <div className='flex justify-between flex-col gap-3 sm:gap-0 sm:flex-row mb-3'>
          <SharedTooltip content={t('Keep_track_of_payrolls')} position='left' id='report' />
          <span data-tooltip-id='report' className='font-semibold text-black/70 text-xl mt-2 '>
            {t('PAYROLL')}
          </span>
        </div>{' '}
        <div className=''>
          <ul className='grid grid-flow-col mb-2 text-center text-gray-500 bg-gray-100 border rounded p-1 shadow-inner'>
            {tabList.map((tab, index) => (
              <li key={index}>
                <a
                  className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                    activeTab === tab?.comapre ? ' bg-gradient rounded shadow text-white ' : ''
                  }`}
                  onClick={() => tab?.command()}
                >
                  {tab.label}
                </a>
              </li>
            ))}
          </ul>

          <div className='rounded-md h-[70vh] overflow-auto'>
            {activeTab === 'employees' ? (
              <EmployeeMain />
            ) : activeTab === 'leave' ? (
              <LeaveMain />
            ) : activeTab === 'attendance' ? (
              <AttendanceMain />
            ) : activeTab === 'deduction' ? (
              <DeductionMain />
            ) : activeTab === 'allowance' ? (
              <AllowanceMain />
            ) : activeTab === 'payrun' ? (
              <PayrunMain />
            ) : activeTab === 'payslip' ? (
              <PayslipMain />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
