import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SharedButton from '../../components/Shared/SharedButton';
import CreateItem from './CreateItem';
import ItemsTable from './ItemsTable';
import { debounce } from '../../helpers/debounce';
import { getItemsList, getSearchedItem } from '../../store/slices/ItemSlice';
import SharedTooltip from '../../components/Shared/SharedTooltip';

export default function ItemsMain() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filteredInvoices, setFilteredInvoices] = useState(null);
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const { itemsList, isLoading } = useSelector(state => state.item);

  useEffect(() => {
    dispatch(getItemsList({ page: 1, per_page: 10 }));
  }, [dispatch]);

  const handleSearch = async event => {
    const value = event?.target?.value;
    debouncedSearch(value);
  };

  // Debounced search function with a delay of 500ms
  const debouncedSearch = useCallback(
    debounce(value => {
      if (value?.trim()) {
        const data = dispatch(getSearchedItem({ search: value.trim(), page: 1, per_page: 10 }));
        if (data?.data && data?.data.length > 0) {
          setFilteredInvoices(data);
        }
      } else {
        setFilteredInvoices(null);
        dispatch(getItemsList({ page: 1, per_page: 10 }));
      }
    }, 500),
    []
  );

  return (
    <div>
      <div className='flex justify-between mb-3'>
        <SharedTooltip content={t('Keep_track_of_your_items')} position='left' id='Items' />
        <span data-tooltip-id='Items' className='font-semibold text-black/70 text-xl mt-2 '>
          {t('ITEMS')}
          <span className='italic text-lg ml-1'>
            ({itemsList?.total_record || 0}
            <span className='text-xs font-bold italic'> {t('Entries')}</span>)
          </span>
        </span>
        <div className='flex items-center gap-4'>
          <div className='flex gap-2'>
            <input
              placeholder={t('Search by Name or Code')}
              type='text'
              className='border border-gray-300 rounded-lg px-2.5 py-1.5 w-64 hover:border-primary !outline-none !shadow-none'
              onChange={handleSearch}
            />
          </div>
          <SharedButton
            label={t('CreateItem')}
            icon='pi pi-plus'
            onClick={() => setCreateDialogVisible(true)}
            className='bg-gradient primary-btn'
          />
        </div>
      </div>
      <CreateItem
        createDialogVisible={createDialogVisible}
        setCreateDialogVisible={setCreateDialogVisible}
        isLoading={isLoading}
        editData={null}
      />
      <ItemsTable data={filteredInvoices?.length ? filteredInvoices : itemsList || []} />
    </div>
  );
}
