import React from 'react';
import Skeleton from 'react-loading-skeleton';

const TableLoading = ({ length = 1 }) => {
  return (
    <React.Fragment>
      <Skeleton height={50} />
      <Skeleton className='w-full' height={40} count={length} />
    </React.Fragment>
  );
};

export default TableLoading;
