import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SharedTooltip from '../../components/Shared/SharedTooltip';
import SharedButton from '../../components/Shared/SharedButton';
import { getPurchasedEntryList, getSearchedPurchaseEntery } from '../../store/slices/PurchaseSlice';
import PurchasedEntryTable from './PurchasedEntryTable';
import { debounce } from '../../helpers/debounce';

export default function PurchasedEntryMain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedTransactionEntries, setSelectedTransactionEntries] = useState([]);
  const { purchasedEntryList } = useSelector(state => state.purchase);
  const [filteredInvoices, setFilteredInvoices] = useState(null);

  useEffect(() => {
    dispatch(getPurchasedEntryList({ page: 1, per_page: 10 }));
  }, []);

  const handleSearch = event => {
    const value = event?.target?.value;
    debouncedSearch(value);
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(async value => {
      const trimmedValue = value?.trim();
      // Handle empty input
      if (!trimmedValue) {
        setFilteredInvoices(null);
        dispatch(getPurchasedEntryList({ page: 1, per_page: 10 }));
        return;
      }
      const data = await dispatch(getSearchedPurchaseEntery({ query: trimmedValue, page: 1, per_page: 10 }));
      if (data?.data?.length > 0) {
        setFilteredInvoices(data);
      }
    }, 500),
    []
  );

  return (
    <>
      <div>
        <div className='flex justify-between flex-col gap-3 sm:gap-0 sm:flex-row mb-3'>
          <SharedTooltip content={t('Keep_track_of_purchased_entries')} position='left' id='Items' />
          <span data-tooltip-id='Items' className='font-semibold text-black/70 text-xl mt-2 '>
            {t('PURCHASEDENTRIES')}
            <span className='italic text-lg ml-1'>
              ({purchasedEntryList?.total_record}
              <span className='text-xs font-bold italic'> {t('Entries')}</span>)
            </span>
          </span>

          <div className=''>
            <div className='flex items-center gap-4'>
              <div className='flex gap-2'>
                <input
                  placeholder={t(' Search by Name')}
                  type='text'
                  className='border border-gray-300 rounded-lg px-2.5 py-1.5 w-64 hover:border-primary !outline-none !shadow-none'
                  onChange={handleSearch}
                />
              </div>

              <SharedButton
                label={t('CreatePurchasedEntry')}
                icon='pi pi-plus'
                onClick={() => navigate('/manager/purchased_entry/create')}
                className='bg-gradient primary-btn'
              />
            </div>
          </div>
        </div>
        <PurchasedEntryTable
          data={filteredInvoices?.length ? filteredInvoices : purchasedEntryList}
          selectedTransactionEntries={selectedTransactionEntries}
          setSelectedTransactionEntries={setSelectedTransactionEntries}
        />
      </div>
    </>
  );
}
