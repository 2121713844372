// ---openAndCollapsable---
import React, { useState } from 'react';
import { IoMdEye } from 'react-icons/io';
import { MdOutlineEdit } from 'react-icons/md';
import { MdOutlineDelete } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import SharedTooltip from '../../components/Shared/SharedTooltip';
import { deleteAccount, getAccounts } from '../../store/slices/AcountsSlice';
import ConfirmationDialog from '../../components/Shared/ConfirmationDialog';
import TableLoading from '../../components/Shared/TableLoading';
import CustomTreeTable from '../../components/Shared/CustomTreeTable';
import CustomTable from '../../components/Shared/CustomTable';

export default function AccountsTable({ data, setUpdateData, setCreateDialogVisible, activeTab, loading, query }) {
  const lang = i18next.language;
  const { t } = useTranslation();
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
  const dispatch = useDispatch();
  const [deleteData, setDeleteData] = useState(null);
  const [isViewAccount, setIsViewAccountDetails] = useState(false);
  const [selectedAccountData, setSelectAccountData] = useState(null);

  const handleDeleteAccount = () => {
    dispatch(deleteAccount(deleteData?.data?.id)).then(() => {
      dispatch(getAccounts({ account_type: activeTab }));
    });
    setConfirmationDialogVisible(false);
    setDeleteData(null);
  };

  const templateBody = rowData => {
    return (
      <div className='flex gap-1'>
        <SharedTooltip content='view' id='my-tooltip-1' />
        <SharedTooltip id='my-tooltip-2' content='edit' />
        <SharedTooltip id='my-tooltip-3' content='delete' />
        <IoMdEye
          size={25}
          data-tooltip-id='my-tooltip-1'
          className='table-icon'
          onClick={() => {
            setIsViewAccountDetails(true);
            setSelectAccountData([rowData?.data]);
          }}
        />
        <MdOutlineEdit
          size={25}
          data-tooltip-id='my-tooltip-2'
          className='table-icon'
          onClick={() => {
            setUpdateData(rowData?.data);
            setCreateDialogVisible(true);
          }}
        />
        <MdOutlineDelete
          onClick={() => {
            setConfirmationDialogVisible(true);
            setDeleteData(rowData);
          }}
          size={25}
          data-tooltip-id='my-tooltip-3'
          className='table-icon'
        />
      </div>
    );
  };

  const balanceBody = data => {
    return (
      <>
        {/* {data?.data?.balance} {t("SAR")} */}
        {data?.data?.balance ? data?.data?.balance.toFixed(2) : '0'} {`${t('SAR')}`}
      </>
    );
  };

  const columnsData = [
    {
      field: lang === 'ar' ? 'name_code_ar' : 'name_code_en',
      header: t('name'),
      headerCellClass: '!w-[40%]',
      bodyCellClass: '!w-[40%]',
      expander: true
    },
    {
      field: lang === 'ar' ? 'type_ar' : 'type_en',
      headerCellClass: '!w-[15%]',
      bodyCellClass: '!w-[15%]',
      header: t('type')
    },
    {
      field: lang === 'ar' ? 'sub_type_ar' : 'sub_type_en',
      headerCellClass: '!w-[15%]',
      bodyCellClass: '!w-[15%]',
      header: t('subType')
    },
    {
      body: templateBody,
      headerCellClass: '!w-[15%]',
      bodyCellClass: '!w-[15%]',
      header: t('actions')
    }
  ];

  const viewAccountColumns = [
    {
      field: lang === 'en' ? `name_en` : `name_ar`,
      header: t('name')
    },
    {
      field: lang === 'ar' ? 'type_ar' : 'type_en',
      header: t('type')
    },
    {
      field: lang === 'ar' ? 'sub_type_ar' : 'sub_type_en',
      header: t('subType')
    },
    {
      field: 'balance',
      header: t('balance')
    },
    {
      field: 'description',
      header: t('description')
    }
  ];

  return (
    <div>
      <CustomTreeTable
        data={data}
        isLoading={loading}
        loadingIcon={<TableLoading />}
        columns={columnsData}
        stateKey='account-table'
        stateStorage='local'
        columnResizeMode='expand'
        emptyMessage={<div className='text-center w-full py-4 text-gray-500'>{t('No user found')}</div>}
        pt={{
          wrapper: { className: 'overflow-auto' },
          table: { className: 'min-w-[50rem]' }
        }}
        globalFilter={query}
        filterMode={'lenient'}
      />
      <Dialog
        header={t('account_details')}
        visible={isViewAccount}
        onHide={() => {
          if (!isViewAccount) return;
          setIsViewAccountDetails(false);
          setSelectAccountData(null);
        }}
        pt={{
          root: { className: 'w-full lg:w-[50%] mx-4 overflow-auto' },
          header: { className: '!p-2 !bg-darkBg !text-white' },
          content: { className: '!p-3' },
          closeButton: {
            className: 'hover:!bg-black/20 !outline-none !shadow-none'
          },
          closeButtonIcon: { className: '!text-white' }
        }}
      >
        <CustomTable data={selectedAccountData} columns={viewAccountColumns} />
      </Dialog>
      <ConfirmationDialog
        title={t('delete_confirmation_popup_text')}
        isDialogVisible={confirmationDialogVisible}
        setIsDialogVisible={setConfirmationDialogVisible}
        confirmHandler={handleDeleteAccount}
      />
    </div>
  );
}
