import i18next from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
// import { ChevronRight, ChevronDown } from 'lucide-react';

const TreeNode = ({ node, level }) => {
  const [isOpen, setIsOpen] = useState(true);
  const language = i18next.language;
  const hasChildren = node.children && node.children.length > 0;
  const toggleOpen = () => setIsOpen(!isOpen);
  const { t } = useTranslation();

  return (
    <>
      <div className={`grid grid-cols-12 items-center py-2 border-b px-4 ${level === 0 ? 'font-medium' : ''}`}>
        <div
          //   className={`col-span-${7 - level} flex items-center `}
          className={`col-span-9 flex items-center `}
          style={{ paddingLeft: `${level * 20}px` }}
        >
          {hasChildren && (
            <button onClick={toggleOpen} className='mr-2'>
              {isOpen ? <IoIosArrowDown size={16} /> : <IoIosArrowForward size={16} />}
            </button>
          )}
          <span>{language === 'en' ? node?.data?.name_en : node?.data?.name_ar}</span>
        </div>
        {/* <div className={`col-span-${5 + level} text-right `}> */}
        <div className={`col-span-3 `}>
          {/* {formatCurrency(node?.data?.balance)} */}
          {node?.data?.balance ? node?.data?.balance.toFixed(2) : '0'} <span>{t('SAR')}</span>
        </div>
      </div>
      {isOpen &&
        hasChildren &&
        node.children.map((child, index) => (
          <TreeNode key={child.key} node={child} level={level + 1} language={language} />
        ))}
    </>
  );
};

const CustomBalanceSheetTreeTable = ({ data, language = 'en' }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className='py-2 text-xl font-semibold'>1 - {t('assets')}</div>
      <div className='bg-white rounded-lg shadow overflow-hidden my-2'>
        <div className='grid grid-cols-12 bg-gray-200 font-bold py-2 px-4'>
          <div className='col-span-9'>{t('name')}</div>
          <div className='col-span-3'>{t('balance')}</div>
        </div>
        {data?.assets?.accounts?.map((account, index) => (
          <TreeNode key={account.key} node={account} level={0} language={language} />
        ))}
      </div>
    </>
  );
};

export default CustomBalanceSheetTreeTable;
