import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FaMoneyBill, FaMoneyCheck } from 'react-icons/fa';
import i18next from 'i18next';

import SharedButton from '../../components/Shared/SharedButton';
import SettingsFormFieldsArray from '../../components/settings/SettingFormFieldsArray';

const CreateSettings = () => {
  const { t } = useTranslation();
  const lang = i18next.language;

  const initialValues = {
    sales_accounts: [{ sales_account_selected: '', sales_account_type: '', sales_account_isvat: false }],
    company_accounts: [{ company_account_selected: '', company_account_type: '', company_account_isvat: false }],
    purchase_accounts: [{ purchase_account_selected: '', purchase_account_type: '', purchase_account_isvat: false }]
  };

  const validationSchema = Yup.object({
    sales_accounts: Yup.array().of(
      Yup.object({
        sales_account_selected: Yup.string().required(t('sales_accounts.account_required')),
        sales_account_type: Yup.string().required(t('sales_accounts.type_required')),
        sales_account_isvat: Yup.boolean().required(t('sales_accounts.vat_required'))
      })
    ),
    company_accounts: Yup.array().of(
      Yup.object({
        company_account_selected: Yup.string().required(t('company_accounts.account_required')),
        company_account_type: Yup.string().required(t('company_accounts.type_required')),
        company_account_isvat: Yup.boolean().required(t('company_accounts.vat_required'))
      })
    ),
    purchase_accounts: Yup.array().of(
      Yup.object({
        purchase_account_selected: Yup.string().required(t('purchase_accounts.account_required')),
        purchase_account_type: Yup.string().required(t('purchase_accounts.type_required')),
        purchase_account_isvat: Yup.boolean().required(t('purchase_accounts.vat_required'))
      })
    )
  });

  const handleFormSubmit = values => {
    console.log(values, 'values');
  };

  return (
    <div className='flex flex-col rounded-md bg-gray-100 px-6 py-4 w-full max-h-[calc(100vh-100px)] overflow-auto'>
      <h2 className='text-3xl font-bold mb-4 text-gray-800'>{t('create_setting')}</h2>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleFormSubmit}>
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <SettingsFormFieldsArray
              name='sales_accounts'
              label={{
                selectAccount: t('select_acccount'),
                selectAccountType: t('select_acccount_type'),
                addNew: t('add_new')
              }}
              title={t('salesinvoice')}
              icon={FaMoneyBill}
              lang={lang}
              values={values}
              fieldsNames={['sales_account_selected', 'sales_account_type', 'sales_account_isvat']}
              setFieldValue={setFieldValue}
              addNewItem={() => ({
                sales_account_selected: '',
                sales_account_type: '',
                sales_account_isvat: false
              })}
            />
            <SettingsFormFieldsArray
              name='company_accounts'
              label={{
                selectAccount: t('select_acccount'),
                selectAccountType: t('select_acccount_type'),
                addNew: t('add_new')
              }}
              title={t('company Invoice')}
              icon={FaMoneyCheck}
              lang={lang}
              values={values}
              fieldsNames={['company_account_selected', 'company_account_type', 'company_account_isvat']}
              setFieldValue={setFieldValue}
              addNewItem={() => ({
                company_account_selected: '',
                company_account_type: '',
                company_account_isvat: false
              })}
            />
            <SettingsFormFieldsArray
              name='purchase_accounts'
              label={{
                selectAccount: t('select_acccount'),
                selectAccountType: t('select_acccount_type'),
                addNew: t('add_new')
              }}
              title={t('purcahse_invoice')}
              icon={FaMoneyCheck}
              lang={lang}
              values={values}
              fieldsNames={['purchase_account_selected', 'purchase_account_type', 'purchase_account_isvat']}
              setFieldValue={setFieldValue}
              addNewItem={() => ({
                purchase_account_selected: '',
                purchase_account_type: '',
                purchase_account_isvat: false
              })}
            />
            <div className='flex justify-end gap-6 mt-4'>
              <SharedButton icon='pi pi-times' label={t('Cancel')} className='cancel-btn' />
              <SharedButton type='submit' icon='pi pi-plus' label={t('create_setting')} className='submit-btn' />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateSettings;
