import React, { useEffect, useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { useField } from 'formik';

import { fetchData } from '../../helpers/fetchData';
import { useTranslation } from 'react-i18next';

const CustomSearchInput = ({
  options,
  label,
  name,
  placeholder,
  className,
  onChange,
  isAsync = false,
  fieldUrl,
  optionsFormat = null,
  defaultValue = null,
  ...props
}) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);
  const [selectedOption, setSelectedOptions] = useState(null);
  const emptyOptionsMessage = useRef(t('search'));

  // Function to fetch options from BE
  const loadOptions = async inputValue => {
    if (!fieldUrl) return [];

    const payload = { query: inputValue };
    const response = await fetchData('POST', fieldUrl, payload);
    if (!response?.data?.length) {
      emptyOptionsMessage.current = t('no_options_found');
      return [];
    }
    return optionsFormat
      ? optionsFormat(response?.data)
      : response?.data?.map(({ name, id }) => ({ label: name, value: id }));
  };

  const handleChange = optionValue => {
    helpers.setValue(optionValue.value);
    if (isAsync && optionValue) {
      setSelectedOptions(optionValue);
    }
    if (onChange) {
      onChange(optionValue);
    }
  };

  const getValue = () => {
    if (options) {
      return options.find(option => option.value === field.value) || null;
    } else {
      return null;
    }
  };

  const getAsyncValue = () => {
    return field.value && { label: selectedOption?.label, value: field.value };
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedOptions(defaultValue);
    }
  }, []);

  return (
    <>
      <div className='flex flex-col gap-1 w-full'>
        <label htmlFor={name} className='text-base font-medium'>
          {label}
        </label>
        {isAsync ? (
          <AsyncSelect
            classNames={{
              control: state => (state.isFocused ? '!border-primary' : '!border-gray-300')
            }}
            classNamePrefix='select'
            className={`!cursor-text !p-0 w-full border-gray-300 rounded-md hover:!border-primary !outline-none shadow-none ${className}`}
            loadOptions={loadOptions}
            value={getAsyncValue()}
            onChange={handleChange}
            onBlur={() => helpers.setTouched(true)}
            placeholder={placeholder}
            noOptionsMessage={() => emptyOptionsMessage.current}
            loadingMessage={() => t('loading')}
            {...props}
          />
        ) : (
          <Select
            classNames={{
              control: state => (state.isFocused ? '!border-primary' : '!border-gray-300')
            }}
            className={`!cursor-text !p-0 w-full border-gray-300 rounded-md hover:!border-primary !outline-none shadow-none ${className}`}
            classNamePrefix='select'
            options={options}
            name={name}
            placeholder={placeholder}
            value={getValue()}
            onChange={handleChange}
            onBlur={() => helpers.setTouched(true)}
            {...props}
          />
        )}
        {meta.error && meta.touched && <p className='text-sm text-red-500'>{meta.error}</p>}
      </div>
    </>
  );
};

export default CustomSearchInput;
