import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AccountStatementTable({ data }) {
  const lang = i18next.language;
  const indexedData = data ? data.map((item, index) => ({ ...item, index: index + 1 })) : [];

  const { t } = useTranslation();

  if (!indexedData || !indexedData) {
    return <div>No ledger data available</div>;
  }

  const calculateTotals = () => {
    let totalDebit = 0;
    let totalCredit = 0;
    let balance = 0;
    // let balance=0;
    indexedData.forEach(item => {
      if (item.entry_type === 'Credit') {
        totalCredit += parseFloat(item.entry_amount) || 0;
      } else {
        totalDebit += parseFloat(item.entry_amount) || 0;
      }
      // balance+=(parseFloat(item.transaction_total_debit))-(parseFloat(item.transaction_total_credit))
    });

    const netTotal = totalDebit + -totalCredit;

    return {
      totalDebit: totalDebit.toFixed(2),
      totalCredit: totalCredit.toFixed(2),
      netTotal: netTotal.toFixed(2)
      // balance:balance.toFixed(2),
    };
  };

  const totals = calculateTotals();

  let runningBalance = 0;

  return (
    <>
      <div className='bg-white !rounded-tl-md !rounded-tr-md'>
        <div className='grid grid-cols-12 bg-secondary font-bold py-1 !rounded-tl-md !rounded-tr-md'>
          <div className='col-span-1 px-1.5'>{t('no')}</div>
          <div className='col-span-1'>{t('date')}</div>
          <div className='col-span-1'>{`${t('account')}`}</div>
          <div className='col-span-1'>{`${t('sub_type')}`}</div>
          <div className='col-span-2'>{`${t('description')}`}</div>
          <div className='col-span-2'>{`${t('debit')} ${t('amount')}`}</div>
          <div className='col-span-2'>{`${t('credit')} ${t('amount')}`}</div>
          <div className='col-span-2'>{`${t('balance')}`}</div>
          {/* <div className="col-span-4">{`${t("description")}`}</div> */}
        </div>
        {indexedData.map(asset => {
          if (asset.entry_type === 'Credit') {
            runningBalance -= parseFloat(asset.entry_amount) || 0;
          } else {
            runningBalance += parseFloat(asset.entry_amount) || 0;
          }

          return (
            <div key={asset.id} className='grid grid-cols-12 items-center py-1 border-b last:border-b-0'>
              <div className='col-span-1 px-1.5'>{asset.index}</div>
              <div className='col-span-1'>{asset.transaction_date}</div>
              <div className='col-span-1'>
                {asset.account_code}-{lang === 'ar' ? `${asset.account_name_en}` : `${asset.account_name_ar}`}
              </div>

              <div className='col-span-1'>
                {lang === 'ar' ? `${asset.account_sub_type_ar}` : `${asset.account_sub_type_en}`}
              </div>

              <div className='col-span-2'>{asset?.entry_description}</div>

              <div className='col-span-2'>
                {asset.entry_type != 'Credit' ? asset?.entry_amount : 0} {`${t('SAR')}`}
              </div>

              <div className='col-span-2'>
                {asset.entry_type === 'Credit' ? asset?.entry_amount : 0} {`${t('SAR')}`}
              </div>

              <div className='col-span-2 balance'>
                {/* {(asset.transaction_total_debit)+(-(asset.transaction_total_credit))}{" "} */}
                {runningBalance.toFixed(2)} {`${t('SAR')}`}
              </div>

              {/* <div className="col-span-4">
              {asset.account_description}
            </div> */}
            </div>
          );
        })}
        {/* Totals */}
        <div className='grid grid-cols-12 items-center py-1 border-t bg-gray-100'>
          <div className='col-span-1 px-1.5'></div>
          <div className='col-span-1'></div>
          <div className='col-span-2 font-semibold text-black/70 text-lg'>{t('Total')}</div>
          <div className='col-span-2'></div>
          <div className='col-span-2 font-semibold text-black/60 text-lg'>
            {totals.totalDebit} {`${t('SAR')}`}
          </div>
          <div className='col-span-2 font-semibold text-black/60 text-lg'>
            {totals.totalCredit} {`${t('SAR')}`}
          </div>
          <div className='col-span-2 font-semibold text-black/60 text-lg'>
            {totals.netTotal} {`${t('SAR')}`}
          </div>
        </div>
      </div>
    </>
  );
}
