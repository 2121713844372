import { Sidebar } from 'primereact/sidebar';
import React from 'react';
import SharedButton from '../Shared/SharedButton';
import { useTranslation } from 'react-i18next';

const FilterSidebar = ({ visible, setVisible, filterDate, setFilterDate, handleUpdateFilters, loading }) => {
  const { t } = useTranslation();
  return (
    <div className='mt-6'>
      <Sidebar
        visible={visible}
        position='right'
        header={t('filter')}
        onHide={() => setVisible(false)}
        pt={{
          header: { className: '!justify-between text-[20px] font-[500]' },
          root: { className: 'w-full sm:w-[350px] mt-[100px] h-[100vh] mt-[100px] md:mt-[170px] xl:mt-[100px]' }
        }}
      >
        <div className='flex justify-between flex-col'>
          <div className='flex flex-col gap-3'>
            <div className='my-2'>
              <label htmlFor='start'>{t('StartDate')}</label>
              <input
                id='start'
                type='date'
                className='w-full border rounded-md !p-1.5'
                value={filterDate?.start_date}
                onChange={e => {
                  setFilterDate(prevDates => ({
                    ...prevDates,
                    start_date: e.target.value
                  }));
                }}
              />
            </div>
            <div className='my-2'>
              <label htmlFor='end'>{t('EndDate')}</label>
              <input
                id='end'
                type='date'
                className='w-full border rounded-md !p-1.5'
                value={filterDate?.end_date}
                onChange={e => {
                  setFilterDate(prevDates => ({
                    ...prevDates,
                    end_date: e.target.value
                  }));
                }}
              />
            </div>
          </div>
          <div className='mt-8'>
            <SharedButton
              disabled={loading}
              label={t('submit')}
              className='bg-gradient w-full'
              onClick={handleUpdateFilters}
            />
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default FilterSidebar;
