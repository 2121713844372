import React, { useEffect, useState } from 'react';
import SharedTooltip from '../components/Shared/SharedTooltip';
import { useTranslation } from 'react-i18next';
import { HiOutlinePrinter } from 'react-icons/hi2';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCreditInvoicesList, printCreditInvoice } from '../store/slices/InvoicesSlice';
import { formatDate } from '../Pages/Transactions/TransactionsTable';
import CustomPagination from '../components/Shared/CustomPagination';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import RecieveSalePayment from './RecieveSalePayment';
import TableLoading from '../components/Shared/TableLoading';
import CustomTable from '../components/Shared/CustomTable';

function CreditTable() {
  const { creditInvoiceList, isLoading } = useSelector(state => state.invoice);
  const [receivePaymentDialogVisible, setReceivePaymentDialogVisible] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handlePrintInvoice = async id => {
    try {
      const resultAction = await dispatch(printCreditInvoice(id));
      if (printCreditInvoice.fulfilled.match(resultAction)) {
        navigate('/manager/show-invoice');
      } else if (printCreditInvoice.rejected.match(resultAction)) {
        console.error('Failed to print invoice:', resultAction.error);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  const templateBody = rowData => (
    <div className='flex gap-1'>
      <SharedTooltip content='print' id='print' />
      <SharedTooltip content='payment' id='payment' />
      <HiOutlinePrinter
        size={25}
        data-tooltip-id='print'
        className='!p-1 !text-black cursor-pointer'
        onClick={() => {
          handlePrintInvoice(rowData?.id);
        }}
      />
      {rowData.status !== 'Paid' && (
        <LiaFileInvoiceDollarSolid
          size={25}
          data-tooltip-id='payment'
          className='table-icon'
          onClick={() => {
            setSelectedEntry(rowData);
            setReceivePaymentDialogVisible(true);
          }}
        />
      )}
    </div>
  );

  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(getCreditInvoicesList({ page: newPage, per_page: newRowsPerPage }));
  };
  useEffect(() => {
    dispatch(getCreditInvoicesList({ page: 1, per_page: 10 }));
  }, []);
  const sequenceBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1;
  };

  const balanceBody = data => {
    return (
      <>
        {data?.patient_share} {t('SAR')}
      </>
    );
  };
  const VatBody = data => {
    return (
      <>
        {data?.patient_tax} {t('SAR')}
      </>
    );
  };
  const invoicesList = [
    {
      data: [{}, {}]
    }
  ];
  const statusBody = data => {
    if (data?.status === 'Paid') {
      return <div className='bg-green-500 !w-[50px] p-1 text-center text-white rounded-md'>{t('Paid')}</div>;
    } else if (data?.status === 'Unpaid') {
      return <div className='bg-red-500 p-1 !w-[50px] text-center text-white rounded-md'>{t('Unpaid')}</div>;
    } else if (data?.status === 'cancel') {
      return <div className='bg-red-500 p-1 !w-[50px] text-center text-white rounded-md'>{t('Cancel')}</div>;
    } else {
      return <div className='bg-red-500 p-1 !w-[50px] text-center text-white rounded-md'>{t('Unpaid')}</div>;
    }
  };

  const columnsData = [
    {
      body: sequenceBodyTemplate,
      header: t('no'),
      style: { width: '3rem' }
    },
    {
      field: 'date',
      header: t('date'),
      body: rowData => formatDate(rowData.date)
    },
    {
      field: 'invoice_number',
      header: t('invoiceNumber')
    },
    {
      field: 'customer_name',
      header: `${t('Customer')} ${t('name')}`
    },
    {
      field: 'patient_share',
      header: t('customer_share'),
      body: balanceBody
    },
    {
      field: 'patient_tax',
      header: t('totalVAT'),
      body: VatBody
    },
    {
      field: 'file_number',
      header: t('fileNumber')
    },
    {
      field: 'nationality',
      header: t('nationality')
    },
    {
      body: statusBody,
      field: 'paid',
      header: t('status')
    },
    {
      field: 'description',
      header: t('description')
    },
    {
      body: templateBody,
      header: t('actions')
    }
  ];

  return (
    <div>
      <div className='flex justify-between items-center flex-col gap-3 sm:gap-0 sm:flex-row mb-3'>
        <SharedTooltip content={t('CREDITINVOICES')} position='left' id='Items' />
        <span data-tooltip-id='Items' className='font-semibold text-black/70 text-xl mt-2'>
          {t('CREDITINVOICES')}
          <span className='italic text-lg ml-1'>
            ({creditInvoiceList?.data?.length || 0}
            <span className='text-xs font-bold italic'> {t('Entries')}</span>)
          </span>
        </span>
      </div>
      <div>
        <CustomTable
          data={creditInvoiceList?.data}
          isLoading={isLoading}
          loadingIcon={<TableLoading />}
          columns={columnsData}
          rows={creditInvoiceList?.per_page}
        />
        {creditInvoiceList?.data?.length > 0 && (
          <CustomPagination
            currentPage={creditInvoiceList?.page}
            rowsPerPage={creditInvoiceList?.per_page}
            totalRecords={creditInvoiceList?.total_pages}
            totalRecord={creditInvoiceList?.total_record}
            onPageChange={onPageChange}
          />
        )}
        <RecieveSalePayment
          open={receivePaymentDialogVisible}
          setOpen={setReceivePaymentDialogVisible}
          selectedEntries={selectedEntry ? [selectedEntry] : []}
        />
      </div>
    </div>
  );
}

export default CreditTable;
