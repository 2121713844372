import React, { useState } from 'react';
import { MdOutlineDelete, MdOutlineEdit } from 'react-icons/md';
import SharedTooltip from '../components/Shared/SharedTooltip';
import { useDispatch, useSelector } from 'react-redux';
import CustomPagination from '../components/Shared/CustomPagination';
import { deleteBuisness, GetBusinessesList } from '../store/slices/BusinessSlice';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import BusinessModal from './BusinessModal';
import SharedButton from '../components/Shared/SharedButton';
import { Dialog } from 'primereact/dialog';
import TableLoading from '../components/Shared/TableLoading';
import CustomTable from '../components/Shared/CustomTable';

export default function BusinessTable({ data }) {
  const { isLoading } = useSelector(state => state.business);
  const dispatch = useDispatch();
  const lang = i18next.language;
  const [selectedData, setSelectedData] = useState(null);
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(GetBusinessesList({ page: newPage, per_page: newRowsPerPage }));
  };
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [editData, setEditData] = useState({});
  const { t } = useTranslation();
  const templateBody = rowData => {
    return (
      <div className='flex  gap-1'>
        <SharedTooltip content='view' id='my-tooltip-1' />
        <SharedTooltip id='my-tooltip-2' content='edit' />
        <SharedTooltip id='my-tooltip-3' content='delete' />
        {/* <IoMdEye
          size={25}
          data-tooltip-id="my-tooltip-1"
          className="table-icon"
        /> */}
        <MdOutlineEdit
          size={25}
          data-tooltip-id='my-tooltip-2'
          className='table-icon'
          onClick={() => {
            setEditData(rowData);
            setCreateDialogVisible(true);
          }}
        />
        <MdOutlineDelete
          size={25}
          data-tooltip-id='my-tooltip-3'
          className='table-icon'
          onClick={() => {
            setConfirmationDialogVisible(true);
            setSelectedData(rowData);
          }}
        />
      </div>
    );
  };
  const sequenceBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1 + (data?.page - 1) * data?.per_page;
  };

  const columnsData = [
    {
      body: sequenceBodyTemplate,
      header: t('no'),
      style: { width: '3rem' }
    },
    {
      field: lang === 'ar' ? 'name_ar' : 'name_en',
      header: t('name')
    },
    {
      field: 'vat_no',
      header: t('vatNo')
    },
    {
      field: 'cr_no',
      header: t('crNo')
    },
    {
      field: 'contact_info',
      header: t('contact')
    },
    {
      body: templateBody,
      header: t('actions')
    }
  ];

  return (
    <>
      <CustomTable
        data={data?.data}
        isLoading={isLoading}
        loadingIcon={<TableLoading />}
        columns={columnsData}
        rows={data?.per_page}
      />
      <Dialog
        header={t('confirmation')}
        visible={confirmationDialogVisible}
        onHide={() => {
          setConfirmationDialogVisible(false);
        }}
        pt={{
          root: { className: 'w-full lg:w-[550px] mx-4 overflow-auto' },
          header: { className: '!p-2 !bg-darkBg !text-white' },
          content: { className: '!p-3' },
          closeButton: {
            className: 'hover:!bg-black/20 !outline-none !shadow-none'
          },
          closeButtonIcon: { className: '!text-white' }
        }}
      >
        <div>
          <p>Do you really want to Delete</p>
        </div>

        <div className='flex justify-end gap-6 mt-6'>
          <SharedButton
            icon='pi pi-times'
            label={t('Cancel')}
            className='cancel-btn'
            onClick={() => {
              setConfirmationDialogVisible(false);
            }}
          />
          <SharedButton
            type='submit'
            icon='pi pi-check'
            label={t('Submit')}
            className='confirm-btn'
            onClick={() => {
              setConfirmationDialogVisible(false);

              if (selectedData) {
                dispatch(deleteBuisness({ id: selectedData?.id })).then(() => {
                  dispatch(GetBusinessesList({ page: 1, per_page: 10 }));
                });
              }
            }}
          />
        </div>
      </Dialog>
      <BusinessModal
        setCreateDialogVisible={setCreateDialogVisible}
        createDialogVisible={createDialogVisible}
        dispatch={dispatch}
        editData={editData}
      />
      {data?.data?.length > 0 && (
        <CustomPagination
          currentPage={data?.page}
          rowsPerPage={data?.per_page}
          totalRecords={data?.total_pages}
          totalRecord={data?.total_record}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
}
