import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import AuthSlice from './slices/AuthSlice';
import { composeWithDevTools } from '@redux-devtools/extension';
import AcountSlice from '../store/slices/AcountsSlice';
import InvoicesSlice from './slices/InvoicesSlice';
import ItemSlice from '../store/slices/ItemSlice';
import TransactionsSlice from './slices/TransactionsSlice';
import UsersSlice from './slices/UsersSlice';
import CustomerSlice from './slices/CustomerSlice';
import ReportSlice from './slices/ReportSlice';
import BusinessSlice from './slices/BusinessSlice';
import EmployeeSlice from './slices/EmployeeSlice';
import PayslipSlice from './slices/PayslipSlice';
import HrSlice from './slices/HrSlice';
import PurchaseSlice from './slices/PurchaseSlice';
import CostCenterSlice from './slices/CostCenterSlice';
import VoucherSlice from './slices/VoucherSlice';
import InvoiceSettingsSlice from './slices/InvoiceSettingsSlice';
const persistConfig = {
  key: 'root-super-git',
  storage
};

const allReducers = combineReducers({
  auth: AuthSlice,
  acount: AcountSlice,
  invoice: InvoicesSlice,
  transaction: TransactionsSlice,
  item: ItemSlice,
  user: UsersSlice,
  customers: CustomerSlice,
  reports: ReportSlice,
  business: BusinessSlice,
  employee: EmployeeSlice,
  payslip: PayslipSlice,
  hr: HrSlice,
  purchase: PurchaseSlice,
  costCenter: CostCenterSlice,
  voucher: VoucherSlice,
  invoiceSettings: InvoiceSettingsSlice
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    state = undefined;
  }
  return allReducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: composeWithDevTools(),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

const persistor = persistStore(store);

export { store, persistor };
