import React from 'react';
import { Password } from 'primereact/password';
import { useField } from 'formik';

export default function SharedPasswordField({
  placeholder = '',
  label = '',
  name = '',
  className = '',
  isIcon = false,
  icon,
  iconPos = 'left',
  iconClassName = '',
  defaultValue = '',
  type = 'password',
  ...props
}) {
  const [field, meta] = useField(name);

  return (
    <div className='flex flex-col gap-1 w-full'>
      <label htmlFor={name} className='text-base font-medium'>
        {label}
      </label>
      <div className='relative'>
        <Password
          id={name}
          placeholder={placeholder}
          type={type}
          {...field}
          {...props}
          className={`relative w-full rounded-md border-gray-300 hover:border-primary !outline-none !shadow-none ${className} ${
            isIcon && iconPos === 'left' ? '!pl-10' : iconPos === 'right' ? '!pr-10' : null
          } `}
          pt={{
            root: { className: 'border border-gray-300 !w-full !h-full !p-0 !pl-0 !py-0 !pe-0' },
            iconField: { root: { className: 'w-full' } },
            input: {
              className:
                '!p-[0.6rem] !w-full !h-full !border-0 focus:outline-none focus:shadow-none focus:bg-white !pl-[0]'
            },
            panel: { className: 'hidden' },
            showIcon: {
              className: '!top-[7px] !right-[0]'
            },
            hideIcon: {
              className: '!top-[7px] !right-[0]'
            }
          }}
        />
        {isIcon && (
          <div
            className={`absolute top-1/2 transform -translate-y-1/2 ${
              iconPos === 'left' ? 'left-3' : 'left-auto right-3'
            } text-primary ${iconClassName}`}
          >
            {icon}
          </div>
        )}
      </div>
      {meta.error && meta.touched && <p className='text-sm text-red-500'>{meta.error}</p>}
    </div>
  );
}
