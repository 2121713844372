import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import React, { Fragment, useEffect, useRef, useState, useTransition } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import Hamburger from 'hamburger-react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../store/slices/AuthSlice';
import SharedConfirmDialog from '../../../components/Shared/SharedConfirmDialog';
import { GoGlobe } from 'react-icons/go';
import { useTranslation } from 'react-i18next';
import { AiOutlineBranches } from 'react-icons/ai';

import {
  FaMoneyBill,
  FaMoneyCheck,
  FaBoxOpen,
  FaRegUserCircle,
  FaRegBuilding,
  FaStoreAlt,
  FaMoneyCheckAlt,
  FaRegChartBar
} from 'react-icons/fa';
import { MdOutlineLibraryBooks } from 'react-icons/md';
import { PiInvoice } from 'react-icons/pi';
import { TbAdjustmentsHorizontal, TbReportAnalytics } from 'react-icons/tb';
import { LuScrollText } from 'react-icons/lu';
import { BiPurchaseTagAlt, BiVerticalCenter } from 'react-icons/bi';
import { MdOutlinePayment } from 'react-icons/md';
import { IoTicketOutline } from 'react-icons/io5';

export let WHerToLogin;

export default function Navbar() {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const navSubMenuRefs = useRef([]);
  const profileMenu = useRef();
  const navMenu = useRef();
  const dropdownRef = useRef(null);
  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const { user } = useSelector(state => state.auth);
  const [logoutConfirm, setLogoutConfirm] = useState(false);
  const [showList, setShowList] = useState(false);
  const [language, setLanguage] = useState(
    localStorage.getItem('language') || i18n.language === 'ar' ? 'العربية' : 'English' || 'English'
  );
  function changeLanguage(lng) {
    setLanguage(lng);
    if (lng === 'العربية') {
      i18n.changeLanguage('ar');
      localStorage.setItem('language', 'العربية');
    }
    if (lng === 'English') {
      i18n.changeLanguage('en');
      localStorage.setItem('language', 'English');
    }
  }

  useOutsideClickHandler(dropdownRef, () => setShowList(false));
  function useOutsideClickHandler(ref, callback) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      }

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, callback]);
  }

  const menu = [
    {
      icon: <FaRegChartBar size={15} className='mr-1.5 text-white ' />,
      label: t('chartofaccount'),
      path: '/manager/accounts',
      command: () => {
        WHerToLogin = '/manager/accounts';
        navigate('/manager/accounts');
      }
    },
    {
      icon: <MdOutlineLibraryBooks size={15} className='mr-1.5 text-white ' />,
      label: t('journalentries'),
      path: '/manager/transactions',
      command: () => {
        WHerToLogin = '/manager/transactions';
        navigate('/manager/transactions');
      }
    },
    {
      icon: <PiInvoice size={17} className='mr-1.5 mb-[1px] text-white ' />,
      label: t('invoices'),
      path: '/manager/invoices',
      items: [
        {
          icon: <FaMoneyBill size={16} className='mr-2.5 text-white' />,
          label: t('salesinvoice'),

          path: '/manager/invoices',
          command: () => {
            WHerToLogin = '/manager/invoices';
            navigate('/manager/invoices');
          }
        },
        {
          icon: <FaMoneyCheck size={16} className='mr-2.5 text-white' />,

          label: t('company Invoice'),
          path: '/manager/insuranceInvoice',
          command: () => {
            WHerToLogin = '/manager/insuranceInvoice';
            navigate('/manager/insuranceInvoice');
          }
        },
        {
          icon: <FaMoneyCheck size={16} className='mr-2.5 text-white' />,

          label: t('creditinvoice'),
          path: '/manager/creditInvoiceTable',
          command: () => {
            WHerToLogin = '/manager/creditInvoiceTable';
            navigate('/manager/creditInvoiceTable');
          }
        },
        {
          icon: <FaMoneyCheck size={16} className='mr-2.5 text-white' />,

          label: t('credit_company_invoice'),
          path: '/manager/creditCompanyInvoiceTable',
          command: () => {
            WHerToLogin = '/manager/creditCompanyInvoiceTable';
            navigate('/manager/creditCompanyInvoiceTable');
          }
        }
      ]
    },
    {
      icon: <TbAdjustmentsHorizontal size={15} className='mr-1.5 text-white ' />,
      label: t('configuration'),
      path: '/configration',
      items: [
        {
          icon: <FaBoxOpen size={16} className='mr-2.5 text-white' />,

          label: t('items'),
          path: '/manager/items',
          command: () => {
            WHerToLogin = '/manager/items';
            navigate('/manager/items');
          }
        },
        {
          icon: <FaRegUserCircle size={16} className='mr-2.5 text-white' />,

          label: t('customers'),
          path: '/manager/customers',
          command: () => {
            WHerToLogin = '/manager/customers';
            navigate('/manager/customers');
          }
        },
        {
          icon: <FaRegBuilding size={16} className='mr-2.5 text-white' />,

          label: t('company'),
          path: '/manager/business',
          command: () => {
            WHerToLogin = '/manager/business';
            navigate('/manager/business');
          }
        },
        {
          icon: <AiOutlineBranches size={16} className='mr-2.5 text-white' />,

          label: t('branches'),
          path: '/manager/branch',
          command: () => {
            WHerToLogin = '/manager/branch';
            navigate('/manager/branch');
          }
        },

        {
          icon: <BiVerticalCenter size={16} className='mr-2.5 text-white' />,

          label: t('costCenters'),
          path: '/manager/costCenter',
          command: () => {
            WHerToLogin = '/manager/costCenter';
            navigate('/manager/costCenter');
          }
        }
      ]
    },
    {
      icon: <LuScrollText size={15} className='mr-1.5 text-white ' />,
      label: t('Payroll'),
      path: '/manager/payroll',
      command: () => {
        WHerToLogin = '/manager/payroll';
        navigate('/manager/payroll');
      }
    },
    {
      icon: <BiPurchaseTagAlt size={15} className='mr-1.5 text-white ' />,
      label: t('purchase'),
      path: '/purchase',
      items: [
        {
          icon: <FaStoreAlt size={16} className='mr-2.5 text-white' />,

          label: t('vendor'),
          path: '/manager/vendor',
          command: () => {
            WHerToLogin = '/manager/vendor';
            navigate('/manager/vendor');
          }
        },
        {
          icon: <FaMoneyCheckAlt size={16} className='mr-2 text-white' />,

          label: t('purchased_entry'),
          path: '/manager/purchased_entry',
          command: () => {
            WHerToLogin = '/manager/purchased_entry';
            navigate('/manager/purchased_entry');
          }
        }
      ]
    },
    {
      icon: <IoTicketOutline size={17} className='mr-1.5 mb-[1px] text-white ' />,
      label: t('voucher'),
      path: '/voucher',
      items: [
        {
          icon: <MdOutlinePayment size={16} className='mr-2.5 text-white ' />,

          label: t('payment_voucher'),
          path: '/manager/payment/voucher',
          command: () => {
            WHerToLogin = '/manager/payment/voucher';
            navigate('/manager/payment/voucher');
          }
        },
        {
          icon: <FaMoneyCheckAlt size={16} className='mr-2 text-white ' />,

          label: t('receipt_voucher'),
          path: '/manager/receipt/voucher',
          command: () => {
            WHerToLogin = '/manager/receipt/voucher';
            navigate('/manager/receipt/voucher');
          }
        }
      ]
    },
    {
      icon: <TbReportAnalytics size={17} className='mr-1.5 mb-[1px] text-white ' />,
      label: t('reports'),
      path: '/manager/report',
      command: () => {
        WHerToLogin = '/manager/report';
        navigate('/manager/report');
      }
    }
  ];

  // --topBar tabs underbar
  function isActivePath(item) {
    if (location.pathname.startsWith(item.path)) {
      return true;
    }
    if (item.items) {
      return item.items.some(subItem => location.pathname.startsWith(subItem.path));
    }
    return false;
  }

  const startContent = (
    <Fragment>
      <div className='flex gap-14 z-[9999999] items-center'>
        <div className='py-1.5'>
          <img
            src='/images/supergit-02.png'
            className='w-10 lg:w-12 cursor-pointer'
            onClick={() => navigate('/manager/dashboard')}
          />
        </div>
        <div className='hidden md:flex gap-6 items-center'>
          {menu?.map((item, i) => (
            <div
              key={i}
              className='text-sm font-semibold text-white flex items-center relative cursor-pointer py-2 px-1 group hover:bg-gradient hover:rounded'
              onClick={e => {
                item?.items?.length > 0 ? navSubMenuRefs.current[i].toggle(e) : navigate(item?.path);
                WHerToLogin = item?.path;
              }}
            >
              {item.icon && <span className='flex items-center'>{item.icon}</span>}
              <span className='uppercase'>{item?.label}</span>
              {item?.items?.length > 0 && (
                <>
                  <span className='text-white text-2xl ml-0.5'>
                    <MdArrowDropDown />
                  </span>
                  <Menu
                    model={item?.items}
                    popup
                    ref={el => (navSubMenuRefs.current[i] = el)}
                    id={`popup_nav_menu_${i}`}
                    popupAlignment='right'
                    className='!bg-darkBg text-white border-none custom-menu !z-[99999]'
                    pt={{
                      label: { className: `text-white whitespace-nowrap ${i18n.language === 'ar' ? 'ms-2' : ''}` },
                      content: { className: '!bg-darkBg hover:bg-gradient' },
                      root: { className: ' mt-3.5 ml-10 ' }
                    }}
                    style={{ width: '17rem' }}
                  />
                </>
              )}
              {/* </div> */}
              <div
                className={classNames(
                  'absolute mt-[51px] left-0 w-full',
                  isActivePath(item) ? 'h-1.5 w-full bg-gradient rounded-lg' : 'h-1.5 '
                )}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );

  let profileItems = [
    {
      command: () => {},
      template: (item, options) => {
        return (
          <button
            onClick={e => options.onClick(e)}
            className={classNames(
              options.className,
              'w-full p-link flex items-center py-2 px-3 text-color hover:bg-gradient rounded-none'
            )}
          >
            <Avatar
              label={user?.full_name ? user?.full_name.charAt(0) : 'U'}
              className='mr-2 text-black'
              shape='circle'
            />
            <div className='flex flex-col align'>
              <span className='font-bold text-ellipsis overflow-hidden whitespace-nowrap ... w-32'>
                {user?.full_name}
              </span>
              <span className='text-sm'>Manager</span>
            </div>
          </button>
        );
      }
    },
    {
      separator: true
    },
    {
      label: t('Settings'),
      icon: 'pi pi-cog',
      path: '/manager/settings',
      command: () => {
        navigate('/manager/settings');
      }
    },
    {
      label: t('Logout'),
      icon: 'pi pi-sign-out',
      command: () => {
        setLogoutConfirm(true);
      }
    }
  ];

  const endContent = (
    <Fragment>
      <div className='flex gap-6 '>
        <div
          className='block md:hidden'
          onClick={e => {
            setNavMenuOpen(!navMenuOpen);
            navMenu?.current?.toggle(e);
          }}
        >
          <Hamburger toggled={navMenuOpen} color='#ffffff' />
        </div>
        <div className='flex items-center justify-start gap-10 '>
          <div
            className={`flex items-center transition-all justify-center min-h-[35px] relative px-2 rounded-lg text-[#ffffff] gap-2 cursor-pointer hover:bg-gradient ${
              showList ? 'bg-orange-400' : null
            }`}
            onClick={() => setShowList(!showList)}
          >
            <GoGlobe size={17} />
            <p className='text-[14px]'>{language}</p>
          </div>
          <div
            ref={dropdownRef}
            className={`absolute bottom-[-80px] flex flex-col gap-1 w-36 py-1 transition-all bg-white rounded-lg shadow-lg ${
              showList ? 'flex' : 'hidden'
            }`}
          >
            <div className='flex cursor-pointer hover:bg-gray-100 py-2 px-3'>
              <p
                onClick={() => {
                  changeLanguage('English');
                  setShowList(false);
                }}
              >
                <span
                  alt={'us'}
                  // src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
                  className={`mr-3 flag flag-us !w-[24px] !h-[17px]`}
                />
                English
              </p>
            </div>
            <div className='flex cursor-pointer hover:bg-gray-100 py-2 px-3'>
              <p
                onClick={() => {
                  changeLanguage('العربية');
                  setShowList(false);
                }}
              >
                <span
                  alt={'ar'}
                  // src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
                  className={`mr-3 flag flag-sa !w-[24px] !h-[17px]`}
                />
                العربية
              </p>
            </div>
          </div>
        </div>
        <div onClick={e => profileMenu?.current?.toggle(e)} className='flex gap-3 cursor-pointer items-center'>
          <Avatar
            label={user?.full_name ? user?.full_name.charAt(0) : 'S'}
            shape='circle'
            size='large'
            pt={{ root: { className: 'hover:bg-gradient hover:text-white' } }}
          />
        </div>
      </div>

      <Menu
        model={profileItems}
        popup
        ref={profileMenu}
        id='popup_profile_menu'
        className='!bg-darkBg text-white border-none custom-menu'
        pt={{
          label: { className: 'text-white' },
          content: { className: '!bg-darkBg hover:bg-gradient' },
          icon: { className: 'text-white' }
        }}
        style={{ width: '13rem' }}
      />

      <Menu
        model={menu}
        popup
        ref={navMenu}
        id='popup_nav_menu'
        className='!bg-darkBg text-white border-none'
        pt={{
          label: { className: 'text-white' },
          content: { className: '!bg-darkBg hover:bg-gradient' },
          icon: { className: 'text-white' }
        }}
        onHide={() => setNavMenuOpen(false)}
      />
    </Fragment>
  );

  return (
    <>
      <Toolbar
        start={startContent}
        end={endContent}
        className='sticky top-0 z-[999] !bg-darkBg border-none rounded-none !py-0 !px-8'
      />
      <SharedConfirmDialog
        visible={logoutConfirm}
        setVisible={setLogoutConfirm}
        message='Doyouwanttologout?'
        rejectLabel='No'
        acceptLabel='Yes'
        onAccept={() => {
          localStorage.clear();
          dispatch(logout());
          navigate('/login');
        }}
        onReject={() => setLogoutConfirm(false)}
      />
    </>
  );
}
