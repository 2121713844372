import React, { useEffect, useState } from 'react';
import SharedTooltip from '../../components/Shared/SharedTooltip';
import SharedButton from '../../components/Shared/SharedButton';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPayrunList } from '../../store/slices/PayslipSlice';
import PayrunTable from './PayrunTable';
import CreatePayrun from './CreatePayrun';
import CreatePayroll from './CreatePayroll';

export default function PayrunMain() {
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [createDialogVisiblee, setCreateDialogVisiblee] = useState(false);
  const dispatch = useDispatch();
  const { payrunList } = useSelector(state => state.payslip);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getPayrunList({ page: 1, per_page: 10 }));
  }, []);

  return (
    <>
      <div>
        <div className='flex justify-between flex-col gap-3 sm:gap-0 sm:flex-row mb-3'>
          <SharedTooltip content={t('Keep_track_of_employees_payruns')} position='left' id='Items' />
          <span data-tooltip-id='Items' className='font-semibold text-black/70 text-xl mt-2 '>
            {t('PAYRUNS')}
            {/* <span className="italic text-lg ml-1">
              ( 
                {payrunList?.total_record}
              <span className="text-xs font-bold italic"> {t("Entries")}</span>)
            </span> */}
          </span>

          <div className='flex gap-4'>
            <SharedButton
              label={t('Create Payroll')}
              icon='pi pi-plus'
              onClick={() => setCreateDialogVisiblee(true)}
              className='bg-gradient primary-btn'
            />

            <SharedButton
              label={t('GeneratePayrun')}
              icon='pi pi-plus'
              onClick={() => setCreateDialogVisible(true)}
              className='bg-gradient primary-btn'
            />
          </div>
        </div>
        <CreatePayroll setCreateDialogVisiblee={setCreateDialogVisiblee} createDialogVisiblee={createDialogVisiblee} />
        <CreatePayrun setCreateDialogVisible={setCreateDialogVisible} createDialogVisible={createDialogVisible} />
        <PayrunTable data={payrunList} />
      </div>
    </>
  );
}
