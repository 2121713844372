import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';

import { getBranches, GetBusinessesList } from '../../store/slices/BusinessSlice';
import i18next from 'i18next';
import SharedButton from '../../components/Shared/SharedButton';
import { AutoComplete } from 'primereact/autocomplete';
import { fetchData } from '../../helpers/fetchData';

const CompanyInvoiceFilters = ({
  filters,
  setFilters,
  handleAddFilters,
  filterLoading,
  handleResetFilters,
  filteredInvoices
}) => {
  const { t } = useTranslation();
  const lang = i18next.language;
  const dispatch = useDispatch();
  const { branches } = useSelector(state => state.business);
  const { businessList } = useSelector(state => state.business);
  const [customers, setCustomers] = useState([]);

  const listBranchvalues = branches?.data
    ? Object.entries(branches?.data).map(([id, branch]) => ({
        value: Number(branch?.id),
        label: lang === 'ar' ? branch.name_ar : branch.name_en
      }))
    : [];

  const listCompaniesvalues = businessList?.data
    ? Object.entries(businessList?.data).map(([id, company]) => ({
        value: Number(company?.license_id),
        label: lang === 'ar' ? company.name_ar : company.name_en
      }))
    : [];

  const payment_method_values = [
    { value: 'cash', label: lang === 'ar' ? 'نقدي' : 'Cash' },
    { value: 'bank', label: lang === 'ar' ? 'بنك' : 'Bank' },
    { value: 'mada', label: lang === 'ar' ? 'مدى' : 'MADA' },
    { value: 'cheque', label: lang === 'ar' ? 'يفحص' : 'Cheque' }
  ];

  useEffect(() => {
    dispatch(GetBusinessesList({ page: 1, per_page: 100 }));
    dispatch(getBranches());
  }, []);

  const handleComplete = event => {
    fetchCustomers(event.query);
  };

  const fetchCustomers = async query => {
    let payload = { query };
    const data = await fetchData('POST', 'searchCustomer', payload);
    if (data) {
      const listAccountsValues = data?.data
        ? Object.entries(data?.data).map(([id, customer]) => ({
            value: Number(customer?.id),
            label: customer?.name + ' ' + customer?.secondary_contact_number
          }))
        : [];
      setCustomers(listAccountsValues);
    }
  };

  return (
    <div>
      <div className='flex flex-col md:flex-row md:items-center mb-3 gap-3'>
        <div>
          <label className='text-base font-medium'>{t('StartDate')}</label>
          <input
            id='start'
            type='date'
            className='w-full border rounded-md !p-1.5'
            value={filters?.start_date}
            onChange={e => {
              setFilters(prevValues => ({
                ...prevValues,
                start_date: e.target.value
              }));
            }}
          />
        </div>
        <div>
          <label className='text-base font-medium'>{t('EndDate')}</label>
          <input
            id='end'
            type='date'
            className='w-full border rounded-md !p-1.5'
            value={filters?.end_date}
            onChange={e => {
              setFilters(prevValues => ({
                ...prevValues,
                end_date: e.target.value
              }));
            }}
          />
        </div>
        <div>
          <label className='text-base font-medium'>{t('payment_method')}</label>
          <Dropdown
            value={filters?.payment_method}
            options={payment_method_values}
            onChange={e => {
              setFilters(prevDates => ({
                ...prevDates,
                payment_method: e.target.value
              }));
            }}
            optionLabel='label'
            placeholder={t('payment_method')}
            className={`!w-full !p-0 !border-gray-300 rounded-md hover:!border-primary !outline-none !shadow-none`}
            pt={{
              input: { className: '!w-full !p-1.5' },
              list: { className: '!py-0' },
              dropdownicon: { className: 'h-[13px] w-[13px]' }
            }}
          />
        </div>
        <div>
          <label className='text-base font-medium'>{t('select_company')}</label>
          <Dropdown
            value={filters?.company_id}
            options={listCompaniesvalues}
            onChange={e => {
              setFilters(prevDates => ({
                ...prevDates,
                company_id: e.target.value
              }));
            }}
            optionLabel='label'
            placeholder={t('select_company')}
            className={`!w-full !p-0 !border-gray-300 rounded-md hover:!border-primary !outline-none !shadow-none`}
            pt={{
              input: { className: '!w-full !p-1.5' },
              list: { className: '!py-0' },
              dropdownicon: { className: 'h-[13px] w-[13px]' }
            }}
          />
        </div>
        <div>
          <label className='text-base font-medium'>{t('select_branch')}</label>
          <Dropdown
            value={filters?.branch_id}
            options={listBranchvalues}
            onChange={e => {
              setFilters(prevDates => ({
                ...prevDates,
                branch_id: e.target.value
              }));
            }}
            optionLabel='label'
            placeholder={`${t('select')} ${t('branch')}`}
            className={`!w-full !p-0 !border-gray-300 rounded-md hover:!border-primary !outline-none !shadow-none`}
            pt={{
              input: { className: '!w-full !p-1.5' },
              list: { className: '!py-0' },
              dropdownicon: { className: 'h-[13px] w-[13px]' }
            }}
          />
        </div>
        <div>
          <label className='text-base font-medium block'>{t('select_customer')}</label>
          <AutoComplete
            field='label'
            placeholder={t('select_customer')}
            value={filters?.customer_id}
            onChange={e => {
              setFilters(prevDates => ({
                ...prevDates,
                customer_id: e.target.value
              }));
            }}
            suggestions={customers}
            completeMethod={handleComplete}
            forceSelection
            pt={{
              input: {
                root: {
                  className:
                    'w-full p-0 border-gray-300 rounded-md hover:border-gray-500 !outline-none !shadow-none !p-1.5'
                }
              },
              panel: { className: '!shadow-lg rounded-[8px] mt-2 pb-2 overflow-hidden' },
              loadingIcon: { className: 'absolute top-[15px] right-[12px]' }
            }}
          />
        </div>
        <div className='mt-[20px]'>
          <SharedButton
            disabled={filterLoading}
            label={t('apply_filters')}
            className='bg-gradient w-full primary-btn whitespace-nowrap'
            onClick={handleAddFilters}
          />
        </div>
        <div className='mt-[20px]'>
          <SharedButton
            disabled={filterLoading}
            label={t('reset')}
            className='cancel-btn'
            onClick={handleResetFilters}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyInvoiceFilters;
