import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FaMoneyBill } from 'react-icons/fa';

import { fetchData } from '../../helpers/fetchData';
import { updateInvoiceSettings } from '../../store/slices/InvoiceSettingsSlice';
import SettingsTable from './SettingsTable';

const SettingsList = () => {
  const { t } = useTranslation();
  const lang = i18next.language;
  const dispatch = useDispatch();
  const { invoiceSettings, isLoading } = useSelector(state => state?.invoiceSettings);
  const [accountSuggestion, setAccountSuggestions] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [defaultSettings, setDefaultSettings] = useState({
    coa_accounts: [],
    invoice_settings: [],
    payroll_account: []
  });
  const isAllowEdit = invoiceSettings?.coaCheck?.value == 'true';

  const settingsTables = [
    { title: 'chart_Of_accounts', dataKey: 'coa_accounts', settingsData: defaultSettings.coa_accounts },
    { title: 'invoices', dataKey: 'invoice_settings', settingsData: defaultSettings.invoice_settings },
    { title: 'payroll_accounts', dataKey: 'payroll_account', settingsData: defaultSettings.payroll_account }
  ];

  useEffect(() => {
    if (invoiceSettings?.data) {
      const formatOptions = data =>
        data.map(item => ({
          ...item,
          account: { label: item?.account_name, value: item?.value }
        }));

      setDefaultSettings({
        coa_accounts: formatOptions(invoiceSettings?.data?.coa_account || []),
        invoice_settings: formatOptions(invoiceSettings?.data?.invoice_accounts || []),
        payroll_account: formatOptions(invoiceSettings?.data?.payroll_account || [])
      });
    }
  }, [invoiceSettings]);

  const handleSearchAccounts = event => {
    fetchAccountOptions(event.query);
  };

  const fetchAccountOptions = async query => {
    const payload = { query };
    const data = await fetchData('POST', 'searchAllAccounts', payload);
    if (data) {
      const accounts = data?.data
        ? Object.entries(data.data).map(([id, account]) => ({
            value: Number(account?.code),
            label: lang === 'ar' ? `${account.name_ar} ${account?.code}` : `${account.name_en} ${account?.code}`
          }))
        : [];
      setAccountSuggestions(accounts);
    }
  };

  const handleFieldChange = (key, index, fieldName, value) => {
    if (fieldName === 'is_vat') handleUpdateSettings(key, index, fieldName, value);
    if (!value && fieldName != 'is_vat') return false;
    setDefaultSettings(prev => {
      const updatedArray = [...prev[key]];
      updatedArray[index] = { ...updatedArray[index], [fieldName]: value };
      return { ...prev, [key]: updatedArray };
    });
  };

  const handleUpdateSettings = (key, index, fieldName, value) => {
    const selectedObj = defaultSettings[key][index];
    if (fieldName === 'is_vat') {
      selectedObj.is_vat = String(value);
    } else {
      selectedObj.value = String(value?.value);
      selectedObj.account_name = value.label;
      selectedObj.is_vat = String(selectedObj?.is_vat);
    }
    if (selectedObj) {
      dispatch(updateInvoiceSettings({ id: selectedObj.id, data: selectedObj }));
    }
  };

  return (
    <div className='flex flex-col rounded-md bg-gray-100 px-6 py-4 w-full max-h-[calc(100vh-100px)] overflow-auto'>
      <Accordion activeIndex={activeIndex} onTabChange={e => setActiveIndex(e.index)}>
        {settingsTables.map((table, index) => (
          <AccordionTab
            key={index}
            header={
              <div className='flex items-center'>
                <div className='flex-shrink-0 p-1.5 bg-gray-100 rounded-lg text-white mr-3'>
                  <FaMoneyBill size={20} color='#f5793b' />
                </div>
                <h3 className='text-xl font-semibold text-gray-700 capitalize'>{t(table?.title)}</h3>
              </div>
            }
            pt={{
              header: { className: `${activeIndex == index ? 'bg-gray-200 mt-4' : 'bg-white mt-4'}` },
              headeraction: { className: 'shadow-none focus:shadow-none bg-transparent' }
            }}
          >
            <SettingsTable
              title={table.title}
              dataKey={table.dataKey}
              settingsData={table.settingsData}
              accountSuggestion={accountSuggestion}
              isLoading={isLoading}
              isAllowEdit={isAllowEdit}
              onFieldChange={handleFieldChange}
              onSearchAccounts={handleSearchAccounts}
              onUpdateSettings={handleUpdateSettings}
            />
          </AccordionTab>
        ))}
      </Accordion>
    </div>
  );
};

export default SettingsList;
