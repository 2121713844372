import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SharedTooltip from '../../components/Shared/SharedTooltip';
import { formatTimestamp } from '../../components/Shared/SharedMethods';
import {
  filterCompanyInvoiceData,
  printCompanyInvoice,
  setInitialCompaniesfilter
} from '../../store/slices/InvoicesSlice';
import CustomPagination from '../../components/Shared/CustomPagination';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../Transactions/TransactionsTable';
import { HiOutlinePrinter } from 'react-icons/hi2';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import RecievePaymentCompanyInvoice from './RecievePaymentCompanyInvoice';
import TableLoading from '../../components/Shared/TableLoading';
import CustomTable from '../../components/Shared/CustomTable';

export default function CompanyInvoiceTable({
  companyInvoicesList,
  filters,
  setFilters,
  setFilteredInvoices,
  setFiltersDetails,
  SetFiltersLoading
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [receivePaymentDialogVisible, setReceivePaymentDialogVisible] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const { isLoading } = useSelector(state => state.invoice);

  const datebody = rowData => {
    return formatTimestamp(rowData.date);
  };

  const onPageChange = async (newPage, newRowsPerPage) => {
    let payload = {
      ...filters,
      page: newPage,
      per_page: newRowsPerPage
    };

    SetFiltersLoading(true);
    const data = await dispatch(filterCompanyInvoiceData(payload));
    SetFiltersLoading(false);

    if (data) {
      let filteredInvoicesData = data?.payload?.data ? data?.payload : [];
      setFiltersDetails(data?.payload);
      setFilteredInvoices(filteredInvoicesData);
      dispatch(setInitialCompaniesfilter(payload));
    }
    setFilters(prev => ({
      ...prev,
      page: newPage,
      per_page: newRowsPerPage
    }));
  };

  const handlePrintInvoice = async id => {
    try {
      const resultAction = await dispatch(printCompanyInvoice(id));
      if (printCompanyInvoice.fulfilled.match(resultAction)) {
        navigate('/manager/show-company-invoice');
      } else if (printCompanyInvoice.rejected.match(resultAction)) {
        // Handle any errors here
        console.error('Failed to print company invoice:', resultAction.error);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const templateBody = rowData => (
    <div className='flex gap-1'>
      <SharedTooltip content='print' id='print' />
      <SharedTooltip content='payment' id='payment' />
      <SharedTooltip content='cancel' id='cancel' />
      <HiOutlinePrinter
        size={25}
        data-tooltip-id='print'
        className='!p-1 !text-black cursor-pointer'
        onClick={() => {
          handlePrintInvoice(rowData?.id);
        }}
      />

      {rowData.status !== 'Paid' && (
        <LiaFileInvoiceDollarSolid
          size={25}
          data-tooltip-id='payment'
          className='table-icon'
          onClick={() => {
            setSelectedEntry(rowData);
            setReceivePaymentDialogVisible(true);
          }}
        />
      )}
    </div>
  );

  const sequenceBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1 + (companyInvoicesList?.page - 1) * companyInvoicesList?.per_page;
  };
  const statusBody = data => {
    if (data?.status === 'Paid') {
      return <div className='bg-green-500 !w-[50px] p-1 text-center text-white rounded-md'>{t('Paid')}</div>;
    } else if (data?.status === 'Unpaid') {
      return <div className='bg-red-500 p-1 !w-[50px] text-center text-white rounded-md'>{t('Unpaid')}</div>;
    } else if (data?.status === 'cancel') {
      return <div className='bg-red-500 p-1 !w-[50px] text-center text-white rounded-md'>{t('Cancel')}</div>;
    } else {
      return <div className='bg-red-500 p-1 !w-[50px] text-center text-white rounded-md'>{t('Unpaid')}</div>;
    }
  };

  const balanceBody = (data, column) => {
    let fieldName = column?.field;
    if (fieldName == 'grass_amount')
      return (
        <>
          {data?.discount_amount + data?.taxable_amount} {t('SAR')}
        </>
      );
    return (
      <>
        {data[fieldName]} {t('SAR')}
      </>
    );
  };

  const columnsData = [
    {
      body: sequenceBodyTemplate,
      header: t('no'),
      style: { width: '3rem' }
    },
    {
      field: 'date',
      header: t('date'),
      body: rowData => formatDate(rowData.date)
    },
    {
      field: 'invoice_number',
      header: t('invoiceNumber')
    },
    {
      field: 'file_number',
      header: t('fileNumber')
    },
    {
      field: 'customer_name',
      header: `${t('Customer')} ${t('name')}`
    },
    {
      field: 'grass_amount',
      header: t('grass_amount'),
      body: balanceBody
    },
    {
      field: 'sub_total_with_tax',
      header: t('subtotal_with_tax'),
      body: balanceBody
    },
    {
      field: 'sub_total_without_tax',
      header: t('subtotal_without_tax'),
      body: balanceBody
    },
    {
      field: 'total_vat',
      header: t('total_tax'),
      body: balanceBody
    },
    {
      field: 'discount_amount',
      header: t('total_discount'),
      body: balanceBody
    },
    {
      field: 'total',
      header: t('total_amount'),
      body: balanceBody
    },
    {
      field: 'payment_method',
      header: t('payment_method')
    },
    {
      field: 'nationality',
      header: t('nationality')
    },
    {
      body: statusBody,
      field: 'paid',
      header: t('status')
    },
    {
      field: 'description',
      header: t('description')
    },
    {
      body: templateBody,
      header: t('actions')
    }
  ];

  return (
    <>
      <CustomTable
        isLoading={isLoading}
        loadingIcon={<TableLoading />}
        data={companyInvoicesList?.data}
        columns={columnsData}
        rows={companyInvoicesList?.per_page}
        rootyStyles='max-h-[50vh] overflow-auto'
      />
      {companyInvoicesList?.data?.length > 0 && (
        <CustomPagination
          currentPage={companyInvoicesList?.page}
          rowsPerPage={companyInvoicesList?.per_page}
          totalRecords={companyInvoicesList?.total_pages}
          totalRecord={companyInvoicesList?.total_record}
          onPageChange={onPageChange}
        />
      )}

      <RecievePaymentCompanyInvoice
        open={receivePaymentDialogVisible}
        setOpen={setReceivePaymentDialogVisible}
        selectedEntries={selectedEntry ? [selectedEntry] : []}
        filtersPayload={filters}
        setFilteredInvoices={setFilteredInvoices}
      />
    </>
  );
}
