import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import { MdOutlineEdit, MdOutlineRemoveRedEye } from 'react-icons/md';
import { CiTrash } from 'react-icons/ci';
import SharedTooltip from '../../components/Shared/SharedTooltip';
import {
  deleteTransactions,
  getTransactionEntries,
  getTransactions,
  searchDatedTransaction,
  searchTransaction
} from '../../store/slices/TransactionsSlice';
import CustomPagination from '../../components/Shared/CustomPagination';
import TableLoading from '../../components/Shared/TableLoading';
import CustomTable from '../../components/Shared/CustomTable';
import ConfirmationDialog from '../../components/Shared/ConfirmationDialog';

export const formatDate = dateString => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = String(hours % 12 || 12).padStart(2, '0');

  return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
};

export default function TransactionsTable({ searchQuery, dates, loading }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = i18next.language;
  const { t } = useTranslation();
  const { transactions, page, per_page, total_pages, isLoading, total_record } = useSelector(
    state => state.transaction
  );
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
  const [transId, setTransId] = useState();
  const [selectedTransactionEntries, setSelectedTransactionEntries] = useState([]);

  const templateBody = rowData => (
    <div className='flex gap-1'>
      <SharedTooltip content={t('view')} id='my-tooltip-1' />
      <SharedTooltip id='my-tooltip-2' content='edit' />
      <SharedTooltip id='my-tooltip-3' content='delete' />
      <MdOutlineRemoveRedEye
        size={25}
        data-tooltip-id='my-tooltip-1'
        className='table-icon'
        onClick={() => {
          dispatch(getTransactionEntries(rowData?.id)).then(response => {
            setSelectedTransactionEntries(response?.payload?.data);
            setCreateDialogVisible(true);
          });
        }}
      />
      <MdOutlineEdit
        size={25}
        data-tooltip-id='my-tooltip-2'
        className='table-icon'
        onClick={() => handleEdit(rowData)}
      />
      <CiTrash
        size={25}
        data-tooltip-id='my-tooltip-3'
        className='table-icon'
        onClick={() => {
          setTransId(rowData?.id);
          setConfirmationDialogVisible(true);
        }}
      />
    </div>
  );
  const handleEdit = rowData => {
    navigate('/manager/transactions/create', {
      state: { transactionData: rowData }
    });
  };

  const onPageChange = (newPage, newRowsPerPage) => {
    if (searchQuery.length > 3) {
      dispatch(searchTransaction({ page: newPage, per_page: newRowsPerPage }));
      if (dates) {
        dispatch(
          searchDatedTransaction({
            ...dates,
            page: newPage,
            per_page: newRowsPerPage
          })
        );
      }
    } else {
      dispatch(getTransactions({ page: newPage, per_page: newRowsPerPage }));
    }
  };

  const sequenceBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1 + (page - 1) * per_page;
  };

  const balanceBody = data => {
    return (
      <>
        {data?.total_debit} {t('SAR')}
      </>
    );
  };
  const balanceBodyy = data => {
    return (
      <>
        {data?.total_credit} {t('SAR')}
      </>
    );
  };

  const transactionTableColumns = [
    {
      body: sequenceBodyTemplate,
      header: t('no'),
      style: { width: '3rem' }
    },
    {
      field: 'date',
      header: t('transactionDate'),
      body: rowData => formatDate(rowData.date)
    },
    {
      field: 'transaction_number',
      header: t('transaction_number')
    },
    {
      field: 'created_by',
      header: t('created_by')
    },
    {
      field: 'total_debit',
      body: balanceBody,
      header: t('total_debit')
    },
    {
      field: 'total_credit',
      body: balanceBodyy,
      header: t('total_credit')
    },
    {
      field: 'description',
      header: t('description')
    },
    {
      body: templateBody,
      header: t('actions')
    }
  ];

  const viewTansationColumns = [
    {
      field: lang === 'en' ? `account.name_en` : `account.name_ar`,
      header: t('AccountName')
    },
    {
      field: 'amount',
      header: t('debitAmount'),
      body: rowData => (rowData?.type === 'Debit' ? rowData?.amount : 0)
    },
    {
      field: 'payment_method',
      header: t('creditAmount'),
      body: rowData => (rowData?.type === 'Credit' ? rowData?.amount : 0)
    }
  ];

  const handleDeleteTransaction = () => {
    dispatch(deleteTransactions({ transaction_id: transId })).then(() => {
      dispatch(getTransactions({ page: 1, per_page: 10 }));
    });
    setConfirmationDialogVisible(false);
    setTransId(null);
  };

  return (
    <div>
      <CustomTable
        data={transactions}
        isLoading={loading}
        loadingIcon={<TableLoading />}
        columns={transactionTableColumns}
        rows={per_page}
      />
      <Dialog
        header={t('Entries')}
        visible={createDialogVisible}
        onHide={() => {
          if (!createDialogVisible) return;
          setCreateDialogVisible(false);
        }}
        pt={{
          root: { className: 'w-full lg:w-[40%] mx-4 overflow-auto' },
          header: { className: '!p-2 !bg-darkBg !text-white' },
          content: { className: '!p-3' },
          closeButton: {
            className: 'hover:!bg-black/20 !outline-none !shadow-none'
          },
          closeButtonIcon: { className: '!text-white' }
        }}
      >
        <CustomTable data={selectedTransactionEntries} columns={viewTansationColumns} rows={per_page} />
      </Dialog>
      <ConfirmationDialog
        title={t('delete_confirmation_popup_text')}
        isDialogVisible={confirmationDialogVisible}
        setIsDialogVisible={setConfirmationDialogVisible}
        confirmHandler={handleDeleteTransaction}
      />
      {transactions?.length > 0 && (
        <CustomPagination
          currentPage={page}
          rowsPerPage={per_page}
          totalRecords={total_pages}
          totalRecord={total_record}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
}
