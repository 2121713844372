import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SharedButton from '../../components/Shared/SharedButton';
import CreateAccount from './CreateAccount';
import SharedTooltip from '../../components/Shared/SharedTooltip';
import AccountsTable from './AccountsTable';
import { useTranslation } from 'react-i18next';
import { getAccounts, setCuurentAccountTab } from '../../store/slices/AcountsSlice';
import SearchInput from '../../components/Shared/Search';

export default function AccountsMain() {
  const dispatch = useDispatch();
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const { t } = useTranslation();

  const { accounts, isLoading, activeAccountsTab } = useSelector(state => state.acount);
  const [activeTab, setActiveTab] = useState(activeAccountsTab ?? 'Asset');

  useEffect(() => {
    dispatch(getAccounts({ account_type: activeTab }));
  }, [dispatch, activeTab]);

  useEffect(() => {
    if (!searchQuery) {
      setFilteredAccounts(accounts || []);
    }
  }, [searchQuery, accounts]);

  const tabList = [
    { label: t('Asset'), value: 'Asset' },
    { label: t('Liability'), value: 'Liability' },
    { label: t('Revenue'), value: 'Revenue' },
    { label: t('Equity'), value: 'Equity' },
    { label: t('Expense'), value: 'Expense' }
  ];

  const handleTabClick = tab => {
    setActiveTab(tab.value);
    dispatch(setCuurentAccountTab(tab.value));
  };

  return (
    <div>
      <div className='flex justify-between items-center flex-col gap-3 sm:gap-0 sm:flex-row mb-3'>
        <SharedTooltip content={t('Keep_track_of_your_accounts')} position='left' id='Items' />
        <span data-tooltip-id='Items' className='font-semibold text-black/70 text-xl mt-2'>
          {t('ACCOUNTS')}
          <span className='italic text-lg ml-1'>
            ({accounts?.length || 0}
            <span className='text-xs font-bold italic'> {t('Entries')}</span>)
          </span>
        </span>
        <div className='flex items-center gap-4'>
          <SearchInput
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            placeholder={t('Search_by_Code_or_name')}
          />
          <SharedButton
            label={t('CreateAccount')}
            icon='pi pi-plus'
            onClick={() => setCreateDialogVisible(true)}
            className='bg-gradient !w-full primary-btn'
          />
        </div>
      </div>

      <div className='yee'>
        <ul className='grid grid-flow-col mb-2 text-center text-gray-500 bg-gray-100 border rounded p-1 shadow-inner'>
          {tabList.map(tab => (
            <li key={tab.value}>
              <a
                className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                  activeTab === tab.value ? 'bg-gradient rounded shadow text-white hover:text-white' : ''
                }`}
                onClick={() => handleTabClick(tab)}
              >
                {tab.label}
              </a>
            </li>
          ))}
        </ul>
        <div className='rounded-md h-[70vh] overflow-auto'>
          <AccountsTable
            loading={isLoading}
            data={filteredAccounts}
            setUpdateData={setUpdateData}
            setCreateDialogVisible={setCreateDialogVisible}
            activeTab={activeTab}
            query={searchQuery}
          />
        </div>
      </div>
      <CreateAccount
        createDialogVisible={createDialogVisible}
        setCreateDialogVisible={setCreateDialogVisible}
        isLoading={isLoading}
        activeTab={activeTab}
        setUpdateData={setUpdateData}
        updateData={updateData}
      />
    </div>
  );
}
