import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getCreditCompanyInvoicesListApi, printCreditCompanyInvoice } from '../store/slices/InvoicesSlice';
import CustomPagination from '../components/Shared/CustomPagination';
import { formatDate } from '../Pages/Transactions/TransactionsTable';
import SharedTooltip from '../components/Shared/SharedTooltip';
import { HiOutlinePrinter } from 'react-icons/hi2';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import ReceiveCompanyPayment from './ReceiveCompanyPayment';
import { useNavigate } from 'react-router-dom';
import TableLoading from '../components/Shared/TableLoading';
import CustomTable from '../components/Shared/CustomTable';

function CrdeitCompanyTable() {
  const { creditCompanyInvoiceList, isLoading } = useSelector(state => state.invoice);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [receivePaymentDialogVisible, setReceivePaymentDialogVisible] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  useEffect(() => {
    dispatch(getCreditCompanyInvoicesListApi({ page: 1, per_page: 10 }));
  }, [dispatch]);

  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(
      getCreditCompanyInvoicesListApi({
        page: newPage,
        per_page: newRowsPerPage
      })
    );
  };
  const navigate = useNavigate();
  const sequenceBodyTemplate = (rowData, options) => options.rowIndex + 1;
  const handlePrintInvoice = async id => {
    try {
      const resultAction = await dispatch(printCreditCompanyInvoice(id));
      if (printCreditCompanyInvoice.fulfilled.match(resultAction)) {
        navigate('/manager/show-company-invoice');
      } else if (printCreditCompanyInvoice.rejected.match(resultAction)) {
        // Handle any errors here
        console.error('Failed to print company invoice:', resultAction.error);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  const templateBody = rowData => (
    <div className='flex gap-1'>
      <SharedTooltip content='print' id='print' />
      <SharedTooltip content='payment' id='payment' />
      <SharedTooltip content='cancel' id='cancel' />

      <HiOutlinePrinter
        size={25}
        data-tooltip-id='print'
        className='!p-1 !text-black cursor-pointer'
        onClick={() => {
          handlePrintInvoice(rowData?.id);
        }}
      />
      {rowData.status !== 'Paid' && (
        <LiaFileInvoiceDollarSolid
          size={25}
          data-tooltip-id='payment'
          className='table-icon'
          onClick={() => {
            setSelectedEntry(rowData);
            setReceivePaymentDialogVisible(true);
          }}
        />
      )}
    </div>
  );

  const statusBody = data => {
    if (data?.status === 'Paid') {
      return <div className='bg-green-500 !w-[50px] p-1 text-center text-white rounded-md'>{t('Paid')}</div>;
    } else if (data?.status === 'Unpaid') {
      return <div className='bg-red-500 p-1 !w-[50px] text-center text-white rounded-md'>{t('Unpaid')}</div>;
    } else if (data?.status === 'cancel') {
      return <div className='bg-red-500 p-1 !w-[50px] text-center text-white rounded-md'>{t('Cancel')}</div>;
    } else {
      return <div className='bg-red-500 p-1 !w-[50px] text-center text-white rounded-md'>{t('Unpaid')}</div>;
    }
  };
  const balanceBody = data => {
    return (
      <>
        {data?.total} {t('SAR')}
      </>
    );
  };

  const columnsData = [
    {
      body: sequenceBodyTemplate,
      header: t('no'),
      style: { width: '3rem' }
    },
    {
      field: 'date',
      header: t('date'),
      body: rowData => formatDate(rowData.date)
    },
    {
      field: 'invoice_number',
      header: t('invoiceNumber')
    },
    {
      field: 'customer_name',
      header: `${t('Customer')} ${t('name')}`
    },
    {
      field: 'sub_total_with_tax',
      header: t('amount'),
      body: balanceBody
    },
    {
      field: 'file_number',
      header: t('fileNumber')
    },
    {
      field: 'nationality',
      header: t('nationality')
    },
    {
      body: statusBody,
      field: 'paid',
      header: t('status')
    },
    {
      field: 'description',
      header: t('description')
    },
    {
      body: templateBody,
      header: t('actions')
    }
  ];

  return (
    <div>
      <div className='flex justify-between items-center mb-3'>
        <h2 className='font-semibold text-black/70 text-xl'>
          {t('credit_company_invoice')}{' '}
          <span className='italic text-lg'>
            ({creditCompanyInvoiceList?.data?.length || 0}{' '}
            <span className='text-xs font-bold italic'>{t('Entries')}</span>)
          </span>
        </h2>
      </div>

      <div>
        <CustomTable
          data={creditCompanyInvoiceList?.data}
          isLoading={isLoading}
          loadingIcon={<TableLoading />}
          columns={columnsData}
          rows={creditCompanyInvoiceList?.per_page}
        />
        <ReceiveCompanyPayment
          open={receivePaymentDialogVisible}
          setOpen={setReceivePaymentDialogVisible}
          selectedEntries={selectedEntry ? [selectedEntry] : []}
        />
        {creditCompanyInvoiceList?.data?.length > 0 && (
          <CustomPagination
            currentPage={creditCompanyInvoiceList?.page}
            rowsPerPage={creditCompanyInvoiceList?.per_page}
            totalRecords={creditCompanyInvoiceList?.total_pages}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </div>
  );
}

export default CrdeitCompanyTable;
