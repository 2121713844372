import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { Menu } from 'primereact/menu';
import VatReportTable from './VatReportTable';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { getPatientsVATList } from '../../store/slices/ReportSlice';
import { getOneMonthAgo } from '../CashInflow/CashInflow';
import SharedButton from '../../components/Shared/SharedButton';

export default function PatientsVat() {
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const menuRef = useRef(null);
  const [startDate, setStartDate] = useState(getOneMonthAgo(endDate));
  const { patientsVatList } = useSelector(state => state.reports);
  const dispatch = useDispatch();
  const lang = i18next.language;

  const balanceSheetRef = useRef(null);
  const iframeRef = useRef(null);

  const handleSubmit = () => {
    if (startDate && endDate) {
      const payload = {
        start_date: startDate,
        end_date: endDate
      };
      dispatch(getPatientsVATList(payload));
    }
  };

  useEffect(() => {
    const payload = {
      start_date: startDate,
      end_date: endDate
    };
    dispatch(getPatientsVATList(payload));
  }, []);

  const { t } = useTranslation();

  const handlePrint = () => {
    const direction = lang == 'ar' ? 'rtl' : 'ltr';
    const printContents = balanceSheetRef.current.innerHTML;
    const iframe = iframeRef.current;
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write('<html><head><title>Balance Sheet</title>');
    doc.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css">');

    doc.write('<style>');
    doc.write(`
      @media print {
        @page {
          margin: 20px 0 20px 0; /* Removes the default margins */
        }
        body {
          margin: 1cm; /* Adjust the margins to your needs */
          direction: ${direction};
        }
        /* Hide browser print headers and footers */
        header, footer {
          display: none;
        }
        table {
          direction: ${direction};
        }
      }
    `);
    doc.write('</style>');

    doc.write('</head><body>');
    doc.write(printContents);
    doc.write('</body></html>');
    doc.close();

    iframe.onload = () => {
      iframe.contentWindow.print();
    };
  };

  const handleExcelExport = () => {
    if (!patientsVatList?.data || patientsVatList.data.length === 0) {
      console.error('No data available for export');
      return;
    }

    const exportData = patientsVatList.data.map(item => ({
      PatientID: item.PatientID,
      PatientName: item.PatientName,
      TotalVAT: item.TotalVAT
    }));
    const totalVAT = exportData.reduce((total, item) => total + item.TotalVAT, 0);
    exportData.push({
      PatientID: 'Total',
      PatientName: '',
      TotalVAT: totalVAT
    });
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const columnWidths = [{ wch: 15 }, { wch: 30 }, { wch: 15 }];
    worksheet['!cols'] = columnWidths;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Patients VAT');
    XLSX.writeFile(workbook, 'Patients_VAT.xlsx');
  };

  const items = [
    {
      label: t('EXCEL'),
      icon: 'pi pi-file-excel',
      command: handleExcelExport
    },
    {
      label: t('PDF'),
      icon: 'pi pi-print',
      command: handlePrint
    }
  ];
  return (
    <div>
      <div className='flex justify-between'>
        <div className='flex gap-5 flex-wrap'>
          <div className='flex items-center gap-2'>
            <label className='text-sm'>{t('StartDate')}</label>
            <input
              required
              type='date'
              name='start_date'
              value={startDate}
              onChange={e => {
                setStartDate(e.target.value);
              }}
              id='dobDate'
              autoComplete='off'
              className='p-[0.33rem] border rounded-md'
            />
          </div>
          <div className='flex items-center gap-2'>
            <label className='text-sm'>{t('EndDate')}</label>
            <input
              required
              type='date'
              name='end_date'
              value={endDate}
              onChange={e => {
                setEndDate(e.target.value);
              }}
              id='dobDate'
              autoComplete='off'
              className='p-[0.33rem] border rounded-md'
            />
          </div>
          <div>
            <SharedButton
              label={t('Submit')}
              className='bg-gradient primary-btn'
              onClick={handleSubmit}
              disabled={!startDate || !endDate}
            />
          </div>
        </div>
        <div className=''>
          <SharedButton
            label={`${t('Export')}`}
            icon='pi pi-download'
            iconPos='right'
            onClick={e => menuRef.current.toggle(e)}
            className='bg-gradient primary-btn'
          />
          <Menu
            pt={{
              root: { className: '!py-0 !bg-none' },
              content: { className: '!p-0 !bg-none !text-gray-500' },
              action: { className: '!py-1.5 !px-2 !bg-none !text-gray-500' },
              menuitem: { className: '!bg-none' },
              menu: { className: '!bg-gray-100' },
              icon: { className: '!text-gray-500' }
            }}
            model={items}
            popup
            ref={menuRef}
          />
        </div>
      </div>

      <div className='this_div' ref={balanceSheetRef}>
        <div>
          <div className='title !my-8'>
            <div className='flex justify-center'>
              <div className='mx-10 !mt-1'>
                <div className='font-semibold text-black/70 text-xl'>{`${t('Patients VAT')}`}</div>
              </div>
            </div>
          </div>

          <div>
            <div pt={{ content: { className: '!py-0' } }}>
              <div>
                <VatReportTable data={patientsVatList?.data} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <iframe ref={iframeRef} style={{ display: 'none' }}></iframe>
    </div>
  );
}
