import React from 'react';
import { InputSwitch } from 'primereact/inputswitch';

const SwitchInput = ({ label, value, handleChange, ...rest }) => {
  return (
    <div>
      <label className='text-base block font-medium mb-2'>{label}</label>
      <InputSwitch
        checked={value}
        onChange={handleChange}
        {...rest}
        pt={{ slider: { className: `${value ? 'bg-primary' : ''}` } }}
      />
    </div>
  );
};

export default SwitchInput;
