import React, { useEffect } from 'react';
import SharedButton from '../../components/Shared/SharedButton';
import { Dialog } from 'primereact/dialog';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { getAllEmployees } from '../../store/slices/EmployeeSlice';
import CustomSearchDropdown from '../../components/Shared/CustomSearchDropdown';
import { CreateEmployeePayslip, getPayslipList } from '../../store/slices/PayslipSlice';
import SharedInputText from '../../components/Shared/SharedInputText';

export default function CreatePayslip({ createDialogVisible, setCreateDialogVisible }) {
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);
  const { isLoading } = useSelector(state => state.payslip);
  const { allEmployees } = useSelector(state => state.employee);
  const { t } = useTranslation();

  const listEmployees = allEmployees?.data
    ? allEmployees.data.map(employee => ({
        id: employee.id,
        name: `${employee.first_name} ${employee.last_name}`
      }))
    : [];

  const initialValues = {
    employee_id: '',
    pay_period: '',

    basic_salary: 0,
    allowances: 0,
    deductions: 0,
    net_salary: 0,
    gosi_contribution: 0,
    payment_date: '',

    branch_id: user?.branch_id,
    business_id: user?.business_id
  };

  const validationSchema = Yup.object({
    employee_id: Yup.number().required(t('required')),
    pay_period: Yup.string().required(t('required')),
    basic_salary: Yup.number().min(0, `${t('basic_salary')}${t('mustBePositive')}`),
    allowances: Yup.number().min(0, `${t('allowances')}${t('mustBePositive')}`),
    deductions: Yup.number().min(0, `${t('deductions')}${t('mustBePositive')}`),
    net_salary: Yup.number().min(0, `${t('net_salary')}${t('mustBePositive')}`),
    gosi_contribution: Yup.number().min(0, `${t('gosi_contribution')}${t('mustBePositive')}`),
    payment_date: Yup.string().required(t('required'))
  });

  return (
    <>
      <Dialog
        header={t('Generate Payslip')}
        visible={createDialogVisible}
        onHide={() => {
          if (!createDialogVisible) return;
          setCreateDialogVisible(false);
        }}
        pt={{
          root: { className: 'w-full lg:w-10/12 mx-4 overflow-auto' },
          header: { className: '!p-2 !bg-darkBg !text-white' },
          content: { className: '!p-3' },
          closeButton: {
            className: 'hover:!bg-black/20 !outline-none !shadow-none'
          },
          closeButtonIcon: { className: '!text-white' }
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (data, { resetForm }) => {
            try {
              const resultAction = await dispatch(CreateEmployeePayslip(data));
              if (CreateEmployeePayslip.fulfilled.match(resultAction)) {
                resetForm();
                setCreateDialogVisible(false);
                dispatch(getPayslipList({ page: 1, per_page: 10 }));
              }
            } catch (error) {
              console.error('Error creating payslip:', error);
            }
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className='grid grid-cols-1 md:grid-cols-4 gap-4 w-full'>
                <CustomSearchDropdown
                  name='employee_id'
                  placeholder={`${t('select')} ${t('employee')}`}
                  options={listEmployees}
                  label={`${t('employee')} ${t('name')}`}
                  className={'!w-full'}
                />

                <SharedInputText
                  type={'date'}
                  label={t('pay_period')}
                  name={'pay_period'}
                  placeholder='Enter pay_period'
                  className='!p-1.5'
                />

                <SharedInputText
                  type={'number'}
                  label={t('basic_salary')}
                  name={'basic_salary'}
                  placeholder={`${t('enter')} ${t('basic_salary')}`}
                  className='!p-1.5'
                />

                <SharedInputText
                  type={'number'}
                  label={t('allowances')}
                  name={'allowances'}
                  placeholder={`${t('enter')} ${t('allowances')}`}
                  className='!p-1.5'
                />

                <SharedInputText
                  type={'number'}
                  label={t('deductions')}
                  name={'deductions'}
                  placeholder={`${t('enter')} ${t('deductions')}`}
                  className='!p-1.5'
                />

                <SharedInputText
                  type={'number'}
                  label={t('net_salary')}
                  name={'net_salary'}
                  placeholder={`${t('enter')} ${t('net_salary')}`}
                  className='!p-1.5'
                />

                <SharedInputText
                  type={'number'}
                  label={t('gosi_contribution')}
                  name={'gosi_contribution'}
                  placeholder={`${t('enter')} ${t('gosi_contribution')}`}
                  className='!p-1.5'
                />

                <SharedInputText
                  type={'date'}
                  label={t('payment_date')}
                  name={'payment_date'}
                  placeholder='Enter payment_date'
                  className='!p-1.5'
                />
              </div>
              <div className='flex justify-end gap-6 mt-6'>
                <SharedButton
                  type='button'
                  icon='pi pi-times'
                  label={t('Cancel')}
                  className='cancel-btn'
                  onClick={() => {
                    setCreateDialogVisible(false);
                  }}
                />
                <SharedButton type='submit' icon='pi pi-check' label={t('Submit')} className='submit-btn' />
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
