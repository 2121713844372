import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import moment from 'moment';
import i18next from 'i18next';

import SharedButton from '../../components/Shared/SharedButton';
import SharedTooltip from '../../components/Shared/SharedTooltip';
import { filterInvoiceData, getSearchedInvoice, setInitialSalesfilter } from '../../store/slices/InvoicesSlice';
import InvoiceTable from './InvoiveTable';
import { debounce } from '../../helpers/debounce';
import InvoiceFilters from './InvoiceFilters';
import FilterDetails from './FilterDetails';
import ExportInvoices from './ExportInvoices';

export default function InvoicesMain() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { invoicesList, isLoading, initialSalesFilters } = useSelector(state => state.invoice);
  const [filterLoading, SetFiltersLoading] = useState(false);
  const [filteredInvoices, setFilteredInvoices] = useState(null);
  const [filtersDetails, setFiltersDetails] = useState(null);
  const currentDate = moment().format('YYYY-MM-DD');
  const firstDayOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD');
  const lang = i18next.language;

  const [filters, setFilters] = useState({
    start_date: firstDayOfCurrentMonth,
    end_date: currentDate,
    branch_id: '',
    customer_id: '',
    customer_name: '',
    invoice_type: 'sales',
    payment_method: '',
    page: 1,
    per_page: 10
  });

  const exportColumnsData = [
    { key: 'no', header: t('no') },
    { key: 'date', header: t('date') },
    { key: 'invoice_number', header: t('invoiceNumber') },
    { key: 'file_number', header: t('fileNumber') },
    { key: 'customer_name', header: `${t('Customer')} ${t('name')}` },
    { key: 'patient_share', header: `${t('Customer')} ${t('amount')}`, type: Number },
    { key: 'discount', header: t('discount'), type: Number },
    { key: 'patient_tax', header: `${t('Customer')} ${t('vat')}`, type: Number },
    { key: 'amount', header: t('total_customer_amount'), type: Number },
    { key: 'company_share', header: t('company_amount'), type: Number },
    { key: 'company_tax', header: t('company_vat'), type: Number },
    { key: 'total_company_amount', header: t('total_company_amount'), type: Number, field: 'company_share' },
    { key: 'payment_method', header: t('payment_method') },
    { key: 'nationality', header: t('nationality') },
    { key: 'paid', header: t('status') },
    { key: 'description', header: t('description') }
  ];

  const translations = {
    en: {
      date: 'Date',
      invoiceNumber: 'Invoice Number',
      fileNumber: 'File Number',
      customerName: 'Customer Name',
      patientShare: 'Customer Amount',
      discount: 'Discount',
      patientTax: 'Customer Vat',
      amount: 'Total Customer Amount',
      companyShare: 'Company Share',
      companyTax: 'Company Tax',
      totalCompanyAmount: 'Total Company Amount',
      paymentMethod: 'Payment Method',
      nationality: 'Nationality',
      status: 'Status',
      description: 'Description'
    },
    ar: {
      date: 'تاريخ',
      invoiceNumber: 'رقم الفاتورة',
      fileNumber: 'رقم الملف',
      customerName: 'اسم العميل',
      patientShare: 'مبلغ العميل',
      discount: 'خصم',
      patientTax: 'ضريبة العميل',
      amount: 'إجمالي مبلغ العميل',
      companyShare: 'حصة الشركة',
      companyTax: 'ضريبة الشركة',
      totalCompanyAmount: 'إجمالي مبلغ الشركة',
      paymentMethod: 'طريقة الدفع',
      nationality: 'الجنسية',
      status: 'الحالة',
      description: 'الوصف'
    }
  };

  const exportintoExcelColumns = data => {
    const keys = translations[lang] || translations.en;
    const type = value => (lang == 'ar' ? Number(value) : String(value));
    return data?.map((item, index) => ({
      [keys.date]: moment(item?.date).format('YYYY-MM-DD HH:mm:ss'),
      [keys.invoiceNumber]: type(item?.invoice_number),
      [keys.fileNumber]: type(item?.file_number),
      [keys.customerName]: item?.customer_name,
      [keys.patientShare]: type(item?.patient_share),
      [keys.patientTax]: type(item?.patient_tax),
      [keys.amount]: type(item?.amount),
      [keys.companyShare]: type(item?.company_share),
      [keys.companyTax]: type(item?.company_tax),
      [keys.totalCompanyAmount]: type(item?.company_share + item?.company_tax),
      [keys.discount]: type(item?.discount),
      [keys.paymentMethod]: item?.payment_method,
      [keys.nationality]: item?.nationality,
      [keys.status]: item?.status,
      [keys.description]: item?.description
    }));
  };

  const handleSearch = event => {
    const value = event?.target?.value;
    debouncedSearch(value);
  };

  useEffect(() => {
    if (initialSalesFilters) setFilters(initialSalesFilters);
    if (!initialSalesFilters?.customer_id) {
      setFilters(prev => ({ ...prev, customer_id: '' }));
    } else {
      setFilters(prev => ({
        ...prev,
        customer_id: { label: initialSalesFilters?.customer_name, value: initialSalesFilters?.customer_id }
      }));
    }
    const payload = initialSalesFilters ? { ...initialSalesFilters } : { ...filters, customer_id: 0 };
    dispatch(filterInvoiceData(payload));
  }, []);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(async value => {
      const trimmedValue = value?.trim();
      setFiltersDetails(null);
      // Handle empty input
      if (!trimmedValue) {
        handleResetFilters();
        return;
      }
      const data = await dispatch(getSearchedInvoice({ query: trimmedValue, page: 1, per_page: 10 }));
      if (data) {
        setFilteredInvoices(data?.payload);
      }
    }, 500),
    []
  );

  const handleAddFilters = async () => {
    if (filters?.start_date && !filters?.end_date) {
      toast.error('Please select an end date');
      return;
    }

    if (filters?.end_date && !filters?.start_date) {
      toast.error('Please select a start date');
      return;
    }
    let payload = {
      ...filters,
      customer_id: filters?.customer_id?.value,
      customer_name: filters?.customer_id?.label,
      page: 1
    };

    SetFiltersLoading(true);
    const data = await dispatch(filterInvoiceData(payload));
    SetFiltersLoading(false);
    if (data) {
      let filteredInvoicesData = data?.payload?.data ? data?.payload : [];
      setFiltersDetails(data?.payload);
      setFilteredInvoices(filteredInvoicesData);
      setFilters(prev => ({ ...prev, page: 1 }));
      dispatch(setInitialSalesfilter(payload));
    }
  };

  const handleResetFilters = () => {
    setFilteredInvoices(null);
    setFiltersDetails(null);
    let payload = {
      start_date: firstDayOfCurrentMonth,
      end_date: currentDate,
      branch_id: '',
      customer_id: '',
      customer_name: '',
      invoice_type: 'sales',
      payment_method: '',
      page: 1,
      per_page: 10
    };
    dispatch(filterInvoiceData(payload));
    dispatch(setInitialSalesfilter(null));
    setFilters(payload);
  };

  return (
    <div>
      <div className='flex justify-between mb-3'>
        <SharedTooltip content={t('Keep_track_of_your_invoices')} position='left' id='Items' />
        <span data-tooltip-id='Items' className='font-semibold text-black/70 text-xl mt-2 '>
          {t('SALESINVOICES')}
          <span className='italic text-lg ml-1'>
            ({invoicesList?.total_record || 0}
            <span className='text-xs font-bold italic'>{t('Entries')} </span>)
          </span>
        </span>

        <div className='flex items-center gap-4'>
          <div className='flex gap-2'>
            <input
              placeholder={t(' Search by invoice number')}
              type='text'
              className='border border-gray-300 rounded-lg px-2.5 py-1.5 w-64 hover:border-primary !outline-none !shadow-none'
              onChange={handleSearch}
            />
          </div>
          <SharedButton
            label={t('CREATESALESINVOICES')}
            icon='pi pi-plus'
            onClick={() => navigate('/manager/invoices/create')}
            className='bg-gradient primary-btn'
          />
          <ExportInvoices
            filters={filters}
            columnsData={exportColumnsData}
            apiUrl='/exportInvoices'
            exportOptionsFormat={exportintoExcelColumns}
            name='Sales Invoices'
          />
        </div>
      </div>
      <InvoiceFilters
        filters={filters}
        setFilters={setFilters}
        filterLoading={filterLoading}
        handleAddFilters={handleAddFilters}
        handleResetFilters={handleResetFilters}
        filteredInvoices={filteredInvoices}
      />
      <InvoiceTable
        invoicesList={filteredInvoices ? filteredInvoices : invoicesList}
        loading={isLoading}
        filters={filters}
        setFilters={setFilters}
        setFilteredInvoices={setFilteredInvoices}
        SetFiltersLoading={SetFiltersLoading}
        setFiltersDetails={setFiltersDetails}
      />
      <FilterDetails filtersDetails={filteredInvoices ? filtersDetails : invoicesList} loading={isLoading} />
    </div>
  );
}
