import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { Formik, FieldArray } from 'formik';
import * as yup from 'yup';
import SharedInputText from '../../components/Shared/SharedInputText';
import SharedButton from '../../components/Shared/SharedButton';
import { useDispatch, useSelector } from 'react-redux';
import SharedDropdown from '../../components/Shared/SharedDropdown';
import { createTransaction, getTransactions } from '../../store/slices/TransactionsSlice';
import { getListAccounts } from '../../store/slices/AcountsSlice';
import { RiDeleteBin5Line } from 'react-icons/ri';
import Loader from '../../Loader';
import CustomSearchDropdown from '../../components/Shared/CustomSearchDropdown';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { getBranches } from '../../store/slices/BusinessSlice';
import { getCostCenter } from '../../store/slices/CostCenterSlice';
import CustomSearchInput from '../../components/Shared/CustomSearchInput';

export const getCurrentDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export default function CreateTransaction({ createDialogVisible, setCreateDialogVisible }) {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { listAccounts } = useSelector(state => state.acount);
  const { isLoading } = useSelector(state => state.transaction);
  const { costCenters } = useSelector(state => state.costCenter);
  const { branches } = useSelector(state => state.business);
  const lang = i18next.language;

  useEffect(() => {
    dispatch(getListAccounts());
    dispatch(getBranches());
    dispatch(getCostCenter());
  }, []);

  // console.log("Accou: ", listAccounts );
  // console.log("branches: ", branches);
  // console.log("costCenters: ", costCenters);

  const listAccountsvalues = listAccounts?.data
    ? Object.entries(listAccounts.data).map(([id, account]) => ({
        value: Number(account?.id),
        label: lang === 'ar' ? account.name_ar : account.name_en
      }))
    : [];

  const listBranchvalues = branches?.data
    ? Object.entries(branches?.data).map(([id, branch]) => ({
        value: Number(branch?.id),
        label: lang === 'ar' ? branch.name_ar : branch.name_en
      }))
    : [];

  const listCostCentervalues = costCenters?.data
    ? Object.entries(costCenters?.data).map(([id, costCenter]) => ({
        value: Number(costCenter?.id),
        label: lang === 'ar' ? costCenter.name_ar : costCenter.name_en
      }))
    : [];

  const initialValues = {
    date: getCurrentDateTime(),
    description: '',
    entry_details: [
      {
        branch_id: '',
        costcenter_id: '',
        account_id: '',
        debit_amount: '',
        credit_amount: '',
        comment: ''
      }
    ]
  };
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    date: yup.string().required(t('required')),
    // description: yup.string().required(t("required")),
    entry_details: yup.array().of(
      yup.object().shape({
        //     costcenter_id: yup.number().required(t("required")),
        //   branch_id: yup.number().required(t("required")),
        // account_id: yup.number().required(t("required")),
        //debit_amount: yup
        // .number()
        //.min(0, `${t("debitAmount")}${t("mustBePositive")}`)
        //.required(t("required")),
        //credit_amount: yup
        // .number()
        //.min(0, `${t("creditAmount")}${t("mustBePositive")}`)
        //.required(t("required")),
        comment: yup.string()
      })
    )
  });
  return (
    <>
      <Dialog
        header={t('CreateJournalEntries')}
        visible={createDialogVisible}
        onHide={() => {
          if (!createDialogVisible) return;
          setCreateDialogVisible(false);
        }}
        pt={{
          root: { className: 'w-full lg:w-10/12 mx-4' },
          header: { className: '!p-2 !bg-darkBg !text-white' },
          content: { className: '!p-3' },
          closeButton: {
            className: 'hover:!bg-black/20 !outline-none !shadow-none'
          },
          closeButtonIcon: { className: '!text-white' }
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (data, { resetForm }) => {
            console.log('Submitting form with data:', data);
            const resultAction = await dispatch(createTransaction(data));
            if (createTransaction.fulfilled.match(resultAction)) {
              setCreateDialogVisible(false);
              resetForm();
              dispatch(getTransactions({ page: 1, per_page: 10 }));
            }
          }}
        >
          {({ values, handleSubmit, resetForm }) => (
            <>
              <div className='grid grid-cols-1 md:grid-cols-4 gap-4 w-full'>
                <SharedInputText
                  type={'datetime-local'}
                  label={t('date')}
                  name={'date'}
                  placeholder='Enter Date'
                  className='!p-1.5'
                />
                <SharedInputText
                  label={t('description')}
                  name={'description'}
                  placeholder={`${t('enter')} ${t('description')}`}
                  className='!p-1.5'
                />
              </div>

              <FieldArray name='entry_details'>
                {({ push, remove }) => (
                  <>
                    {values.entry_details.map((_, index) => (
                      <div key={index} className='grid grid-cols-2 md:grid-cols-7  gap-2 w-full mt-4 border-t py-2'>
                        {/* <div>
                          <CustomSearchDropdown
                            label={`${t("branch")}`}
                            className="!w-full"
                            name={`entry_details.${index}.branch_id`}
                            options={listBranchvalues}
                            placeholder={`${t("select")} ${t("branch")}`}
                          />
                          <div>
                            
                          </div>
                        </div> */}
                        <CustomSearchInput
                          label={t('branch')}
                          className='!w-full'
                          name={`entry_details.${index}.branch_id`}
                          options={listBranchvalues}
                          placeholder={`${t('select')} ${t('branch')}`}
                        />

                        <CustomSearchInput
                          label={`${t('costCenter')}`}
                          className='!w-full'
                          name={`entry_details.${index}.costcenter_id`}
                          options={listCostCentervalues}
                          placeholder={`${t('select')} ${t('costCenter')}`}
                        />
                        <CustomSearchInput
                          label={t('account')}
                          className='!w-full'
                          name={`entry_details.${index}.account_id`}
                          options={listAccountsvalues}
                          placeholder={`${t('select')} ${t('account')}`}
                        />
                        <SharedInputText
                          type={'number'}
                          label={t('debitAmount')}
                          name={`entry_details.${index}.debit_amount`}
                          placeholder={`${t('enter')} ${t('debitAmount')}`}
                          className='!p-1.5'
                          min={0}
                        />

                        <SharedInputText
                          type={'number'}
                          label={t('creditAmount')}
                          name={`entry_details.${index}.credit_amount`}
                          placeholder={`${t('enter')} ${t('creditAmount')}`}
                          className='!p-1.5'
                          min={0}
                        />
                        <SharedInputText
                          label={t('comment')}
                          name={`entry_details.${index}.comment`}
                          placeholder={`${t('enter')} ${t('comment')}`}
                          className='!p-1.5'
                        />

                        {index > 0 && (
                          <>
                            <div className='flex justify-end items-end'>
                              <span className='h-[30px] w-full flex justify-end pr-5'>
                                <RiDeleteBin5Line
                                  size={20}
                                  className='cursor-pointer text-red-500'
                                  onClick={() => remove(index)}
                                />
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                    <SharedButton
                      icon='pi pi-plus'
                      label={t('ADDENTRY')}
                      className='submit-btn mt-4'
                      onClick={() =>
                        push({
                          branch_id: '',
                          costcenter_id: '',
                          account_id: '',
                          debit_amount: '',
                          credit_amount: '',
                          comment: ''
                        })
                      }
                    />
                  </>
                )}
              </FieldArray>

              <div className='flex justify-end gap-6 mt-6'>
                <SharedButton
                  icon='pi pi-times'
                  label={t('Cancel')}
                  className='cancel-btn'
                  onClick={() => {
                    setCreateDialogVisible(false);
                    resetForm();
                  }}
                />
                <SharedButton icon='pi pi-check' label={t('Submit')} className='submit-btn' onClick={handleSubmit} />
              </div>
              {/* {console.log("Form errors:", errors)}
                {console.log("Form touched:", touched)} */}
            </>
          )}
        </Formik>
      </Dialog>
      <Loader showLoader={isLoading} />
    </>
  );
}
