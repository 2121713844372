import { Dialog } from 'primereact/dialog';
import React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SharedInputText from '../components/Shared/SharedInputText';
import SharedButton from '../components/Shared/SharedButton';
import { CreateBusiness, editBuisness, GetBusinessesList } from '../store/slices/BusinessSlice';

export default function CompanyModal({ setCreateDialogVisible, createDialogVisible, dispatch, editData = null }) {
  const { t } = useTranslation();
  const { isLoading } = useSelector(state => state.business);

  const initialValues = {
    company_license: editData?.company_license || '',
    name_en: editData?.name_en || '',
    name_ar: editData?.name_ar || '',
    tpa_license_id: editData?.tpa_license_id || '',
    tpa_name_en: editData?.tpa_name_en || '',
    tpa_name_ar: editData?.tpa_name_ar || '',
    address: editData?.address || '',
    contact_info: editData?.contact_info || '',
    vat_no: editData?.vat_no || '',
    cr_no: editData?.cr_no || ''
  };

  const validationSchema = Yup.object().shape({
    company_license: Yup.string().required(t('required')),
    name_en: Yup.string().required(t('required')),
    name_ar: Yup.string().required(t('required')),
    address: Yup.string()
      .required(t('required'))
      .matches(/^(?!\d+$).+$/, t('invalid_address_format')),
    contact_info: Yup.string().required(t('required')),
    vat_no: Yup.string().required(t('required')),
    cr_no: Yup.string().required(t('required'))
  });

  return (
    <>
      <Dialog
        header={t('CreateCompany')}
        visible={createDialogVisible}
        onHide={() => {
          if (!createDialogVisible) return;
          setCreateDialogVisible(false);
        }}
        pt={{
          root: { className: 'w-full lg:w-10/12 mx-4 overflow-auto' },
          header: { className: '!p-2 !bg-darkBg !text-white' },
          content: { className: '!p-3' },
          closeButton: {
            className: 'hover:!bg-black/20 !outline-none !shadow-none'
          },
          closeButtonIcon: { className: '!text-white' }
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            if (editData) {
              const resultAction = await dispatch(editBuisness({ data: values, id: editData?.id }));
              if (editBuisness.fulfilled.match(resultAction)) {
                dispatch(GetBusinessesList({ page: 1, per_page: 10 }));
                resetForm();
                setCreateDialogVisible(false);
              }
            } else {
              const resultAction = await dispatch(
                CreateBusiness({
                  ...values,
                  license_id: values?.company_license
                })
              );
              if (CreateBusiness.fulfilled.match(resultAction)) {
                dispatch(GetBusinessesList({ page: 1, per_page: 10 }));
                resetForm();
                setCreateDialogVisible(false);
              }
            }
          }}
        >
          {formik => (
            <form onSubmit={formik.handleSubmit}>
              <div className='grid grid-cols-4 gap-4 w-full'>
                <Field
                  as={SharedInputText}
                  label={t('name_en')}
                  name='name_en'
                  placeholder={`${t('enter')} ${t('name_en')}`}
                  className='!p-1.5'
                />
                <Field
                  as={SharedInputText}
                  label={t('name_ar')}
                  name='name_ar'
                  placeholder={`${t('enter')} ${t('name_ar')}`}
                  className='!p-1.5'
                />
                <Field
                  as={SharedInputText}
                  label={t('company_license')}
                  name='company_license'
                  placeholder={`${t('enter')} ${t('company_license')}`}
                  className='!p-1.5'
                />
                <Field
                  as={SharedInputText}
                  label={t('address')}
                  name='address'
                  placeholder={`${t('enter')} ${t('address')}`}
                  className='!p-1.5'
                />
                <Field
                  as={SharedInputText}
                  label={t('contact_info')}
                  name='contact_info'
                  placeholder={t('contact_info_placeholder')}
                  className='!p-1.5'
                />
                <Field
                  as={SharedInputText}
                  label={t('vat_no')}
                  name='vat_no'
                  placeholder={t('vat_no_placeholder')}
                  className='!p-1.5'
                />
                <Field
                  as={SharedInputText}
                  label={t('cr_no')}
                  name='cr_no'
                  placeholder={t('cr_no_placeholder')}
                  className='!p-1.5'
                />
                <Field
                  as={SharedInputText}
                  label={t('tpa_license_id')}
                  name='tpa_license_id'
                  placeholder={`${t('enter')} ${t('tpa_license_id')}`}
                  className='!p-1.5'
                />
                <Field
                  as={SharedInputText}
                  label={`${t('TPA')} ${t('name_en')}`}
                  name='tpa_name_en'
                  placeholder={`${t('enter')} ${t('TPA')} ${t('name_en')}`}
                  className='!p-1.5'
                />
                <Field
                  as={SharedInputText}
                  label={`${t('TPA')} ${t('name_ar')}`}
                  name='tpa_name_ar'
                  placeholder={`${t('enter')} ${t('TPA')} ${t('name_ar')}`}
                  className='!p-1.5'
                />
              </div>

              <div className='flex justify-end gap-6 mt-6'>
                <SharedButton
                  icon='pi pi-times'
                  label={t('Cancel')}
                  className='cancel-btn'
                  onClick={() => {
                    setCreateDialogVisible(false);
                    formik.resetForm();
                  }}
                  type='button'
                />
                <SharedButton
                  icon='pi pi-check'
                  disabled={isLoading}
                  label={editData ? t('Update') : t('Submit')}
                  className='submit-btn'
                  type='submit'
                />
              </div>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
