import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import JournalEntriesTable from './JournalEntriesTable';

const CreateJournalEntries = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dateRef = useRef(Date.now());
  const [transactionId, setTransactionId] = useState(null);
  const transactionData = location.state?.transactionData;
  const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');

  useEffect(() => {
    dateRef.current.value = currentDateTime;
  }, []);

  useEffect(() => {
    if (transactionData) {
      setTransactionId(transactionData?.id);
      dateRef.current.value = transactionData.date || '';
    }
  }, [transactionData]);

  return (
    <div className='rounded-md min-h-[85vh] overflow-hidden bg-gray-100 p-2 px-4 w-full'>
      <div className='flex gap-5 items-center mb-4'>
        <h2 className='text-3xl min-w-[300px] font-bold text-gray-800'>{t('create_journal_entries')}</h2>
        <div className='grid grid-cols-2 gap-4 flex-grow'>
          <div className='flex flex-col gap-1'>
            <label htmlFor='date' className='text-base font-medium'>
              {t('date')}
            </label>
            <InputText
              id='date'
              type='datetime-local'
              ref={dateRef}
              max={currentDateTime}
              className='w-full !p-1 rounded-md hover:border-primary !outline-none !shadow-none'
            />
          </div>
        </div>
      </div>
      <JournalEntriesTable
        date={dateRef}
        transactionId={transactionId}
        transactionDescription={transactionData?.description}
      />
    </div>
  );
};

export default CreateJournalEntries;
