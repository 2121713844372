import React from 'react';
import SharedInputText from '../../components/Shared/SharedInputText';
import SharedDropdown from '../../components/Shared/SharedDropdown';
import AttachmentInput from '../../components/Shared/AttachmentInput';
import { Formik, Form } from 'formik';
import SharedButton from '../../components/Shared/SharedButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { CreateEmployees, getEmployeesList, UpdateEmployees } from '../../store/slices/EmployeeSlice';
import { BiSolidUserDetail } from 'react-icons/bi';
import { BsBank2 } from 'react-icons/bs';
import { FaFileInvoice } from 'react-icons/fa6';
import { GrAttachment } from 'react-icons/gr';

export default function CreateEmployeePage({ editingEmployee, setEditingEmployee }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.employee);

  const departmentOptions = [
    { value: 1, label: 'HR' },
    { value: 2, label: 'Accounts' },
    { value: 3, label: 'Physicians' },
    { value: 4, label: 'Receptionists' }
  ];

  const initialValues = editingEmployee || {
    first_name: '',
    last_name: '',
    national_id: '',
    contact_number: '',
    email: '',
    department_id: '',
    bank_detail: {
      name: '',
      account_no: '',
      iban: ''
    },
    contract: {
      job_title: '',
      salary: '',
      start_date: '',
      end_date: ''
    },
    attachment: {
      file: null
    }
  };

  const supportedFileTypes = [
    'application/pdf', // PDF files
    'application/msword', // DOC files
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX files
    'application/vnd.ms-excel', // XLS files
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // XLSX files
  ];

  const validationSchema = Yup.object({
    first_name: Yup.string().required(t('required')),
    last_name: Yup.string().required(t('required')),
    national_id: Yup.number().typeError(t('Must be a number')).required(t('required')),
    contact_number: Yup.string().required(t('required')),
    email: Yup.string()
      .email(t('Invalid Email'))
      .matches(/.+@.+\..+/, t("Email must contain '@'"))
      .required(t('required')),
    department_id: Yup.number().required(t('required')),
    bank_detail: Yup.object({
      name: Yup.string().required(t('required')),
      account_no: Yup.string().matches(/^\d+$/, t('Must be a number')).required(t('required')),
      iban: Yup.string().required(t('required'))
    }),
    contract: Yup.object({
      job_title: Yup.string().required(t('required')),
      salary: Yup.number().required(t('required')),
      start_date: Yup.date().required(t('required')),
      end_date: Yup.date().required(t('required'))
    }),
    attachment: Yup.object({
      file: Yup.mixed().required(t('required'))
      // .test("fileType", t("Unsupported File Format"), (value) =>
      //   value ? supportedFileTypes.includes(value.type) : false
      // )
      // .test("fileSize", t("File too large"), (value) =>
      //   value ? value.size <= 2.5 * 1024 * 1024 : true
      // ),
    })
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (data, { resetForm }) => {
        try {
          let resultAction;
          if (editingEmployee) {
            resultAction = await dispatch(UpdateEmployees({ ...data, id: editingEmployee.id }));
          } else {
            resultAction = await dispatch(CreateEmployees(data));
          }

          if (CreateEmployees.fulfilled.match(resultAction) || UpdateEmployees.fulfilled.match(resultAction)) {
            resetForm();
            if (setEditingEmployee) {
              setEditingEmployee(null);
            }
            dispatch(getEmployeesList({ page: 1, per_page: 10 }));
            navigate('/manager/payroll');
          }
        } catch (error) {
          console.error('Error creating/updating employee:', error);
        }
      }}
    >
      {({ setFieldValue, errors, touched }) => (
        <Form>
          <div className='flex rounded-md bg-gray-100 px-6 py-4 w-full '>
            <div className='w-full'>
              <h2 className='text-3xl font-bold mb-4 text-gray-800'>
                {editingEmployee ? t('Update Employee') : t('Create Employee')}
              </h2>
              <div className='overflow-y-auto scrollbar max-h-[480px]'>
                <div className='mb-3 rounded-lg border bg-white border-gray-300 px-6 pt-5 pb-1'>
                  <div className='flex items-center mb-4'>
                    <div className='flex-shrink-0 p-1.5 bg-gray-100 rounded-lg text-white mr-3'>
                      <BiSolidUserDetail size={20} color='#f5793b' />
                    </div>
                    <h3 className='text-xl font-semibold text-gray-700'>{t('Personal_Details')}</h3>
                  </div>
                  <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 w-full mb-3'>
                    <SharedInputText
                      label={t('first_name')}
                      name={'first_name'}
                      placeholder={`${t('enter')} ${t('first_name')}`}
                      className='!p-1.5'
                      error={touched.first_name && errors.first_name}
                    />
                    <SharedInputText
                      label={t('last_name')}
                      name={'last_name'}
                      placeholder={`${t('enter')} ${t('last_name')}`}
                      className='!p-1.5'
                    />
                    <SharedInputText
                      label={t('national_id')}
                      name={'national_id'}
                      placeholder={`${t('enter')} ${t('national_id')}`}
                      className='!p-1.5'
                    />
                    <SharedInputText
                      label={t('contact_number')}
                      name={'contact_number'}
                      placeholder={`${t('enter')} ${t('contact_number')}`}
                      className='!p-1.5'
                    />
                    <SharedInputText
                      label={t('email')}
                      name={'email'}
                      placeholder={`${t('enter')} ${t('email')}`}
                      className='!p-1.5'
                    />
                    <SharedDropdown label={t('department_id')} name={'department_id'} options={departmentOptions} />
                  </div>
                  {/* </div>

                <div className="mb-3 rounded-lg border bg-white border-gray-300 p-6"> */}
                  <div className='flex items-center my-4'>
                    <div className='flex-shrink-0 p-1.5 bg-gray-100 rounded-lg text-white mr-3'>
                      <FaFileInvoice size={20} color='#f5793b' />
                    </div>
                    <h3 className='text-xl font-semibold text-gray-700'>{t('Contract_Details')}</h3>
                  </div>
                  <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 w-full mb-3'>
                    <SharedInputText
                      label={t('job_title')}
                      name={'contract.job_title'}
                      placeholder={`${t('enter')} ${t('job_title')}`}
                      className='!p-1.5'
                    />
                    <SharedInputText
                      type={'number'}
                      label={t('salary')}
                      name={'contract.salary'}
                      placeholder={`${t('enter')} ${t('salary')}`}
                      className='!p-1.5'
                    />
                    <SharedInputText
                      type={'date'}
                      label={t('start_date')}
                      name={'contract.start_date'}
                      placeholder={`${t('enter')} ${t('start_date')}`}
                      className='!p-1.5'
                    />
                    <SharedInputText
                      type={'date'}
                      label={t('end_date')}
                      name={'contract.end_date'}
                      placeholder={`${t('enter')} ${t('end_date')}`}
                      className='!p-1.5'
                    />
                  </div>
                </div>

                <div className='flex rounded-lg border bg-white border-gray-300'>
                  <div className='w-1/2 px-6 pt-5 pb-1'>
                    <div className='flex items-center mb-4'>
                      <div className='flex-shrink-0 p-1.5 bg-gray-100 rounded-lg text-white mr-3'>
                        <BsBank2 size={20} color='#f5793b' />
                      </div>
                      <h3 className='text-xl font-semibold text-gray-700'>{t('Bank_Details')}</h3>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 w-full mb-3'>
                      <SharedInputText
                        label={t('bank_name')}
                        name={'bank_detail.name'}
                        placeholder={`${t('enter')} ${t('bank_name')}`}
                        className='!p-1.5'
                      />
                      <SharedInputText
                        label={t('account_no')}
                        name={'bank_detail.account_no'}
                        placeholder={`${t('enter')} ${t('account_no')}`}
                        className='!p-1.5'
                      />
                      <SharedInputText
                        label={t('iban')}
                        name={'bank_detail.iban'}
                        placeholder={`${t('enter')} ${t('iban')}`}
                        className='!p-1.5'
                      />
                    </div>
                  </div>

                  {/* <div className="w-1/2 mb-3 rounded-lg border bg-white border-gray-300 p-6"> */}
                  <div className='w-1/2 px-6 pt-5'>
                    <div className='flex items-center mb-3'>
                      <div className='flex-shrink-0 p-1.5 bg-gray-100 rounded-lg text-white mr-3'>
                        <GrAttachment size={20} color='#f5793b' />
                      </div>
                      <h3 className='text-xl font-semibold text-gray-700'>{t('attachment')}</h3>
                    </div>
                    <AttachmentInput
                      className='hidden'
                      name='attachment'
                      label={t('Upload_Attachment')}
                      onChange={file => setFieldValue('attachment.file', file)}
                      labelName={t('Choose_a_file')}
                      error={touched.attachment?.file && errors.attachment?.file}
                    />
                  </div>
                </div>
              </div>

              <div className='flex justify-end gap-6 mt-4'>
                <SharedButton
                  icon='pi pi-times'
                  label={t('Cancel')}
                  className='cancel-btn'
                  onClick={() => {
                    navigate('/manager/payroll');
                  }}
                />
                <SharedButton
                  type='submit'
                  icon='pi pi-plus'
                  label={editingEmployee ? t('Update Employee') : t('Create Employee')}
                  className='submit-btn'
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
