import { Dialog } from 'primereact/dialog';
import React, { useEffect } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import SharedInputText from '../../components/Shared/SharedInputText';
import SharedButton from '../../components/Shared/SharedButton';
import { useDispatch, useSelector } from 'react-redux';
import { CreateItems, editItem, getItemsList } from '../../store/slices/ItemSlice';
import SharedDropdown from '../../components/Shared/SharedDropdown';
import { useTranslation } from 'react-i18next';
import CustomSearchInput from '../../components/Shared/CustomSearchInput';
import { GetBusinesses } from '../../store/slices/BusinessSlice';
import i18next from 'i18next';

export default function CreateItem(props) {
  const { createDialogVisible, setCreateDialogVisible, isLoading, editData } = props;
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { business } = useSelector(state => state.business);
  const lang = i18next.language;
  const { t } = useTranslation();
  const PayerOptions = payerOptions(business || []);
  const item_type_option = [
    { value: 'Service', label: 'Service' },
    { value: 'Goods', label: 'Goods' }
  ];

  function payerOptions(inputData) {
    return Object.values(inputData || {}).map(item => ({
      value: item?.id,
      label: lang === 'ar' ? item.name_ar : item.name_en
    }));
  }

  useEffect(() => {
    if (createDialogVisible) {
      dispatch(GetBusinesses());
    }
  }, [createDialogVisible]);

  const initialValues = {
    name_en: editData ? editData.name_en : '',
    name_ar: editData ? editData.name_ar : '',
    item_code: editData ? editData.item_code : '',
    item_type: editData ? editData.item_type : '',
    effective_date: editData ? editData.effective_date : '',
    price: editData ? editData.price : '',
    factor: editData ? editData.factor : '',
    tax: editData ? editData.tax : 15,
    discount: editData ? editData.discount : 0,
    non_standard_code: editData ? editData.non_standard_code : '',
    non_standard_description: editData ? editData.non_standard_description : '',
    payer_id: editData ? editData.payer_id : '',
    branch_id: editData ? editData.branch_id : user?.branch_id,
    business_id: editData ? editData.business_id : user?.business_id
  };

  const validationSchema = yup.object().shape({
    name_en: yup.string().required(t('required')),
    name_ar: yup.string().required(t('required')),
    item_code: yup.string().required(t('required')),
    item_type: yup.string().required(t('required')),
    effective_date: yup.string().required(t('required')),
    price: yup.number().required(t('required')).positive(t('required')),
    tax: yup.number().required(t('required')),
    discount: yup.number().required(t('required'))
  });

  return (
    <>
      <Dialog
        header={t('CreateItem')}
        visible={createDialogVisible}
        onHide={() => {
          if (!createDialogVisible) return;
          setCreateDialogVisible(false);
        }}
        pt={{
          root: { className: 'w-full lg:w-10/12 mx-4 overflow-auto' },
          header: { className: '!p-2 !bg-darkBg !text-white' },
          content: { className: '!p-3' },
          closeButton: {
            className: 'hover:!bg-black/20 !outline-none !shadow-none'
          },
          closeButtonIcon: { className: '!text-white' }
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (data, { resetForm }) => {
            try {
              if (editData) {
                const resultAction = await dispatch(editItem({ ...data, ID: editData?.ID }));
                if (editItem.fulfilled.match(resultAction)) {
                  resetForm();
                  setCreateDialogVisible(false);
                  dispatch(getItemsList({ page: 1, per_page: 10 }));
                }
              } else {
                const resultAction = await dispatch(CreateItems(data));
                if (CreateItems.fulfilled.match(resultAction)) {
                  resetForm();
                  setCreateDialogVisible(false);
                  dispatch(getItemsList({ page: 1, per_page: 10 }));
                }
              }
            } catch (error) {
              console.error('Error creating item:', error);
            }
          }}
        >
          {props => {
            return (
              <>
                <div className='grid grid-cols-1 md:grid-cols-4 gap-4 w-full'>
                  <SharedInputText
                    label={t('name_en')}
                    name={'name_en'}
                    placeholder={`${t('enter')} ${t('name_en')}`}
                    className='!p-1.5'
                  />
                  <SharedInputText
                    label={t('name_ar')}
                    name={'name_ar'}
                    placeholder={`${t('enter')} ${t('name_ar')}`}
                    className='!p-1.5'
                  />

                  <SharedInputText
                    label={t('item_code')}
                    name={'item_code'}
                    placeholder={t('item_code_placeholder')}
                    className='!p-1.5'
                  />
                  <CustomSearchInput
                    label={t('payer')}
                    name='payer_id'
                    placeholder={`${t('select')} ${t('payer')}`}
                    options={PayerOptions}
                    className={'!w-full'}
                  />
                  <SharedDropdown label={t('item_type')} name={'item_type'} options={item_type_option} />

                  <SharedInputText
                    type={'date'}
                    label={t('effective_date')}
                    name={'effective_date'}
                    placeholder='Enter effective_date'
                    className='!p-1.5'
                  />

                  <SharedInputText
                    type={'number'}
                    label={t('unitPrice')}
                    name={'price'}
                    placeholder={t('price_placeholder')}
                    className='!p-1.5'
                  />

                  <SharedInputText
                    type={'number'}
                    label={t('factor')}
                    name={'factor'}
                    placeholder={`${t('enter')} ${t('factor')}`}
                    className='!p-1.5'
                  />
                  <SharedInputText
                    type={'number'}
                    label={t('tax')}
                    name={'tax'}
                    placeholder={`${t('enter')} ${t('tax')}`}
                    className='!p-1.5'
                  />

                  <SharedInputText
                    type={'number'}
                    label={t('discount')}
                    name={'discount'}
                    placeholder={`${t('enter')} ${t('discount')}`}
                    className='!p-1.5'
                  />

                  <SharedInputText
                    label={t('non_standard_code')}
                    name={'non_standard_code'}
                    placeholder={`${t('enter')} ${t('non_standard_code')}`}
                    className='!p-1.5'
                  />

                  <SharedInputText
                    label={t('non_standard_description')}
                    name={'non_standard_description'}
                    placeholder={`${t('enter')} ${t('non_standard_description')}`}
                    className='!p-1.5'
                  />
                </div>
                <div className='flex justify-end gap-6 mt-6'>
                  <SharedButton
                    icon='pi pi-times'
                    label={t('Cancel')}
                    className='cancel-btn'
                    onClick={() => {
                      setCreateDialogVisible(false);
                      props.resetForm();
                    }}
                  />
                  <SharedButton
                    icon='pi pi-check'
                    label={editData ? t('Update') : t('Submit')}
                    className='submit-btn'
                    onClick={props.handleSubmit}
                    disabled={isLoading}
                  />
                </div>
              </>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
}
