import React from 'react';

export default function SearchInput({ searchQuery, setSearchQuery, placeholder, className, ...props }) {
  return (
    <input
      type='text'
      className={`border border-gray-300 rounded-lg px-2.5 py-1.5 w-64 max-w-[18rem] hover:border-primary !outline-none !shadow-none ${className}`}
      placeholder={placeholder}
      value={searchQuery}
      onChange={e => setSearchQuery(e.target.value)}
      aria-label={placeholder}
      {...props}
    />
  );
}
