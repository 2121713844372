import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './scss/index.scss';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';
import AuthRoutes from './ProtectedRoutes/AuthRoutes';
import ManagerRoutes from './ProtectedRoutes/ManagerRoutes';
import RootLayout from './layouts/RootLayout/RootLayout';
import { useTranslation } from 'react-i18next';
import Home from './components/Home';
import Login from './Pages/Auth/Login';
import Register from './Pages/Auth/Register';
import AccountsMain from './Pages/Accounts/AccountsMain';
import ItemsMain from './Pages/Items/ItemsMain';
import InvoicesMain from './Pages/Invoice/InvoicesMain';
import CompanyInvoicesMain from './Pages/CompanyInvoice/CompanyInvoicesMain';
import TransactionsMain from './Pages/Transactions/TransactionsMain';
import CustomerMain from './Pages/Customer/CustomerMain';
import UsersMain from './Pages/User/UsersMain';
import ReportMain from './Pages/Report/ReportMain';
import ShowInvoice from './Pages/Invoice/ShowInvoice';
import MainBusiness from '../src/business/MainBusiness';
import ShowCompanyInvoice from './Pages/CompanyInvoice/ShowCompanyInvoice';
import PayslipMain from './Pages/Payslip/PayslipMain';
import PayrollMain from './Pages/Payroll/PayrollMain';
import VendorMain from './Pages/Vendor/VendorMain';
import PurchasedEntryMain from './Pages/PurchasedEntry/PurchasedEntryMain';
import CreateInvoicePage from './Pages/Invoice/CreateInvoicePage';
import CreateCompanyInvoicePage from './Pages/CompanyInvoice/CreateCompanyInvoicePage';
import CreateEmployeePage from './Pages/Employee/CreateEmployeePage';
import BranchMain from './Pages/Branch/BranchMain';
import CostCenterMain from './Pages/CostCenter/CostCenterMain';
import CreateTransactionPage from './Pages/Transactions/CreateTransactionPage';
import CreatePurchasedEntryPage from './Pages/PurchasedEntry/CreatePurchasedEntryPage';
import CreditTable from './CreditInvoice/CreditTable';
import CreditCompanyInvoice from './CreditInvoice/CrdeitCompanyTable';
import PaymentVoucher from './Pages/Voucher/PaymentVoucher';
import ReceiptVoucher from './Pages/Voucher/ReceiptVoucher';
import CreatePaymentVoucher from './Pages/Voucher/CreatePaymentVoucher';
import CreateReceiptVoucher from './Pages/Voucher/CreateReceiptVoucher';
import ExcelTransactionPage from './Pages/Transactions/ExcelTransactionPage';
import CreateJournalEntries from './Pages/Transactions/CreateJournalEntries';
import Settings from './Pages/settings/Settings';
import CreateSettings from './Pages/settings/CreateSettings';

// import LeaveMain from "./Pages/Leave/LeaveMain";
// import AttendanceMain from "./Pages/Attendance/AttendanceMain";
// import DeductionMain from "./Pages/Deduction/DeductionMain";
// import AllowanceMain from "./Pages/Allowance/AllowanceMain";
// import EmployeeMain from "./Pages/Employee/EmployeeMain";

function App() {
  const { i18n } = useTranslation();

  // Set the direction based on the language
  useEffect(() => {
    const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('dir', direction);
    document.body.setAttribute('dir', direction);
  }, [i18n.language]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes>
            <Route element={<AuthRoutes />}>
              <Route path='/login' exact element={<Login />} />
              <Route path='/register' exact element={<Register />} />
            </Route>

            <Route
              path='/manager'
              element={
                <RootLayout>
                  <ManagerRoutes />
                </RootLayout>
              }
            >
              <Route path='dashboard' exact element={<Home />} />
              <Route path='settings' exact element={<Settings />} />
              <Route path='settings/create' exact element={<CreateSettings />} />
              <Route path='user' exact element={<UsersMain />} />
              <Route path='accounts' exact element={<AccountsMain />} />
              <Route path='transactions' exact element={<TransactionsMain />} />
              <Route path='transactions/create' exact element={<CreateJournalEntries />} />
              {/* <Route
                path="transactions/create"
                exact
                element={<CreateTransactionPage />}
              /> */}
              <Route path='transactions/edit' exact element={<CreateTransactionPage />} />
              <Route path='invoices' exact element={<InvoicesMain />} />
              <Route path='invoices/create' exact element={<CreateInvoicePage />} />
              <Route path='customers' exact element={<CustomerMain />} />
              {/* <Route path="payslip" exact element={<PayslipMain />} /> */}
              <Route path='insuranceInvoice' exact element={<CompanyInvoicesMain />} />
              <Route path='creditInvoiceTable' exact element={<CreditTable />} />
              <Route path='creditCompanyInvoiceTable' exact element={<CreditCompanyInvoice />} />
              <Route path='insuranceInvoice/create' exact element={<CreateCompanyInvoicePage />} />
              <Route path='items' exact element={<ItemsMain />} />
              <Route path='report' exact element={<ReportMain />} />
              <Route path='show-invoice' exact element={<ShowInvoice />} />
              <Route path='show-company-invoice' exact element={<ShowCompanyInvoice />} />
              <Route path='business' exact element={<MainBusiness />} />
              <Route path='branch' exact element={<BranchMain />} />
              <Route path='costCenter' exact element={<CostCenterMain />} />
              <Route path='report' exact element={<ReportMain />} />

              {/* <Route path="employees" exact element={<EmployeeMain />} />
              <Route path="leave" exact element={<LeaveMain />} />
              <Route path="attendance" exact element={<AttendanceMain />} />
              <Route path="deduction" exact element={<DeductionMain />} />
              <Route path="allowance" exact element={<AllowanceMain />} /> */}

              <Route path='payroll' exact element={<PayrollMain />} />
              <Route path='payroll/create' exact element={<CreateEmployeePage />} />
              <Route path='vendor' exact element={<VendorMain />} />
              <Route path='purchased_entry' exact element={<PurchasedEntryMain />} />
              <Route path='purchased_entry/create' exact element={<CreatePurchasedEntryPage />} />
              <Route path='receipt/voucher' exact element={<ReceiptVoucher />} />
              <Route path='create/payment/voucher' exact element={<CreatePaymentVoucher />} />
              <Route path='create/receipt/voucher' exact element={<CreateReceiptVoucher />} />
              <Route path='payment/voucher' exact element={<PaymentVoucher />} />
            </Route>

            <Route path='/' element={<Navigate to='/login' />} />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
