import { Dialog } from 'primereact/dialog';
import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import SharedInputText from '../../components/Shared/SharedInputText';
import SharedButton from '../../components/Shared/SharedButton';
import {
  createAccounts,
  getAccounts,
  getListAccounts,
  getSubTypesDropdown,
  getTypesDropdown,
  updateAccount
} from '../../store/slices/AcountsSlice';
import SharedDropdown from '../../components/Shared/SharedDropdown';
import CustomSearchDropdown from '../../components/Shared/CustomSearchDropdown';

export default function CreateAccount(props) {
  const { createDialogVisible, setCreateDialogVisible, activeTab, updateData, setUpdateData } = props;
  const dispatch = useDispatch();
  const lang = i18next.language;
  const formikRef = useRef(null);
  const { t } = useTranslation();
  const { user } = useSelector(state => state.auth);
  const { listAccounts, coaTypes, coaTypesBySubTypes } = useSelector(state => state.acount);
  const { isLoading } = useSelector(state => state.acount);
  const [subTypes, setSubTypes] = useState([]);
  const category_option = [
    { value: 'parent', label: lang === 'ar' ? 'أصل' : 'Parent' },
    { value: 'child', label: lang === 'ar' ? 'طفل' : 'Child' }
  ];

  const listAccountsvalues = listAccounts?.data
    ? Object.entries(listAccounts.data).map(([id, account]) => ({
        id: Number(account?.code),
        name: lang === 'ar' ? account.name_ar : account.name_en,
        name_en: account.name_en,
        name_ar: account.name_ar
      }))
    : [];

  const coaTypesValues = coaTypes?.data
    ? coaTypes.data.map(type => ({
        id: Number(type.id),
        name: lang === 'ar' ? type.name_ar : type.name_en,
        name_en: type.name_en,
        name_ar: type.name_ar
      }))
    : [];

  useEffect(() => {
    if (updateData?.sub_type_en) {
      dispatch(getSubTypesDropdown(coaTypesValues.find(typ => typ?.name_en === updateData?.type_en)?.id));
    }
  }, [updateData]);

  useEffect(() => {
    if (coaTypesBySubTypes) {
      const mappedSubTypes = coaTypesBySubTypes.map(subType => ({
        id: Number(subType.id),
        name: lang === 'ar' ? subType.name_ar : subType.name_en,
        name_en: subType.name_en,
        name_ar: subType.name_ar
      }));
      setSubTypes(mappedSubTypes);
    }
  }, [coaTypesBySubTypes, lang]);

  useEffect(() => {
    if (createDialogVisible) {
      dispatch(getTypesDropdown());
    }
  }, [dispatch, createDialogVisible]);

  const initialValues = {
    name_en: updateData ? updateData?.name_en : '',
    name_ar: updateData ? updateData?.name_ar : '',
    type: updateData ? coaTypesValues.find(typ => typ?.name_en === updateData?.type_en)?.id : '',
    sub_type: updateData ? subTypes?.find(typ => typ?.name_en === updateData?.sub_type_en)?.id : '',
    balance: updateData ? updateData?.balance : 0,
    branch_id: updateData ? updateData?.branch_id : user?.branch_id,
    business_id: updateData ? updateData?.business_id : user?.business_id,
    category: updateData ? updateData?.category : '',
    parent_code: updateData ? updateData?.parent_code : 0,
    description: updateData ? updateData?.description : '',
    type_en: updateData ? updateData?.type_en : '',
    type_ar: updateData ? updateData?.type_ar : '',
    sub_type_en: updateData ? updateData?.sub_type_en : '',
    sub_type_ar: updateData ? updateData?.sub_type_ar : ''
  };

  console.log(updateData);

  const validationSchema = yup.object().shape({
    name_en: yup.string().required(t('name_is_required')),
    name_ar: yup.string().required(t('name_ar_is_required')),
    type: yup.string().required(t('type_is_required')),
    sub_type: yup.string().required(t('sub_type_is_required')),
    balance: yup.number().required(t('balance_is_required')),
    category: yup.string().required(t('category_is_required')),
    parent_code: yup.number()
    // description: yup.string().required(t('description_is_required'))
  });

  const handleSubmit = async (data, { resetForm }) => {
    const typeDetails = coaTypesValues.find(type => type.id === data.type);
    const subTypeDetails = subTypes.find(subType => subType.id === data.sub_type);

    const formData = {
      ...data,
      type_en: typeDetails ? typeDetails.name_en : '',
      type_ar: typeDetails ? typeDetails.name_ar : '',
      sub_type_en: subTypeDetails ? subTypeDetails.name_en : '',
      sub_type_ar: subTypeDetails ? subTypeDetails.name_ar : ''
    };
    if (updateData) {
      const resultAction = await dispatch(updateAccount({ id: updateData?.id, data: formData }));
      if (updateAccount.fulfilled.match(resultAction)) {
        setCreateDialogVisible(false);
        resetForm();
        dispatch(getAccounts({ account_type: activeTab }));
      }
    } else {
      const resultAction = await dispatch(createAccounts(formData));
      if (createAccounts.fulfilled.match(resultAction)) {
        setCreateDialogVisible(false);
        resetForm();
        dispatch(getAccounts({ account_type: activeTab }));
      }
    }
  };

  return (
    <>
      <Dialog
        header={t(updateData ? 'Update Account' : 'CreateAccount')}
        visible={createDialogVisible}
        onHide={() => {
          setCreateDialogVisible(false);
          setUpdateData(null);
        }}
        pt={{
          root: { className: 'w-full lg:w-10/12 mx-4 overflow-auto' },
          header: { className: '!p-2 !bg-darkBg !text-white' },
          content: { className: '!p-3' },
          closeButton: {
            className: 'hover:!bg-black/20 !outline-none !shadow-none'
          },
          closeButtonIcon: { className: '!text-white' }
        }}
      >
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={values => {
            const errors = {};
            if (values.category === 'child' && values.parent_code === 0) {
              errors.parent_code = 'Account is required';
            } else if (values.category === 'parent' && values.parent_code !== 0) {
              errors.parent_code = 'Parent ID must be 0 for parent category';
            }
            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {props => (
            <>
              <div className='grid grid-cols-1 md:grid-cols-4 gap-4 w-full'>
                <SharedInputText label={t('name_en')} name={'name_en'} placeholder={t('name_en')} className='!p-1.5' />
                <SharedInputText label={t('name_ar')} name={'name_ar'} placeholder={t('name_ar')} className='!p-1.5' />

                <SharedDropdown
                  label={t('type')}
                  name='type'
                  options={coaTypesValues}
                  value={props.values.type}
                  onChange={e => {
                    const selectedTypeId = e.value;
                    props.setFieldValue('type', selectedTypeId);
                    dispatch(getSubTypesDropdown(selectedTypeId));
                  }}
                  optionLabel='name'
                  optionValue='id'
                  placeholder={`${t('select')} ${t('type')}`}
                />

                <SharedDropdown
                  label={t('sub_type')}
                  name='sub_type'
                  options={subTypes}
                  value={props.values.sub_type}
                  onChange={e => {
                    props.setFieldValue('sub_type', e.value);
                  }}
                  optionLabel='name'
                  optionValue='id'
                  placeholder={`${t('select')} ${t('subType')}`}
                />

                <SharedInputText
                  type={'number'}
                  // disabled={updateData}
                  label={t('balance')}
                  name={'balance'}
                  placeholder={`${t('enter')} ${t('balance')}`}
                  className='!p-1.5'
                />

                {/* <SharedDropdown
                  label={t("category")}
                  name={"category"}
                  options={category_option}
                  onChange={(e) => {
                    props.handleChange(e);
                    if (e.target.value === "parent") {
                      props.setFieldValue("parent_code", 0);
                    }
                  }}
                /> */}
                <SharedDropdown
                  label={t('category')}
                  name={'category'}
                  options={category_option}
                  disabled={updateData}
                  onChange={e => {
                    props.handleChange(e);
                    if (e.target.value === 'parent') {
                      props.setFieldValue('parent_code', 0);
                    } else if (e.target.value === 'child') {
                      dispatch(getListAccounts());
                    }
                  }}
                />

                {/* {props?.values?.category === "child" && (
                  <CustomSearchDropdown
                    label={t("account")}
                    name={"parent_code"}
                    options={listAccountsvalues}
                    placeholder={`${t("select")}${t("account")}`}
                  />
                )} */}
                {props?.values?.category === 'child' && listAccountsvalues.length > 0 && (
                  <CustomSearchDropdown
                    label={t('account')}
                    name={'parent_code'}
                    disabled={updateData}
                    options={listAccountsvalues}
                    placeholder={`${t('select')}${t('account')}`}
                    className={'!w-full'}
                  />
                )}

                <SharedInputText
                  label={t('description')}
                  name={'description'}
                  placeholder={t('description')}
                  className='!p-1.5'
                />
              </div>
              <div className='flex justify-end gap-6 mt-6'>
                <SharedButton
                  icon='pi pi-times'
                  label={t('Cancel')}
                  className='cancel-btn'
                  onClick={() => {
                    setCreateDialogVisible(false);
                    setUpdateData(null);
                    props.resetForm();
                  }}
                />
                <SharedButton
                  icon='pi pi-check'
                  label={t(updateData ? 'Update' : 'Submit')}
                  className='submit-btn'
                  onClick={props.handleSubmit}
                  disabled={isLoading}
                />
              </div>
            </>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
