import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { PrimeReactProvider } from 'primereact/api';
import { Toaster } from 'react-hot-toast';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Tailwind from 'primereact/passthrough/tailwind';
import EnTransaltions from './locales/en/translation.json';
import ArTransaltions from './locales/ar/translation.json';
import FrTransaltions from './locales/fr/translation.json';
import ErrorBoundary from './components/Shared/ErrorBoundary';
import ErrorBoundaryAlert from './components/Shared/ErrorBoundaryAlert';
import Loader from './Loader';
import App from './App';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    resources: {
      en: { translation: EnTransaltions },
      ar: { translation: ArTransaltions },
      fr: { translation: FrTransaltions }
    },
    supportedLngs: ['en', 'fr', 'ar'],
    fallbackLng: 'en',
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie']
    },
    react: { useSuspense: false }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ErrorBoundary fallback={ErrorBoundaryAlert}>
    <Suspense fallback={<Loader showLoader={true} />}>
      <PrimeReactProvider value={{ pt: Tailwind }}>
        <App />
        <Toaster
          position='top-right'
          reverseOrder={false}
          gutter={8}
          containerClassName=''
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: '',
            duration: 5000,
            style: {
              background: '#363636',
              color: '#fff',
              zIndex: 99999
            },

            // Default options for specific types
            success: {
              duration: 3000,
              theme: {
                primary: 'green',
                secondary: 'black'
              }
            }
          }}
        />
      </PrimeReactProvider>
    </Suspense>
  </ErrorBoundary>
);
