import toast from 'react-hot-toast';
import validSupergitHIS from '../api/validSupergitHIS';

export const fetchData = async (method = 'POST', url, payload = {}) => {
  try {
    // Define a mapping of HTTP methods to the axios functions
    const methods = {
      POST: () => validSupergitHIS.post(url, payload),
      GET: () => validSupergitHIS.get(url),
      PUT: () => validSupergitHIS.put(url, payload),
      DELETE: () => validSupergitHIS.delete(url)
    };

    if (methods[method]) {
      const response = await methods[method]();
      return response?.data;
    } else {
      throw new Error(`Unsupported HTTP method: ${method}`);
    }
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.error || `Failed to fetch data from ${url}`);
    return null;
  }
};
