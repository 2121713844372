import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import i18next from 'i18next';

import SharedButton from '../../components/Shared/SharedButton';
import SharedTooltip from '../../components/Shared/SharedTooltip';
import CompanyInvoiceTable from './CompanyInvoiceTable';
import {
  filterCompanyInvoiceData,
  getSearchedInsuranceInvoice,
  setInitialCompaniesfilter
} from '../../store/slices/InvoicesSlice';
import { debounce } from '../../helpers/debounce';
import CompanyInvoiceFilters from './CompanyInvoiceFilters';
import FilterDetails from '../Invoice/FilterDetails';
import ExportInvoices from '../Invoice/ExportInvoices';

export default function CompanyInvoicesMain() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filteredInvoices, setFilteredInvoices] = useState(null);
  const { isLoading, companyInvoicesList, initialCompnayFilters } = useSelector(state => state.invoice);
  const [filterLoading, SetFiltersLoading] = useState(false);
  const [filtersDetails, setFiltersDetails] = useState(null);
  const currentDate = moment().format('YYYY-MM-DD');
  const firstDayOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD');
  const lang = i18next.language;

  const [filters, setFilters] = useState({
    start_date: firstDayOfCurrentMonth,
    end_date: currentDate,
    branch_id: '',
    customer_id: '',
    company_id: '',
    customer_name: '',
    payment_method: '',
    page: 1,
    per_page: 10
  });

  const exportColumnsData = [
    { key: 'no', header: t('no') },
    { key: 'date', header: t('date') },
    { key: 'invoice_number', header: t('invoiceNumber') },
    { key: 'file_number', header: t('fileNumber') },
    { key: 'customer_name', header: `${t('Customer')} ${t('name')}` },
    { key: 'grass_amount', header: t('grass_amount'), type: Number },
    { key: 'sub_total_with_tax', header: t('sub_total_with_tax'), type: Number },
    { key: 'sub_total_without_tax', header: t('sub_total_without_tax'), type: Number },
    { key: 'total_vat', header: t('total_tax') },
    { key: 'discount_amount', header: t('total_discount'), type: Number },
    { key: 'total', header: t('total_amount'), type: Number },
    { key: 'payment_method', header: t('payment_method') },
    { key: 'nationality', header: t('nationality') },
    { key: 'status', header: t('status') },
    { key: 'description', header: t('description') }
  ];

  const translations = {
    en: {
      date: 'Date',
      invoiceNumber: 'Invoice Number',
      fileNumber: 'File Number',
      customerName: 'Customer Name',
      grassAmount: 'Grass Amount',
      subtotalWithTax: 'Subtatal With Tax',
      subtotalWithoutTax: 'Subtotal Without Tax',
      totalTax: 'Total Tax',
      totalDiscount: 'Total Discount',
      totalAmount: 'Total Amount',
      paymentMethod: 'Payment Method',
      nationality: 'Nationality',
      status: 'Status',
      description: 'Description'
    },
    ar: {
      date: 'التاريخ',
      invoiceNumber: 'رقم الفاتورة',
      fileNumber: 'رقم الملف',
      customerName: 'اسم العميل',
      grassAmount: 'المبلغ الإجمالي',
      subtotalWithTax: 'الإجمالي الفرعي مع الضريبة',
      subtotalWithoutTax: 'الإجمالي الفرعي بدون الضريبة',
      totalTax: 'إجمالي الضريبة',
      totalDiscount: 'إجمالي الخصم',
      totalAmount: 'المبلغ الإجمالي',
      paymentMethod: 'طريقة الدفع',
      nationality: 'الجنسية',
      status: 'الحالة',
      description: 'الوصف'
    }
  };

  const exportintoExcelColumns = data => {
    const keys = translations[lang] || translations.en;
    const type = value => (lang == 'ar' ? Number(value) : String(value));
    return data?.map((item, index) => ({
      [keys.date]: moment(item?.date).format('YYYY-MM-DD HH:mm:ss'),
      [keys.invoiceNumber]: type(item?.invoice_number),
      [keys.fileNumber]: type(item?.file_number),
      [keys.customerName]: item?.customer_name,
      [keys.grassAmount]: type(item?.discount_amount + item?.taxable_amount),
      [keys.subtotalWithTax]: type(item?.sub_total_with_tax),
      [keys.subtotalWithoutTax]: type(item?.sub_total_without_tax),
      [keys.totalAmount]: type(item?.total),
      [keys.totalTax]: type(item?.total_vat),
      [keys.totalDiscount]: type(item?.discount_amount),
      [keys.paymentMethod]: item?.payment_method,
      [keys.nationality]: item?.nationality,
      [keys.status]: item?.status,
      [keys.description]: item?.description
    }));
  };

  const handleSearch = event => {
    const value = event?.target?.value;
    debouncedSearch(value);
  };

  useEffect(() => {
    if (initialCompnayFilters) setFilters(initialCompnayFilters);
    if (!initialCompnayFilters?.customer_id) {
      setFilters(prev => ({ ...prev, customer_id: '' }));
    } else {
      setFilters(prev => ({
        ...prev,
        customer_id: { label: initialCompnayFilters?.customer_name, value: initialCompnayFilters?.customer_id }
      }));
    }
    const payload = initialCompnayFilters ? { ...initialCompnayFilters } : { ...filters, customer_id: 0 };
    dispatch(filterCompanyInvoiceData(payload));
  }, []);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(async value => {
      const trimmedValue = value?.trim();
      setFiltersDetails(null);
      // Handle empty input
      if (!trimmedValue) {
        handleResetFilters();
        return;
      }
      const data = await dispatch(getSearchedInsuranceInvoice({ query: trimmedValue, page: 1, per_page: 10 }));
      if (data) {
        setFilteredInvoices(data?.payload);
      }
    }, 500),
    []
  );

  const handleAddFilters = async () => {
    if (filters?.start_date && !filters?.end_date) {
      toast.error('Please select an end date');
      return;
    }

    if (filters?.end_date && !filters?.start_date) {
      toast.error('Please select a start date');
      return;
    }
    let payload = {
      ...filters,
      customer_id: filters?.customer_id?.value,
      customer_name: filters?.customer_id?.label,
      page: 1
    };
    SetFiltersLoading(true);
    const data = await dispatch(filterCompanyInvoiceData(payload));
    SetFiltersLoading(false);
    if (data) {
      let filteredInvoicesData = data?.payload?.data ? data?.payload : [];
      setFiltersDetails(data?.payload);
      setFilteredInvoices(filteredInvoicesData);
      setFilters(prev => ({ ...prev, page: 1 }));
      dispatch(setInitialCompaniesfilter(payload));
    }
  };

  const handleResetFilters = () => {
    setFilteredInvoices(null);
    setFiltersDetails(null);
    let payload = {
      start_date: firstDayOfCurrentMonth,
      end_date: currentDate,
      branch_id: '',
      customer_id: '',
      customer_name: '',
      company_id: '',
      payment_method: '',
      page: 1,
      per_page: 10
    };
    dispatch(filterCompanyInvoiceData(payload));
    dispatch(setInitialCompaniesfilter(null));
    setFilters(payload);
  };

  return (
    <>
      <div className=''>
        <div className='flex justify-between flex-col gap-3 sm:gap-0 sm:flex-row mb-3'>
          <SharedTooltip content={t('Keep_track_of_your_invoices')} position='left' id='Items' />
          <span data-tooltip-id='Items' className='font-semibold text-black/70 text-xl mt-2'>
            {t('company Invoices')}
            <span className='italic text-lg ml-1'>
              ({companyInvoicesList?.total_record || 0}
              <span className='text-xs font-bold italic'> {t('Entries')}</span>)
            </span>
          </span>
          <div className='flex gap-4'>
            <div className='flex gap-2'>
              <input
                placeholder={t(' Search by invoice number')}
                type='text'
                className='border border-gray-300 rounded-lg px-2.5 py-1.5 w-64 hover:border-primary !outline-none !shadow-none'
                onChange={handleSearch}
              />
            </div>
            <SharedButton
              label={`${t('create')} ${t('company Invoice')}`}
              icon='pi pi-plus'
              onClick={() => navigate('/manager/insuranceInvoice/create')}
              className='bg-gradient primary-btn'
            />
            <ExportInvoices
              filters={filters}
              columnsData={exportColumnsData}
              apiUrl='/exportCompanyInvoices'
              exportOptionsFormat={exportintoExcelColumns}
              name='Company Invoices'
            />
          </div>
        </div>

        <CompanyInvoiceFilters
          filters={filters}
          setFilters={setFilters}
          filterLoading={filterLoading}
          handleAddFilters={handleAddFilters}
          handleResetFilters={handleResetFilters}
          filteredInvoices={filteredInvoices}
        />
        <CompanyInvoiceTable
          companyInvoicesList={filteredInvoices ? filteredInvoices : companyInvoicesList}
          loading={isLoading}
          filters={filters}
          setFilters={setFilters}
          setFilteredInvoices={setFilteredInvoices}
          SetFiltersLoading={SetFiltersLoading}
          setFiltersDetails={setFiltersDetails}
        />
        <FilterDetails filtersDetails={filteredInvoices ? filtersDetails : companyInvoicesList} loading={isLoading} />
      </div>
    </>
  );
}
