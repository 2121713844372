import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SharedButton from '../../components/Shared/SharedButton';
import SharedInputText from '../../components/Shared/SharedInputText';
import { CreateNewVendor, getVendorList } from '../../store/slices/PurchaseSlice';

export default function CreateVendor({ createDialogVisible, setCreateDialogVisible }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isLoading } = useSelector(state => state.purchase);

  const initialValues = {
    name: '',
    email: '',
    phone: '',
    address: '',
    vat_no: '',
    cr_no: ''
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t('required')),
    email: Yup.string().required(t('required')),
    address: Yup.string()
      .required(t('required'))
      .matches(/^(?!\d+$).+$/, t('invalid_address_format')),
    phone: Yup.string().required(t('required')),
    vat_no: Yup.string().required(t('required')),
    cr_no: Yup.string().required(t('required'))
  });

  return (
    <>
      <Dialog
        header={t('CreateVendor')}
        visible={createDialogVisible}
        onHide={() => {
          if (!createDialogVisible) return;
          setCreateDialogVisible(false);
        }}
        pt={{
          root: { className: 'w-full lg:w-10/12 mx-4 overflow-auto' },
          header: { className: '!p-2 !bg-darkBg !text-white' },
          content: { className: '!p-3' },
          closeButton: {
            className: 'hover:!bg-black/20 !outline-none !shadow-none'
          },
          closeButtonIcon: { className: '!text-white' }
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (data, { resetForm }) => {
            try {
              const resultAction = await dispatch(CreateNewVendor(data));
              if (CreateNewVendor.fulfilled.match(resultAction)) {
                resetForm();
                setCreateDialogVisible(false);
                dispatch(getVendorList({ page: 1, per_page: 10 }));
              }
            } catch (error) {
              console.error('Error creating vendor:', error);
            }
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className='grid grid-cols-1 md:grid-cols-4 gap-4 w-full'>
                <SharedInputText
                  label={t('name')}
                  name={'name'}
                  placeholder={`${t('enter')} ${t('name')}`}
                  className='!p-1.5'
                  error={touched.name && errors.name}
                />

                <SharedInputText
                  label={t('email')}
                  name={'email'}
                  placeholder={`${t('enter')} ${t('email')}`}
                  className='!p-1.5'
                  error={touched.email && errors.email}
                />

                <SharedInputText
                  label={t('contact_info')}
                  name={'phone'}
                  placeholder={t('contact_info_placeholder')}
                  // placeholder={`${t("enter")} ${t("phone")}`}
                  className='!p-1.5'
                  error={touched.phone && errors.phone}
                />

                <SharedInputText
                  label={t('address')}
                  name={'address'}
                  placeholder={`${t('enter')} ${t('address')}`}
                  className='!p-1.5'
                  error={touched.address && errors.address}
                />

                <SharedInputText
                  label={t('vat_no')}
                  name={'vat_no'}
                  placeholder={t('vat_no_placeholder')}
                  className='!p-1.5'
                  error={touched.vat_no && errors.vat_no}
                />

                <SharedInputText
                  label={t('cr_no')}
                  name={'cr_no'}
                  placeholder={t('cr_no_placeholder')}
                  className='!p-1.5'
                  error={touched.cr_no && errors.cr_no}
                />
              </div>
              <div className='flex justify-end gap-6 mt-6'>
                <SharedButton
                  type='button'
                  icon='pi pi-times'
                  label={t('Cancel')}
                  className='cancel-btn'
                  onClick={() => {
                    setCreateDialogVisible(false);
                  }}
                />
                <SharedButton type='submit' icon='pi pi-check' label={t('Submit')} className='submit-btn' />
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
