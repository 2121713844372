import React, { useState } from 'react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { Dialog } from 'primereact/dialog';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import SharedTooltip from '../../components/Shared/SharedTooltip';
import CustomPagination from '../../components/Shared/CustomPagination';
import { useTranslation } from 'react-i18next';
import { formatTimestamp } from '../../components/Shared/SharedMethods';
import { getPurchasedEntryList } from '../../store/slices/PurchaseSlice';
import ReceivePayment from './ReceivePatment';
import TableLoading from '../../components/Shared/TableLoading';
import CustomTable from '../../components/Shared/CustomTable';

export default function PurchasedEntryTable({ data, selectedTransactionEntries, setSelectedTransactionEntries }) {
  const datebody = rowData => {
    return formatTimestamp(rowData.purchase_date);
  };

  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [receivePaymentDialogVisible, setReceivePaymentDialogVisible] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const { isLoading } = useSelector(state => state.purchase);
  const lang = i18next.language;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(
      getPurchasedEntryList({
        page: newPage,
        per_page: newRowsPerPage
      })
    );
  };

  const templateBody = rowData => (
    <div className='flex gap-1'>
      <SharedTooltip content='view' id='my-tooltip-1' />
      <SharedTooltip id='my-tooltip-2' content='edit' />
      <SharedTooltip id='my-tooltip-3' content='delete' />
      <SharedTooltip id='my-tooltip-4' content='Record Payment' />
      <MdOutlineRemoveRedEye
        size={25}
        data-tooltip-id='my-tooltip-1'
        className='table-icon'
        onClick={() => {
          setSelectedTransactionEntries(rowData.purchase_detail);
          setCreateDialogVisible(true);
        }}
      />

      {/* <LiaFileInvoiceDollarSolid
        size={25}
        data-tooltip-id="my-tooltip-4"
        className="table-icon"
        onClick={() => {
          setSelectedEntry(rowData);
          setReceivePaymentDialogVisible(true);
        }}
      /> */}
      {rowData.status !== 'paid' && (
        <LiaFileInvoiceDollarSolid
          size={25}
          data-tooltip-id='my-tooltip-4'
          className='table-icon'
          onClick={() => {
            setSelectedEntry(rowData);
            setReceivePaymentDialogVisible(true);
          }}
        />
      )}
    </div>
  );
  const sequenceBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1 + (data?.page - 1) * data?.per_page;
  };

  const statusBody = data => {
    if (data?.status == 'paid') {
      return <h1 className='bg-green-500 !w-[50px] p-1 text-center text-white rounded-md'>{t('Paid')}</h1>;
    } else {
      return <h1 className='bg-red-500 p-1 !w-[50px] text-center text-white rounded-md'>{t('Unpaid')}</h1>;
    }
  };

  const balanceBody = data => {
    return (
      <>
        {data?.total_amount} {t('SAR')}
      </>
    );
  };

  const purchaseEntryColumns = [
    {
      body: sequenceBodyTemplate,
      header: t('no'),
      style: { width: '3rem' }
    },
    {
      field: 'purchase_date',
      header: t('purchase_date'),
      body: datebody
    },
    {
      field: 'entry_number',
      header: t('entry_number')
    },
    {
      field: 'vendor.name',
      header: t('name')
    },
    {
      field: 'total_amount',
      header: t('total_amount'),
      body: balanceBody
    },
    {
      field: 'notes',
      header: t('description')
    },
    {
      field: 'status',
      header: t('status'),
      body: statusBody
    },
    {
      body: templateBody,
      header: t('actions')
    }
  ];

  const entryDetailsColumns = [
    {
      field: lang === 'ar' ? 'item_name_ar' : 'item_name_en',
      header: t('item_name')
    },
    {
      field: 'item_price',
      header: t('item_price')
    },
    {
      field: 'quantity',
      header: t('quantity')
    },
    {
      field: 'subtotal',
      header: t('subtotal')
    },
    {
      field: 'vat_percent',
      header: t('vat_percent')
    },
    {
      field: 'vat_amount',
      header: t('vat_amount')
    },
    {
      field: 'total',
      header: t('total')
    }
  ];

  return (
    <>
      <CustomTable
        data={data?.data}
        isLoading={isLoading}
        loadingIcon={<TableLoading />}
        columns={purchaseEntryColumns}
        rows={data?.per_page}
      />

      {/* Detail Dialog */}
      <Dialog
        header={t('PurchaseDetail')}
        visible={createDialogVisible}
        onHide={() => {
          if (!createDialogVisible) return;
          setCreateDialogVisible(false);
        }}
        pt={{
          root: { className: 'w-full lg:w-10/12 mx-4 overflow-auto' },
          header: { className: '!p-2 !bg-darkBg !text-white' },
          content: { className: '!p-3' },
          closeButton: {
            className: 'hover:!bg-black/20 !outline-none !shadow-none'
          },
          closeButtonIcon: { className: '!text-white' }
        }}
      >
        <CustomTable data={selectedTransactionEntries} columns={entryDetailsColumns} />
      </Dialog>
      {data?.data?.length > 0 && (
        <CustomPagination
          currentPage={data?.page}
          rowsPerPage={data?.per_page}
          totalRecords={data?.total_pages}
          totalRecord={data?.total_record}
          onPageChange={onPageChange}
        />
      )}

      <ReceivePayment
        open={receivePaymentDialogVisible}
        setOpen={setReceivePaymentDialogVisible}
        selectedEntries={selectedEntry ? [selectedEntry] : []}
      />
    </>
  );
}
