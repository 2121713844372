import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SharedTooltip from '../../components/Shared/SharedTooltip';
import SharedButton from '../../components/Shared/SharedButton';
import CreateCustomer from './CreateCustomer';
import CustomerTable from './CustomerTable';
import { getCustomersList, getSearchedCustomer } from '../../store/slices/CustomerSlice';
import { debounce } from '../../helpers/debounce';

export default function CustomerMain() {
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const dispatch = useDispatch();
  const { customersList } = useSelector(state => state.customers);
  const { t } = useTranslation();
  const [filteredInvoices, setFilteredInvoices] = useState(null);

  useEffect(() => {
    dispatch(getCustomersList({ page: 1, per_page: 10 }));
  }, []);

  const handleSearch = event => {
    const value = event?.target?.value;
    debouncedSearch(value);
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(async value => {
      const trimmedValue = value?.trim();
      // Handle empty input
      if (!trimmedValue) {
        setFilteredInvoices(null);
        dispatch(getCustomersList({ page: 1, per_page: 10 }));
        return;
      }
      const data = await dispatch(getSearchedCustomer({ query: trimmedValue, page: 1, per_page: 10 }));
      if (data?.data?.length > 0) {
        setFilteredInvoices(data);
      }
    }, 500),
    []
  );

  return (
    <>
      <div>
        <div className='flex justify-between flex-col gap-3 sm:gap-0 sm:flex-row mb-3'>
          <SharedTooltip content={t('Keep_track_of_your_customers')} position='left' id='Items' />
          <span data-tooltip-id='Items' className='font-semibold text-black/70 text-xl mt-2 '>
            {t('CUSTOMERS')}
            <span className='italic text-lg ml-1'>
              ( {customersList?.total_record}
              <span className='text-xs font-bold italic'> {t('Entries')}</span>)
            </span>
          </span>

          <div className='flex items-center gap-4'>
            <div className='flex gap-2'>
              <input
                placeholder={t('Search by Name or Document ID')}
                type='text'
                className='border border-gray-300 rounded-lg px-2.5 py-1.5 w-[18rem] hover:border-primary !outline-none !shadow-none'
                onChange={handleSearch}
              />
            </div>

            <SharedButton
              label={t('CreateCustomer')}
              icon='pi pi-plus'
              onClick={() => setCreateDialogVisible(true)}
              className='bg-gradient primary-btn'
            />
          </div>
        </div>
        <CustomerTable data={filteredInvoices?.length ? filteredInvoices : customersList} />
        <CreateCustomer setCreateDialogVisible={setCreateDialogVisible} createDialogVisible={createDialogVisible} />
      </div>
    </>
  );
}
