import React from 'react';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import SharedButton from './SharedButton';

const ConfirmationDialog = props => {
  const { t } = useTranslation();
  const { title, isDialogVisible, setIsDialogVisible, confirmHandler, label = t('delete') } = props;
  return (
    <div>
      <Dialog
        header={t('Confirmation')}
        visible={isDialogVisible}
        onHide={() => {
          setIsDialogVisible(false);
        }}
        pt={{
          root: { className: 'w-full lg:w-[550px] mx-4 overflow-auto' },
          header: { className: '!p-2 !bg-darkBg !text-white' },
          content: { className: '!p-3' },
          closeButton: {
            className: 'hover:!bg-black/20 !outline-none !shadow-none'
          },
          closeButtonIcon: { className: '!text-white' }
        }}
      >
        <div>
          <p>{title}</p>
        </div>

        <div className='flex justify-end gap-6 mt-6'>
          <SharedButton
            icon='pi pi-times'
            label={t('Cancel')}
            className='cancel-btn'
            onClick={() => {
              setIsDialogVisible(false);
            }}
          />
          <SharedButton
            type='submit'
            icon='pi pi-check'
            label={label}
            className='confirm-btn'
            onClick={confirmHandler}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;
