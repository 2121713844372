import React from 'react';
import { formatTimestamp } from '../../components/Shared/SharedMethods';
import SharedTooltip from '../../components/Shared/SharedTooltip';
import { MdOutlineDelete, MdOutlineEdit, MdOutlineRemoveRedEye } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import CustomPagination from '../../components/Shared/CustomPagination';
import { useTranslation } from 'react-i18next';
import { getAllowanceList } from '../../store/slices/HrSlice';
import TableLoading from '../../components/Shared/TableLoading';
import CustomTable from '../../components/Shared/CustomTable';

export default function AllowanceTable({ data }) {
  const datebody = rowData => {
    return formatTimestamp(rowData.effective_date);
  };

  const { isLoading } = useSelector(state => state.hr);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(
      getAllowanceList({
        page: newPage,
        per_page: newRowsPerPage
      })
    );
  };

  const templateBody = rowData => (
    <div className='flex gap-1'>
      <SharedTooltip content='view' id='my-tooltip-1' />
      <SharedTooltip id='my-tooltip-2' content='edit' />
      <SharedTooltip id='my-tooltip-3' content='delete' />
      <MdOutlineRemoveRedEye size={25} data-tooltip-id='my-tooltip-1' className='table-icon' />
      <MdOutlineEdit size={25} data-tooltip-id='my-tooltip-2' className='table-icon' />
      <MdOutlineDelete size={25} data-tooltip-id='my-tooltip-3' className='table-icon' />
    </div>
  );

  const sequenceBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1 + (data?.page - 1) * data?.per_page;
  };

  const amountBody = data => {
    return (
      <>
        {data?.amount} {t('SAR')}
      </>
    );
  };

  const columnsData = [
    {
      body: sequenceBodyTemplate,
      header: t('no'),
      style: { width: '3rem' }
    },
    {
      field: 'effective_date',
      header: t('effective_date'),
      body: datebody
    },
    {
      field: 'full_name',
      header: t('name'),
      body: rowData => (
        <div>
          {rowData.employee.first_name} {rowData.employee.last_name}
        </div>
      )
    },
    {
      field: 'allowance_type',
      header: t('allowance_type')
    },
    {
      field: 'amount',
      header: t('amount'),
      body: amountBody
    },
    {
      body: templateBody,
      header: t('actions')
    }
  ];

  return (
    <>
      <CustomTable
        data={data?.data}
        isLoading={isLoading}
        loadingIcon={<TableLoading />}
        columns={columnsData}
        rows={data?.per_page}
      />
      {data?.data?.length > 0 && (
        <CustomPagination
          currentPage={data?.page}
          rowsPerPage={data?.per_page}
          totalRecords={data?.total_pages}
          totalRecord={data?.total_record}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
}
