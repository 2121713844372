import React from 'react';
import SharedInputText from '../../components/Shared/SharedInputText';
import SharedPasswordField from '../../components/Shared/SharedPasswordField';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FaLock, FaRegUser } from 'react-icons/fa';
import SharedButton from '../../components/Shared/SharedButton';
import { useDispatch, useSelector } from 'react-redux';
import { SignIn } from '../../store/slices/AuthSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WHerToLogin } from '../../layouts/RootLayout/Navbar';
import Spinner from '../../components/Shared/Spinner';

export default function Login() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    employee_id: '',
    password: ''
  };
  const validationSchema = yup.object().shape({
    password: yup.string().required(t('password_is_required')),
    employee_id: yup.string().required(t('username_is_required'))
  });
  const { isLoading } = useSelector(state => state.auth);

  const handleSubmit = async data => {
    const resultAction = await dispatch(SignIn(data));
    if (SignIn.fulfilled.match(resultAction)) {
      navigate(`${WHerToLogin ? WHerToLogin : '/manager/dashboard'}`);
    }
  };

  return (
    <>
      <div className='grid grid-cols-1 lg:grid-cols-2 h-full'>
        <div className='hidden lg:block'>
          <img
            src='https://res.cloudinary.com/uzairmunir/image/upload/v1732084304/zxpckohbmjrhy91k72zk.png'
            className='h-screen w-full'
          />
        </div>
        <div className='flex justify-center items-center h-screen flex-col'>
          <img src='/images/supergit-01.png' className='w-28 lg:w-36' />
          <p className='font-semibold text-2xl lg:text-3xl text-center my-3'>{t('LoginToYourAccount')}</p>
          <div className='flex justify-center w-full mt-4'>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {({ submitForm }) => (
                <Form
                  className='w-11/12 sm:w-9/12 md:w-7/12 lg:w-[550px] flex flex-col gap-4'
                  // onKeyDown={(e) => handleKeyDown(e, submitForm)}
                >
                  <SharedInputText
                    label={t('username')}
                    name={'employee_id'}
                    isIcon={true}
                    placeholder='Enter Username'
                    icon={<FaRegUser />}
                    iconPos={'left'}
                    className='!p-1.5'
                  />
                  <SharedPasswordField
                    label={t('password')}
                    name={'password'}
                    type='password'
                    isIcon={true}
                    placeholder='Enter Password'
                    isPasswordField={true}
                    toggleMask
                    icon={<FaLock />}
                    iconPos={'left'}
                    className='!p-1.5'
                  />
                  <SharedButton label={isLoading ? <Spinner /> : t('login')} className='submit-btn' type='submit' />
                </Form>
              )}
            </Formik>
            <div className='absolute bottom-0 right-0 m-3'>
              <p className='bg-gray-100 py-1.5 px-3 text-gray-600 text-xs font-semibold rounded-full '>
                Super Git v2.1.4
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
