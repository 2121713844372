import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import supergitHIS from '../../api/supergitHIS';
import toast from 'react-hot-toast';

export const SignIn = createAsyncThunk('login', async (data, { rejectWithValue }) => {
  try {
    const response = await supergitHIS.post(`login`, data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error || 'something went wrong');
    return rejectWithValue(error?.response?.data?.error || 'something went wrong');
  }
});

export const SignUp = createAsyncThunk('register', async (data, { rejectWithValue }) => {
  try {
    const response = await supergitHIS.post(`register`, data);
    toast.success(response?.data?.message);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error || 'something went wrong');
    return rejectWithValue(error?.response?.data?.error || 'something went wrong');
  }
});

const initialState = {
  isLoading: false,
  user: null,
  token: null
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      state.isLoading = false;
      state.user = null;
      state.token = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(SignIn.pending, state => {
        state.isLoading = true;
      })
      .addCase(SignIn.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload?.user;
        state.token = action.payload?.token;
        toast.success(action.payload.message || 'Successful');
      })
      .addCase(SignIn.rejected, state => {
        state.isLoading = false;
      })
      .addCase(SignUp.pending, state => {
        state.isLoading = true;
      })
      .addCase(SignUp.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action.payload.message || 'Successful');
      })
      .addCase(SignUp.rejected, state => {
        state.isLoading = false;
      });
  }
});

export const { logout } = AuthSlice.actions;
export default AuthSlice.reducer;
