import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AutoComplete } from 'primereact/autocomplete';

const SettingsTable = props => {
  const {
    dataKey,
    settingsData,
    accountSuggestion,
    isLoading,
    isAllowEdit,
    onFieldChange,
    onSearchAccounts,
    onUpdateSettings
  } = props;
  const { t } = useTranslation();
  const lang = i18next.language;

  const renderIsVatTab = (key, index, isVat) => {
    let isVatTrue = isVat == 'true' || isVat == true;
    return (
      <div className='flex flex-col gap-1 w-full '>
        <div className='flex'>
          <button
            className={`py-1 px-3 cursor-pointer transition-all disabled:cursor-not-allowed disabled:opacity-70
            ${lang === 'ar' ? 'rounded-tr-md rounded-br-md' : 'rounded-tl-md rounded-bl-md'}
            ${isVatTrue ? 'is_vat_true' : 'is_vat_false'}`}
            disabled={isLoading || !isAllowEdit}
            onClick={() => onFieldChange(key, index, 'is_vat', true)}
          >
            {t('true')}
          </button>
          <button
            className={`py-1 px-3 cursor-pointer transition-all disabled:cursor-not-allowed disabled:opacity-70
            ${lang === 'ar' ? 'rounded-tl-md rounded-bl-md' : 'rounded-tr-md rounded-br-md'}
            ${!isVatTrue ? 'is_vat_true' : 'is_vat_false'}`}
            disabled={isLoading || !isAllowEdit}
            onClick={() => onFieldChange(key, index, 'is_vat', false)}
          >
            {t('false')}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className={`max-h-[500px] overflow-auto scrollbar`}>
      <table className='min-w-full border-collapse'>
        <thead className='!bg-secondary sticky top-0 z-50'>
          <tr>
            <th className='settings_table_header w-[80px] text-start'>{t('no')}</th>
            <th className='settings_table_header md:w-[400px] text-start'>{t('title')}</th>
            <th className='settings_table_header md:w-[600px] text-start'>{t('select_acccount')}</th>
            <th className='settings_table_header text-start'>{t('is_vat')}</th>
          </tr>
        </thead>
        <tbody>
          {settingsData.map((data, index) => {
            return (
              <tr key={index}>
                <td className='settings_table_colum'>{index + 1}</td>
                <td className='settings_table_colum whitespace-nowrap'>{data?.name_en}</td>
                <td className='settings_table_colum'>
                  <AutoComplete
                    disabled={isLoading || !isAllowEdit}
                    field='label'
                    placeholder={t('Select Account')}
                    value={data?.account}
                    suggestions={accountSuggestion}
                    onChange={e => onFieldChange(dataKey, index, 'account', e.value)}
                    onSelect={e => onUpdateSettings(dataKey, index, 'account', e.value)}
                    completeMethod={onSearchAccounts}
                    forceSelection
                    pt={{
                      root: { className: '!w-[300px]' },
                      input: {
                        root: {
                          className:
                            '!w-full p-0 rounded-md hover:border-primary !outline-none !shadow-none !p-1 disabled:border-gray-500'
                        }
                      },
                      panel: { className: '!shadow-lg rounded-[8px] mt-2 pb-2 overflow-hidden' },
                      loadingIcon: { className: 'absolute top-[15px] right-[12px]' }
                    }}
                  />
                </td>
                <td className='settings_table_colum'>{renderIsVatTab(dataKey, index, data?.is_vat)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SettingsTable;
